import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-wallet-options',
  templateUrl: './wallet-options.component.html',
  styleUrls: ['./wallet-options.component.scss']
})
export class WalletOptionsComponent implements OnInit {
  public walletOptions:any = [
    {
      label:'Metamask',
      key:'METAMASK',
      img: 'assets/images/metamask.svg'
    },
    {
      label:'WalletConnect',
      key:'WALLETCONNECT',
      img: 'assets/images/wallet-connect.svg'
    }
  ]
  constructor(
    public dialogRef: MatDialogRef<WalletOptionsComponent>,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  onSelectWallet = (key:any) => {
    this.dialogRef.close(key);
  }
}

<div class="head">
  <h3 class="color_primary">{{offerInfo.title}}</h3>
  <mat-icon class="color_primary" (click)="drawer.toggle()">close</mat-icon>
</div>
<mat-card appearance="outlined" class="offer_scroll">
  <div class="gws_invoice_counts">
    <div class="gws_count_box">
      <p>Offer Usage</p>
      <h5>{{offerInfo?.statistics?.usage || 0}}</h5>
    </div>
    <span class="divider"></span>
    <div class="gws_count_box">
      <p>Active On</p>
      <h5>{{offerInfo?.statistics?.activeOn || 0}}</h5>
    </div>
    <span class="divider"></span>
    <div class="gws_count_box">
      <p>Total Discounts Applied</p>
      <h5> {{offerInfo?.statistics?.discount || 0}} {{currency}}</h5>
    </div>
  </div>

  <mat-list class="offer_list">
    <mat-list-item *ngIf="offerInfo?.created">
      <span class="firstchild" matListItemTitle>Created At</span>
      <span matListItemLine class="disaply_block">{{formateOfferDate(offerInfo?.created)}}</span>
    </mat-list-item>
    <mat-list-item>
      <span class="firstchild" matListItemTitle>Status</span>
      <span matListItemLine class="disaply_block">
        <span class="gws_chip {{offerInfo?.status ? 'enabled' : 'disabled'}}">{{offerInfo?.status ? 'Enabled' : 'Disabled'}}</span>
        <a class="gws_btn_secondary" (click)="changeOfferStatus(offerInfo)">{{offerInfo?.status ? 'Disable' : 'Enable'}}</a>
      </span>
    </mat-list-item>
    <mat-list-item *ngIf="offerInfo?.title">
      <span class="firstchild" matListItemTitle>Offer Name</span>
      <span matListItemLine class="disaply_block">{{offerInfo?.title}}</span>
    </mat-list-item>

    <mat-list-item *ngIf="offerInfo?.displayTitle">
      <span class="firstchild" matListItemTitle>Display Text</span>
      <span matListItemLine class="disaply_block">{{offerInfo?.displayTitle}}</span>
    </mat-list-item>

    <mat-list-item *ngIf="offerInfo?.description">
      <span class="firstchild" matListItemTitle>Terms</span>
      <span matListItemLine class="disaply_block">{{offerInfo?.description}}</span>
    </mat-list-item>

    <mat-list-item>
      <span class="firstchild" matListItemTitle>Promotion Type</span>
      <span matListItemLine class="disaply_block">Subscription</span>
    </mat-list-item>

    <mat-list-item>
      <span class="firstchild" matListItemTitle>On Offer Failure</span>
      <span matListItemLine class="disaply_block">{{ offerInfo?.paymentFailedAllowed ? 'Block Payment' : 'Allow Payment'}}</span>
    </mat-list-item>

    <mat-list-item >
      <span class="firstchild" matListItemTitle>Checkout Visibility</span>
      <span matListItemLine class="disaply_block">{{ offerInfo?.isCheckoutPreview ? 'Yes' : 'No'}}</span>
    </mat-list-item>

    <mat-list-item>
      <span class="firstchild" matListItemTitle>Min Payment</span>
      <span matListItemLine class="disaply_block"> {{offerInfo?.minOrderAmt}} {{currency}}</span>
    </mat-list-item>

    <mat-list-item *ngIf="offerInfo?.validityStartDate">
      <span class="firstchild" matListItemTitle>Start of Offer</span>
      <span matListItemLine class="disaply_block">{{formateOfferDate(offerInfo?.validityStartDate)}}</span>
    </mat-list-item>

    <mat-list-item *ngIf="offerInfo?.validityEndDate">
      <span class="firstchild" matListItemTitle>Expiry of Offer</span>
      <span matListItemLine class="disaply_block">{{formateOfferDate(offerInfo?.validityEndDate)}}</span>
    </mat-list-item>

    <mat-list-item>
      <span class="firstchild" matListItemTitle>Discount Type</span>
      <span matListItemLine class="disaply_block">{{offerInfo?.discountName}}</span>
    </mat-list-item>

    <mat-list-item>
      <span class="firstchild" matListItemTitle>Discount Worth</span>
      <span matListItemLine class="disaply_block" *ngIf="offerInfo.discountType == 1"> {{offerInfo?.discountAmt}} {{currency}}</span>
      <span matListItemLine class="disaply_block" *ngIf="offerInfo.discountType == 2">{{offerInfo?.discountAmt}}%</span>
    </mat-list-item>

    <mat-list-item *ngIf="offerInfo.discountType == 2">
      <span class="firstchild" matListItemTitle>Max Cashback</span>
      <span matListItemLine class="disaply_block"> {{offerInfo?.maxOrderAmt}} {{currency}}</span>
    </mat-list-item>

    <mat-list-item>
      <span class="firstchild" matListItemTitle>Offer Used</span>
      <span matListItemLine class="disaply_block">{{offerInfo?.usedCount ?? '0'}}/{{offerInfo?.maxUses}}</span>
    </mat-list-item>

    <mat-list-item>
      <span class="firstchild" matListItemTitle>Method</span>
      <span matListItemLine class="disaply_block">{{offerInfo?.paymentMethod}}</span>
    </mat-list-item>

    <mat-list-item>
      <span class="firstchild" matListItemTitle>Offer Type</span>
      <span matListItemLine class="disaply_block">Instant</span>
    </mat-list-item>

    <mat-list-item>
      <span class="firstchild" matListItemTitle>Redemption Type</span>
      <span matListItemLine class="disaply_block">{{offerInfo?.redeemName}}</span>
    </mat-list-item>

    <mat-list-item>
      <span class="firstchild" matListItemTitle>Number of Cycles</span>
      <span matListItemLine class="disaply_block">{{offerInfo?.numOfCycles ? offerInfo?.numOfCycles : '--'}}</span>
    </mat-list-item>
  </mat-list>
</mat-card>

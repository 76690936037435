import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CommonService } from 'src/app/core/services/common.service';
import { InvoiceService } from 'src/app/core/services/invoice.service';
import { WalletService } from 'src/app/core/services/wallet.service';
import { currencyJSON } from 'src/app/model/currencyJSON';
import { getCurrencySymbol } from '@angular/common';
import { currency, NetWorks } from 'src/app/model/payment';
import { status } from 'src/app/model/subscriptions';
import { NgxSpinnerService } from 'ngx-spinner';
import { OnmetaService } from 'src/app/core/services/payouts/onmeta.service';

@Component({
  selector: 'app-invoice-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss'],
})
export class InvoicePaymentComponent implements OnInit {
  @Input() invoiceInputData: any;
  public invoiceData: any;
  public planId: any = null;
  public totalAmount: number = 0;
  public offerId: any = null;
  public invoiceCurrency: any = currencyJSON;
  public logoURL: any;
  public enabledPayment: boolean = true;
  public status: any = status;
  public netWorks: any = NetWorks;
  public currency: any = currency;
  public totalTaxAmount: any = 0;
  public totalWithoutTax: any = 0;
  public isLoader: boolean = false;
  public subscriptionStatus: any = '';
  public isSubscriptionInvoice: boolean = false;
  public isSubscriptionView: boolean = false;
  public totalDiscount: any = 0;
  public isPayoutEnable: boolean = false;
  public payoutType:any = ''
  public isWalletConnected:boolean = false;
  public isPaymentMethodEnable:boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InvoicePaymentComponent>,
    public dialog: MatDialog,
    public invoiceService: InvoiceService,
    public router: Router,
    private aRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public commonService: CommonService,
    private sanitizer: DomSanitizer,
    private walletService: WalletService,
    private spinner: NgxSpinnerService,
    public onmetaService: OnmetaService,
    public route: ActivatedRoute
  ) {
    this.isSubscriptionView = this.router.url.includes('subscription');
  }

  ngOnInit(): void {
    this.isWalletConnected = false;
    this.invoiceService._setWallectStatus$.subscribe((res) => {
      this.isWalletConnected = res !== null ? res : false;
    });

    if(this.data && this.data.hasOwnProperty('invoiceId')) {
      this.invoiceInputData = {invoiceId: this.data.invoiceId}
    }
    this.invoiceCurrency.map((curreny: any) => {
      curreny.symbol = getCurrencySymbol(curreny.code, 'wide');
    });
    this.aRoute.params.subscribe((res) => {
      if (res && Object.keys(res).length > 0) this.getInvoiceData(res);
    });
    if (this.invoiceInputData &&
      Object.keys(this.invoiceInputData).length > 0) {
      this.isPayoutEnable = true;
      this.payoutType = this.commonService.getLocalStorage('type'); 
      this.getInvoiceData({ id: this.invoiceInputData.invoiceId });
    }
  }

  getInvoiceData = (res: any) => {
    this.spinner.show(); //res.id
    this.walletService.getInvoicePayment(res.id).subscribe(
      (res) => {
        if (res.result) {
          this.invoiceData = res.result;
          // NEED REMOVE
          if (this.isPayoutEnable) {
            this.invoiceData = { ...this.invoiceData, isPayoutEnable: true };
            this.invoiceData.items.map((item:any) => {
              item.unitPrice = Number(this.data.quotation.totalAmount);
              item.amount = Number(this.data.quotation.totalAmount);
              return item;
            });
          }
          this.prepareInvoiceData();
        } else {
          this.isSubscriptionInvoice = true;
          this.subscriptionStatus = this.status.EXPIRED;
        }
        this.isLoader = true;
        this.spinner.hide();
      },
      (err) => {
        this.snackBar.open(err.message, 'CLOSE', {
          panelClass: ['snack-error'],
        });
        this.invoiceData = { subscriptionId: { status: this.status.EXPIRED } };
        this.isLoader = true;
        this.spinner.hide();
      }
    );
  };

  prepareInvoiceData = () => {
    this.invoiceData.allowPayment = false;
    if (
      this.invoiceData.paymentMode &&
      Object.keys(this.invoiceData.paymentMode).length > 0
    ) {
      this.invoiceData.paymentMode.networkObj = {};
      const networkObj = this.netWorks.find(
        (chain: any) => chain.value == this.invoiceData.paymentMode.network.toLowerCase()
      );
      if (networkObj && Object.keys(networkObj).length > 0)
        this.invoiceData.paymentMode.networkObj = networkObj;
        if(this.invoiceData.paymentMode.network === 'MUMBAI') {
          this.invoiceData.paymentMode.networkObj = this.netWorks[1];
          this.invoiceData.paymentMode.networkObj.label = 'Polygon(Mumbai)';
        }
    }
    if (
      this.invoiceData.subscriptionId &&
      Object.keys(this.invoiceData.subscriptionId).length > 0
    ) {
      const { status, nextDue, offerId } = this.invoiceData.subscriptionId;
      this.offerId = offerId ? offerId : null;
      this.subscriptionStatus = status.toUpperCase();
      this.isSubscriptionInvoice = true;
      this.totalDiscount = this.invoiceService.calculateDiscount(
        this.invoiceData.items
      );
      if (
        this.isSubscriptionView &&
        (this.subscriptionStatus === this.status.CREATED ||
          this.subscriptionStatus === this.status.PENDING)
      ) {
        this.enabledPayment = false;
      }
      if (nextDue) {
        const dueDate = moment(nextDue, 'X');
        this.invoiceData.allowPayment =
          this.commonService.isCurrentDateBeforeDueDate(3, dueDate);
      }
    }
    if (this.invoiceData.hasOwnProperty('invoiceCurrency')) {
      if (!this.invoiceData.invoiceCurrency)
        this.invoiceData.invoiceCurrency = this.invoiceData.billFrom?.currency;
      const currObj = this.invoiceCurrency.find(
        (curreny: any) => curreny.code === this.invoiceData.invoiceCurrency
      );
      this.invoiceData.currencyName = `${currObj?.symbol}- ${currObj?.name}`;
    }
    if (
      this.invoiceData &&
      this.invoiceData.hasOwnProperty('items') &&
      this.invoiceData.items.length > 0
    ) {
      this.invoiceData.items = this.invoiceData.items.map((item: any) => {
        if (this.isSubscriptionInvoice) {
          const { planId } = this.invoiceData.subscriptionId;
          this.planId = planId;
          item.itemDetails = { itemCode: '001', name: planId.title };
        } else {
          item.amount = this.getAmount(item);
        }
        return item;
      });
    }
    this.calculateAmt();
    this.getLogo();
    if (this.isPayoutEnable && this.payoutType === 'ENCRYPTUS') {
      setTimeout(() => {
        this.enableTimer();
      }, 100)
    }
  };

  getAmount(item: any) {
    return this.invoiceService.calculateAmount(item);
  }

  calculateAmt() {
    this.totalAmount = this.invoiceData?.items.reduce(
      (total: any, current: any) =>
        parseFloat(total) + parseFloat(current.amount),
      0
    );
    this.totalTaxAmount = this.invoiceData?.items.reduce(
      (total: any, current: any) =>
        (
          parseFloat(
            this.invoiceService.calculationTax(
              current.taxType,
              parseFloat(current.quantity) * parseFloat(current.unitPrice),
              current.tax
            )
          ) + parseFloat(total)
        ).toFixed(2),
      0
    );
    this.totalWithoutTax = this.invoiceData?.items.reduce(
      (total: any, current: any) =>
        parseFloat(current.quantity) * parseFloat(current.unitPrice),
      0
    );
    this.totalTaxAmount = !isNaN(this.totalTaxAmount) ? this.totalTaxAmount : 0;
  }
  getLogo() {
    if (!this.invoiceData?.billFrom?.logo?.key) return;
    this.commonService
      .getFileURL(this.invoiceData.billFrom.logo.key)
      .then((url) => {
        this.logoURL = url;
      });
  }

  sanitizeURL(url: any) {
    if (!url) return;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  reacivedInvoiceData(invoice: object) {
    this.invoiceData = invoice;
    if (
      this.invoiceData.paymentMode &&
      Object.keys(this.invoiceData.paymentMode).length > 0
    ) {
      this.invoiceData.paymentMode.networkObj = {};
      const networkObj = this.netWorks.find(
        (chain: any) => chain.value == this.invoiceData.paymentMode.network.toLowerCase()
      );
      if (networkObj && Object.keys(networkObj).length > 0)
        this.invoiceData.paymentMode.networkObj = networkObj;
    }
    if (this.isPayoutEnable) {
      this.payoutActionHandler(this.invoiceData);
    }
  }

  enableSubscriptionPayment = () => {
    this.enabledPayment = true;
  };

  close = (status:boolean) => {
    this.dialogRef.close(status);
    this.commonService.clearTimeout();
    if(status) this.router.navigate(['/fiat-withdrawals']);
  };

  payoutActionHandler = (data?: any) => {
    if (this.payoutType === 'ONMETA') {
      this.updateTransactionHash(data);
    } else {
      setTimeout(() => {
        this.close(true);
      }, 700);
      
    }
  };

  updateTransactionHash = (data: any) => {
    const {
      transactionDetails: { hash },
    } = data;
    const payload = { orderId: this.invoiceInputData.orderId, txnHash: hash };
    this.onmetaService.updateTxHash(payload).then((res: any) => {
      if (res && res.hasOwnProperty('success') && res.success) {
        this.updateOrderStatusInDB(data);
        localStorage.removeItem('type');
        setTimeout(() => {
          this.close(true);
        }, 700);
      }
    },(error) => {
      console.log(error)
      this.snackBar.open(error.error.error.message, 'CLOSE', {
        panelClass: ['snack-error'],
      });
      localStorage.removeItem('type');
      this.router.navigate(['/fiat-withdrawals']);
    });
  };

  updateOrderStatusInDB = (invoiceData: any) => {
    console.log('PAYMENT COMPLETED')
    // Need to call backend API for update status
  };

  enableTimer = () => {
    let quotationTime = moment(this.data.expriyTime);
    let now = moment();
    this.startTimer(now.diff(quotationTime, 'seconds'))
  }

  disablePaymentOption = () => {
    this.isPaymentMethodEnable = false;
  }

  startTimer(diff:any) {
    var fiveMinutes = (60 * 15) - diff;
    this.commonService.callbackTimer(fiveMinutes, this.disablePaymentOption.bind(this), 'ptTimer');
  }

  backToWalletConnect = () => {
    this.invoiceService.updateWalletStatus('DISCONNECTED');  
  }
}

import { Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {CHAINS, NETWORKS, POLYGON_GAS_STATION} from 'src/app/config';
import {CommonService} from 'src/app/core/services/common.service';
import {InvoiceService} from 'src/app/core/services/invoice.service';
import {WalletService} from 'src/app/core/services/wallet.service';
import {InvoiceStatus, invoiceType} from 'src/app/model/invoice';
import {MetamaskErrors} from 'src/app/model/metamaskErrors';
import {environment} from 'src/environments/environment';
import { Clipboard } from '@angular/cdk/clipboard';

const Web3 = require('web3');
// import { Web3ModalService } from '@mindsorg/web3modal-angular';
import { ProcessingDialogComponent } from '../../dialog/processing-dialog/processing-dialog.component';
import { ConnectToWalletComponent } from '../../../modules/payment/connect-to-wallet/connect-to-wallet.component';
import {CoinOptions} from 'src/app/model/transfer';
import { WalletConnectService } from 'src/app/core/services/wallet-connect.service';
import { WalletOptionsComponent } from '../../../modules/payment/wallet-options/wallet-options.component';
let web3: any;
@Component({
  selector: 'app-payment-actions',
  templateUrl: './payment-actions.component.html',
  styleUrls: ['./payment-actions.component.scss'],
})
export class PaymentActionsComponent implements OnInit, OnChanges {
  @Input() invoiceData: any;
  totalAmount: number = 0;
  paymentSucess = false;
  chainSelected = false;
  connectedToWallet: boolean = false;
  ethereum: any;
  coinPrice: any;
  coins: any[] = CoinOptions;
  contractAddress: string = '';
  contractABI: any = [];
  DEFUALT: any = environment.WEB3_DEFUALT;
  NETWORKS: any = NETWORKS;
  CHAINS: any = CHAINS;
  chain: any;
  toAddress: any;
  paywithMetamask: boolean = false;
  showQR: boolean = false;
  coin: any;
  InvoiceStatus: any = InvoiceStatus;
  metamaskErrors: any = MetamaskErrors;
  @Output() data = new EventEmitter<any>();
  loadingPaid: boolean = false;
  INVOICE_TYPE: any = invoiceType;
  public walletObj: any = null;
  public currenctChain: any = CHAINS[0];
  constructor(
    public dialog: MatDialog,
    public invoiceService: InvoiceService,
    public router: Router,
    private aRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public commonService: CommonService,
    private sanitizer: DomSanitizer,
    private walletService: WalletService,
   // private web3modalService: Web3ModalService,
    private ngZone: NgZone,
    private clipboard: Clipboard,
    public walletConnectService: WalletConnectService
  ) {}

  ngOnInit(): void {
    this.invoiceService._setWallectStatus$.subscribe((res) => {
      if(res !== null && res === 'DISCONNECTED') {
        this.back();
        this.invoiceService.updateWalletStatus(null);   
      }
    });
    this.walletConnectService.emitWalletStatus();
    this.walletConnectService.wallectObj$.subscribe((res: any) => {
      if (
        res &&
        res.peerMeta.name === 'WalletConnect Safe App' &&
        res.chainId !== this.currenctChain.ID
      ) {
        this.walletConnectService.switchChainID(this.currenctChain);
      } else {
        this.walletObj = res;
        if(this.walletObj && this.walletObj?.hasOwnProperty('connected') && this.walletObj?.connected) {
          this.invoiceService.updateWalletStatus('CONNECTED');    
        }
        res && this.walletConnectService.switchChainID(this.currenctChain);
      }
    });

    /** defult */
    this.contractABI = this.NETWORKS[this.DEFUALT.NETWORK].CONTACTABI;
    this.chain = this.CHAINS.find((c: any) => c.ID === this.DEFUALT.CHAINID);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.invoiceData && this.invoiceData.paymentStatus === undefined) {
      // external payment - paymentStatus flag set by backend for checking payment is done
      this.toAddress = this.invoiceData?.forwarderContract?.address;
      this.calculateTotalAmount();
    }

    if (
      this.invoiceData &&
      this.invoiceData.hasOwnProperty('paymentMode') &&
      Object.keys(this.invoiceData.paymentMode).length > 0
    ) {
      const { currency, address } = this.invoiceData.paymentMode;
      // if(this.invoiceData.isPayoutEnable) {
      //   this.toAddress = address ? address : this.toAddress;
      // }
      if (currency && currency.length > 0) {
        this.coins = this.coins.filter((c) => currency.includes(c.value));
      }
    }
  }

  calculateTotalAmount() {
    if (this.invoiceData.invoiceType === this.INVOICE_TYPE.EXTERNAL) {
      this.totalAmount = this.invoiceData.convertedAmount;
    } else if (
      this.invoiceData.invoiceType === this.INVOICE_TYPE.PAYMENT_LINK
    ) {
      this.totalAmount = this.invoiceData.amount;
    } else {
      this.totalAmount = this.invoiceData?.items.reduce(
        (total: any, current: any) =>
          total + current.quantity * current.unitPrice,
        0
      );
    }
    if(this.totalAmount && !this.invoiceData.hasOwnProperty('isPayoutEnable')) {
      const { currency } = this.invoiceData.paymentMode;
      this.getPriceConversion(currency);
    } else {
      const { currency } = this.invoiceData.paymentMode;
      this.coinPrice = {[currency[0]] : {price:this.totalAmount}};
    }
  }

  async connectToWalletDialog() {
    // this.getPriceConversion();

    const provider: any = '' //await this.web3modalService.open();

    this.connectedToWallet = provider.isConnected;
    this.ethereum = provider;
    if(this.connectedToWallet) {
      this.invoiceService.updateWalletStatus('CONNECTED');    
    } 
    web3 = new Web3(provider);
    this.accChanged();
    this.chainChanged();
  }

  /** Account change */
  accChanged() {
    this.ethereum.on('accountsChanged', (acc: any) => {
      // this.selectedAccount = acc;
      if (!acc.length) window.location.reload();
    });
  }

  /** Network change */
  chainChanged() {
    this.ethereum.on('chainChanged', (_chainId: any) => {
      // window.location.reload()
      this.ngZone.run(() => {
        const chain = this.CHAINS.find((c: any) => c.IDHEX === _chainId);
        this.chainSelected = false;
        if (chain) this.chainSelected = true; // defult network selected
      });
    });
  }

  async getcontractInstance(contactAddress: string) {
    try {
      return new web3.eth.Contract(this.contractABI, contactAddress, {
        from: this.ethereum.selectedAddress,
      });
    } catch (e) {
      this.error(e);
    }
  }

  async getPrice(price: number, contractInstance: any) {
    //coin price to decimal bignumber
    try {
      const decimal = await contractInstance.methods.decimals().call();
      // (Math.round(price * 100) / 100) - round the decimal price
      // 10 ** decimal - 10 exponent decimal
      // 2.3031 * 100 => 230.31 => 230 => 230/100 => 2.30 * 1000000 => 2300000
      // 2.3031 * 100 => 230.31 => 230 => 230/100 => 2.30 * 1000000000000000000 => 2300000000000000000
      const roundDecimal = Math.round(price * 100); // (Math.round((price * 100))/100)  * 1000000
      // 2.3031 * 100 => 230.31 => 230
      //
      return web3.utils.toBN(String(roundDecimal) + '0'.repeat(decimal - 2));
      // return new web3.utils.toBN( roundDecimal.toString() );
    } catch (e) {
      this.error(e);
    }
  }

  async getTokenBalance(contractInstance: any) {
    try {
      let balance = await contractInstance.methods
        .balanceOf(this.ethereum.selectedAddress)
        .call();
      return balance;
    } catch (e) {
      this.error(e);
    }
  }

  async getWalletBalance() {
    try {
      let balance = web3.eth.getBalance(this.ethereum.selectedAddress);
      return balance; // wei
    } catch (e) {
      this.error(e);
    }
  }

  async estimateGasPrice(price: any, contractInstance: any) {
    try {
      let estGasPrice = await contractInstance.methods
        .transfer(this.toAddress, price) // abhi addr
        .estimateGas();

      return web3.utils.toWei(estGasPrice.toString(), 'shannon'); // Gwei to wei
    } catch (e) {
      this.error(e);
    }
  }

  async invoicePaymnet(
    price: any,
    contractInstance: any,
    selectedAddress: any,
    paymentObj:any
  ) {
    try {
      await contractInstance.methods
        .transfer(this.toAddress, price)
        .send({
          from: selectedAddress,
          nonce:paymentObj.nonce,
          maxPriorityFeePerGas: web3.utils.toWei(
            Math.ceil(paymentObj.estimateGas.fast.maxPriorityFee).toString(),
            'gwei'
          ),
        })
        .on('transactionHash', (hash: any) => {
          const tx = {
            transactionHash: hash,
            nonce: paymentObj.nonce,
            network: this.chain?.SYMBOL,
            from: web3.currentProvider.selectedAddress,
          };
          this.walletConnectService.clearConnectionFields();
          this.updateDB(tx);
        })
        .on('error', (error: any, receipt: any) => {
          throw error;
        });
    } catch (e: any) {
      this.error(e);
    }
  }

  updateDB(tx: any) {
    this.walletService
      .postInvoicePayament(this.invoiceData.refID, tx)
      .subscribe(
        (res) => {
          this.invoiceData = res.result;
          this.data.next(this.invoiceData);
          this.snackBar.open(
            'Payment is being processed. You will recieve a notification shortly.',
            'CLOSE',
            { panelClass: ['snack-sucess'] }
          );
          this.closeAllDialog(true);
        },
        (err) => {
          this.snackBar.open('Transaction failed!', 'CLOSE', {
            panelClass: ['snack-error'],
          });
          this.closeAllDialog(false);
        }
      );
  }

  async pay(coin: string) {
    this.commonService.showSpinner();
    if (!this.ethereum) {
      this.snackBar.open('Please connect to Account', 'CLOSE', {
        panelClass: ['snack-error'],
      });
      this.closeAllDialog();
      return;
    }
    this.processigDialog();
    const contractAddress =
      this.NETWORKS[this.DEFUALT.NETWORK].CONTRACTADDRESSES[coin];

    const contractInstance = await this.getcontractInstance(contractAddress);

    const price = await this.getPrice(
      this.coinPrice[coin].price,
      contractInstance
    ); // pay price
    const tokenBalance = await this.getTokenBalance(contractInstance); // metamask usdc balance

    if (!price || !tokenBalance) {
      this.snackBar.open('Somthing went wrong. Try again later!', 'CLOSE', {
        panelClass: ['snack-error'],
      });
      this.closeAllDialog();
      return;
    }

    if (Number(tokenBalance) < Number(price.toString())) {
      this.snackBar.open('Insufficient token balance!', 'CLOSE', {
        panelClass: ['snack-error'],
      });
      this.closeAllDialog();
      return;
    }

    const walletBalance = await this.getWalletBalance(); //
    const estmateGasPrice = await this.estimateGasPrice(
      price,
      contractInstance
    );

    if (!walletBalance || !estmateGasPrice) {
      this.snackBar.open('Somthing went wrong. Try again later!', 'CLOSE', {
        panelClass: ['snack-error'],
      });
      this.closeAllDialog();
      return;
    }

    if (Number(walletBalance) < Number(estmateGasPrice)) {
      this.snackBar.open('Insufficient balance to pay gas fee!', 'CLOSE', {
        panelClass: ['snack-error'],
      });
      this.closeAllDialog();
      return;
    }
    const { nonce, estimateGas } = await this.getNonesAndEstimateGas(
      web3.currentProvider.selectedAddress
    );
    await this.invoicePaymnet(
      price,
      contractInstance,
      web3.currentProvider.selectedAddress,
      { nonce, estimateGas }
    );
  }

  sendPaymentDialog() {
    // this.dialog.open(SendPaymentComponent,{autoFocus: false}).afterClosed().subscribe(res => {
    //   // if(res){
    //   // }
    //   this.paymentSucess = true;
    // })
  }

  getPriceConversion(token:any) {
    const coinsymbols = this.coins.filter((c) => token[0].includes(c.symbol)).map((c) => c.symbol).join(',');
    this.getCoinPrice(coinsymbols);
  }

  getCoinPrice(coinType: string) {
    this.commonService
      .getPrice({
        amount: this.totalAmount,
        symbol: this.invoiceData?.billFrom?.currency,
        convert: coinType,
      })
      .subscribe((res) => {
        this.coinPrice = res?.result?.quote;
      });
  }

  processigDialog() {
    this.dialog.open(ProcessingDialogComponent, {
      data: { txt: 'Payment Processing...' },
      disableClose: true,
      backdropClass: ['custombackdrop'],
    });
  }
  connectingDialog() {
    this.dialog.open(ProcessingDialogComponent, {
      data: { txt: 'intrXn connecting with Metamask' },
      disableClose: true,
      backdropClass: ['custombackdrop'],
    });
  }

  connectToWallet() {
    this.dialog
      .open(ConnectToWalletComponent)
      .afterClosed()
      .subscribe((btn: any) => {
        if (btn === 'METAMASK') {
          this.connectingDialog();
          this.getProvider();
        }
      });
  }

  getProvider() {
    // this.walletService.getProvider().subscribe(
    //   (provider) => {
    //     setTimeout(() => {
    //       this.setProvider(provider);
    //     }, 1000);
    //   },
    //   (err) => {
    //     this.snackBar.open('Please Install Metamask extension', 'CLOSE', {
    //       duration: 3000,
    //       panelClass: ['snack-error'],
    //     });
    //     this.closeAllDialog();
    //   }
    // );
  }

  async setProvider(provider: any) {
    try {
      this.ethereum = provider;
      this.connectedToWallet = provider?.isConnected;
      if(this.connectedToWallet) {
        this.invoiceService.updateWalletStatus('CONNECTED');    
      } 
      web3 = new Web3(provider);

      this.accChanged();
      this.chainChanged();

      if (!web3.currentProvider.selectedAddress) {
        const accounts = await this.ethereum.request({
          method: 'eth_requestAccounts',
        });
      }

      // if(id !== 80001){ //polygon network
      //   this.snackBar.open('Please select Polygon network', 'CLOSE', {
      //     panelClass: ['snack-error'],
      //   });
      //   this.closeAllDialog();
      //   return;
      // }
      this.switchChain();

      // this.getPriceConversion();
      this.closeAllDialog();
    } catch (e) {
      this.ethereum = '';
      this.connectedToWallet = false;
      this.invoiceService.updateWalletStatus(null);    
      this.error(e);
    }
  }

  async switchChain() {
    let id = await web3.eth.net.getId();
    const chain = this.CHAINS.find((c: any) => c.ID === id);
    if (!chain) {
      await this.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: this.chain.IDHEX }],
      });
    } else {
      this.chainSelected = true;
    }
  }

  btnClick() {
    !this.connectedToWallet && this.connectToWallet();
    !this.chainSelected && this.connectedToWallet && this.switchChain();
  }

  error(e: any) {
    const error = this.metamaskErrors[e?.code];
    let msg = `${error?.standard}: ${error?.message}`;
    if (!e?.code) msg = 'Metamask-Web3 error';
    this.snackBar.open(msg, 'CLOSE', {
      panelClass: ['snack-error'],
    });
    this.closeAllDialog();;
  }

  copy(addr: string) {
    this.clipboard.copy(addr);
    this.snackBar.open('Address Copied!', 'Close', { duration: 1000 });
  }

  payMetamask() {
    this.paywithMetamask = true;
    if (
      this.InvoiceStatus[this.invoiceData.invoiceStatus] < 3 ||
      this.InvoiceStatus[this.invoiceData.invoiceStatus] === 5
    )
      this.getProvider();
  }

  clickShowQR(coin: string) {
    this.showQR = true;
    this.coin = coin;
    const ele:any = document.getElementsByClassName('payment-model');
    if(ele) {
      ele[0].classList.add("owerflowhideen");
    }
  }

  back() {
    this.paywithMetamask = false;
    this.connectedToWallet = false;
    this.chainSelected = false;
    this.walletObj = null;
    this.showQR = false;
    const ele:any = document.getElementsByClassName('payment-model');
    ele[0].classList.remove("owerflowhideen");
    this.coin = undefined;
  }

  getValue() {
    const value = this.coinPrice[this.coin].price;
    return Math.round(value * 100) / 100;
  }

  paid() {
    this.loadingPaid = true;
    this.snackBar.open('checking...', 'CLOSE', {
      panelClass: ['snack-sucess'],
      duration: 2000,
    });
    this.walletService
      .getNetworkInvoicePayment(this.invoiceData.refID)
      .subscribe(
        (res) => {
          if (res.result.paymentStatus) {
            this.invoiceData = res.result;
            this.snackBar.open(
              'Payment is being processed. You will recieve a notification shortly.',
              'CLOSE',
              { panelClass: ['snack-sucess'], duration: 4000 }
            );
            this.data.emit(this.invoiceData);
          } else {
            this.snackBar.open(
              'Please wait. Payment status not updated yet!',
              'CLOSE',
              { panelClass: ['snack-info'], duration: 4000 }
            );
          }
          this.loadingPaid = false;
        },
        (err) => {
          this.snackBar.open('update failed!', 'CLOSE', {
            panelClass: ['snack-error'],
            duration: 2000,
          });
          this.loadingPaid = false;
        }
      );
  }

  getNonesAndEstimateGas = async (selectedAddress: any) => {
    let nonce = await web3.eth.getTransactionCount(selectedAddress);
    const estimateGas = await fetch(POLYGON_GAS_STATION).then((response) =>
      response.json()
    );
    return { nonce, estimateGas };
  };

  selectWallet() {
    this.dialog
      .open(WalletOptionsComponent, {
        panelClass: ['wallet-option-model'],
      })
      .afterClosed()
      .subscribe((key: any) => {
        this.switchToSelectWalletProvider(key);
      });
  }

  switchToSelectWalletProvider = (key: any) => {
    switch (key) {
      case 'METAMASK':
        this.payMetamask();
        break;
      case 'WALLETCONNECT':
        this.paywithMetamask = false;
        this.connectedToWallet = false;
        this.invoiceService.updateWalletStatus(null);    
        this.chainSelected = false;
        this.walletConnectService.init();
        break;
      default:
        break;
    }
  };

  getAccounts = (str: any) => {
    return this.commonService.getAccounts(str);
  };

  processPayment = async (coin: any) => {
    if (this.paywithMetamask) {
      this.pay(coin);
    } else {
      this.proceedWallectPayment(coin);
    }
  };

  proceedWallectPayment = async (coin: any) => {
    web3 = await this.walletConnectService.getWeb3Instance();
    const price = web3.utils.toBN(
      String(Math.round(this.coinPrice[coin].price * 100)) + '0'.repeat(6 - 2)
    );
    const { nonce, estimateGas } = await this.getNonesAndEstimateGas(
      this.walletObj.accounts[0]
    );

    const infuraNonce = await this.walletConnectService.getNonceByInfura(this.walletObj.accounts[0]);
    this.ethereum = { selectedAddress: this.walletObj.accounts[0] };
    const contractInstance: any = await this.getcontractInstance(
      this.NETWORKS[this.DEFUALT.NETWORK].CONTRACTADDRESSES[coin]
    );
    let data = await contractInstance.methods
      .transfer(this.toAddress, price)
      .encodeABI();

    const tx = {
      from: this.walletObj.accounts[0], // Required
      to: this.toAddress,
      data,
      gasPrice: web3.utils.toHex(10000000000), // Optional
      gasLimit: web3.utils.toHex(50000000000), // Optional
      gas: web3.utils.toBN(
      String(Math.round(estimateGas.fast.maxPriorityFee * 100)) +
        '0'.repeat(6 - 2)
    ), // Optional,
      value: price, // Optional,
      nonce: web3.utils.toHex(infuraNonce),
    };
    
    if (this.walletObj.peerMeta.name === 'WalletConnect Safe App') {
      this.walletConnectService.callProvideTx(tx, this.currenctChain).then(
        (res) => {
          const tx = {
            transactionHash: res.hash,
            nonce: res.nonce,
            network: this.chain?.SYMBOL,
            from: res.from,
          };
          this.updateDB(tx);
        },
        (error) => {
          this.error(error);
        }
      );
    } else {
      await this.invoicePaymnet(
        price,
        contractInstance,
        this.walletObj.accounts[0],
        { nonce:web3.utils.toHex(infuraNonce), estimateGas }
      );
    }
  };

  closeAllDialog = (stauts?:any) => {
    if(stauts || (this.invoiceData.isPayoutEnable !== undefined && !this.invoiceData.isPayoutEnable)) {
       this.dialog.closeAll();
    }
  }
}

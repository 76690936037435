export const APIENDPOINTS = {
  REFERAL: {
   GET_HISTORY: 'app/users/referral/history',
   GET_DEPOSIT_HISTORY: 'app/users/referral/deposit/history'
  },
  CSV: {
   UPLOAD_CSV_ONE: 'app/admin/create/csv/account',
   UPLOAD_CSV_TWO: 'app/admin/create/csv/account'
  },
  INVOICE: {
    CREATE: 'core/rn/invoice',
    MARK_PAID: 'app/core/rn/invoice/mark/sent',
    MARK_APPROVED:'app/core/rn/invoice/mark/received'
  },
  SUBSCRIPTION: {
    CREATE: 'app/core/subscription/rn/create',
  },
  PAYMENT_LINK: {
    CREATE: 'core/rn/invoice/paymentLink',
  },
  PAYOUT: {
   CREATE_RN_ORDER: 'core/rn/payoutOrder/create',
   CREATE_ORDER: 'core/payoutOrder/create',
   GET_ORDER:'core/payoutOrder/list',
   GET_EXCHANGE: 'core/exchange/token',
   GET_ALL_USERS: 'core/exchange/fetch/all/user',
   CREATE_USER: 'core/exchange/create/user',
   GET_SUPPORTED_COUNTRIES: 'service/exchange/supported/countries',
   VERIFYBENIFICIARY: 'core/exchange/fetch/all/whitelist/bank/accounts',
   WHITELIST_BANKACCOUNT: 'core/exchange/whitelist/bank/account',
   GET_KYC_URL: 'core/exchange/kyc',
   GET_QUOTES: "core/exchange/quote/amount",
   GET_PUBLIC_QUOTES: "service/exchange/estimated/quote/amount",
  },
  ONMETA: {
    LOGIN:'users/login',
    BANK_STATUS:'users/get-bank-status/',
    LINK_BANK_DETAILS:'users/account-link',
    GET_REFRESH_TOKEN:'users/refresh-token',
    GET_QUOTATION :'quote/sell',
    UPDATETXHASH :'offramp/orders/txnhash',
    OFFRAMP_CREATE_ORDER: 'offramp/orders/create',
    ONRAMP_FETCH_TOKEN:'tokens',
    ONRAMP_GET_Quotation:'quote/buy',
    ONRAMP_CREATE_ORDER:'orders/create',
    CHECK_KYC_STATUS:'users/kyc-status'
  },
  ENCRYPTUS: {
    PARTNERS_LOGIN:'partners/generate/token',// N
    CREATE_USER: 'partners/create/user', // Y Done
    GET_BY_USERID: 'partners/user', // N
    GET_ALL_USERS: 'partners/fetchall/user',// Y Done
    GET_KYC_URL : 'partners/kycurl',
    GET_SUPPORTED_COUNTRIES: 'payout/bankwire/supportedcountries',// Y  Y Done  
    GET_SUPPORTED_CURRENCIES: 'payout/bankwire/supportedcurrencies',// N
    WHITELIST_BANKACCOUNT: 'partners/user/forensics/whitelist/bankAccount', // Y Done  
    VERIFYBENIFICIARY: 'partners/user/forensics/fetchAll/whitelisted/BankAccount', // Y Done  
    GET_QUOTES:'payout/bankwire/quotebyamount', // Y Done
    SUBMIT_ORDER:'payout/bankwire/submitOrder/bank', // N
    
    GET_OPERATOR : "topup/carriers",
    GET_DETAILS_BY_MOBILENUMBER: "topup/mobile/lookup/",
    GET_PLAN:"topup/carrier/plans",
    GET_QUOTE:"topup/quote",
    GET_SUPPORTED_TOPUP_COUNTRY: "topup/country-code"
  }
};

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { APIENDPOINTS } from 'src/app/config/constants-endpoint';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-csv-upload',
  templateUrl: './csv-upload.component.html',
  styleUrls: ['./csv-upload.component.scss']
})
export class CsvUploadComponent implements OnInit {
  public csvOne:any = null;
  public csvTwo:any = null;
  constructor(  public commonService: CommonService,
    private snackBar: MatSnackBar,
    ) { }

  ngOnInit(): void {

  }

  docChange(files: any, type: any) {
    let fileSize = files.target.files[0].size / 1024 / 1024; // MB
    if(fileSize > 5.5){
      this.csvOne = null; //
      this.snackBar.open('File size must be less than 5MB', 'Close', { duration: 5000, panelClass:['snack-warn'] });
    } else {
      this.uploadFile(files, type) 
    }
  }

  uploadFile = (files: any, type: any) => {
    if(type === 'csvOne') {
      this.csvOne = files.target.files[0];
    }
    if(type === 'csvTwo') {
      this.csvTwo = files.target.files[0];
    }
  }

  uploadCSV = (type:string) => {
    let file = this.csvOne;
    let url = `${environment.url}${APIENDPOINTS.CSV.UPLOAD_CSV_ONE}`
    if(type === 'csvTwo') {
      file = this.csvTwo;
      url = `${environment.url}${APIENDPOINTS.CSV.UPLOAD_CSV_TWO}`
    }
    
    if(!file) {
      this.snackBar.open('Please upload CSV file', 'Close', { duration: 5000, panelClass:['snack-warn'] });
      return 
    }
    
    const formData: FormData = new FormData();
    formData.append('Image', file, file.name);
    this.commonService.commonPost(url, formData).subscribe(
      (res: any) => {
        console.log(res)
      },
      (err) => {

      }
    )
  }

}



const contractABI = [
    {
      constant: false,
      inputs: [
        {
          name: '_to',
          type: 'address',
        },
        {
          name: '_value',
          type: 'uint256',
        },
      ],
      name: 'transfer',
      outputs: [
        {
          name: '',
          type: 'bool',
        },
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    // balanceOf
    {
      constant: true,
      inputs: [{ name: '_owner', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ name: 'balance', type: 'uint256' }],
      type: 'function',
    },
    // decimals
    {
      constant: true,
      inputs: [],
      name: 'decimals',
      outputs: [{ name: '', type: 'uint8' }],
      type: 'function',
    },
  ];

export const NETWORKS_DEV = {

    MUMBAI: {
  
      CONTRACTADDRESSES: {
        USDC : '0x8f7116CA03AEB48547d0E2EdD3Faa73bfB232538',
        USDT: '0xAFfBD3D70B0e41385422338EB24E9dEc717880ed',
        BUSD: '0xE287B83CCA3e4AAf6E8546959B9f3FEa7455C373',
        DAI: '0x27da73AED24348F4A0053cbAbFfB8022938f632a',
        MATIC: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      },
  
      CONTACTABI: contractABI,
  
      DECIMALS: {
        USDT : 6,
        USDC : 6,
        DAI: 18, 
      },
  
      TXN_FEES: {
        USDT: 1,
        USDC: 1,
      },
  
      API: {
        Tx: 'https://mumbai.polygonscan.com/tx/',
        Address: 'https://mumbai.polygonscan.com/address/'
      }
    },
  
    // POLYGON: {
  
    //   CONTRACTADDRESSES: {
    //     USDT: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    //     USDC: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174'
    //   },
  
    //   CONTACTABI: contractABIMainnet,
  
    //   DECIMALS: {
    //     USDT : 6,
    //     USDC : 6, 
    //   },
  
    //   TXN_FEES: {
    //     USDT: 1,
    //     USDC: 1,
    //   },
  
    //   API: {
    //     Tx: 'https://mumbai.polygonscan.com/tx//',
    //     Address: 'https://mumbai.polygonscan.com/address//'
    //   }
    // }
  
  }

  export const CHAINS_DEV = [
    {
      ID: 80001,
      IDHEX: '0x13881',
      SYMBOL: 'MUMBAI',
      NAME: 'Mumbai Testnet',
      SECRET_TYPE: 'MATIC',
    },
    // {
    //   ID: 137,
    //   IDHEX: '0x89',
    //   SYMBOL: 'POLYGON',
    //   NAME: 'Polygon Mainnet',
    //   SECRET_TYPE: 'MATIC',
    // },  infura
  ]
export const POLYGON_INFURA_WEB3_API_DEV = 'https://polygon-mumbai.infura.io/v3/'
export const POLYGON_GAS_STATION_DEV = 'https://gasstation-testnet.polygon.technology/v2';

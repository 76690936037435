import { Component, OnDestroy, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CustomValidators} from 'src/app/auth/custom-validators';
import {AuthService} from 'src/app/core/services/auth.service';
import {CommonService} from 'src/app/core/services/common.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  userData:any;
  otpForm!: UntypedFormGroup;
  sentOTP: boolean = false;
  count:number = 0;
  showDialog: string = 'OTP';
  timeout:any;

  showOld: boolean = false;
  show: boolean = false;
  showC: boolean = false;
  resetForm!: UntypedFormGroup;
  actionCode :any;
  constructor(
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    private fb: UntypedFormBuilder,
    public commonService: CommonService,
    public snackBar: MatSnackBar,
    public authService: AuthService,
  ) { 
    this.userData = this.authService.getUserLocal();
  }

  ngOnInit(): void {
    this.otpForm = this.fb.group({
      otp1: [ null , Validators.required],
      otp2: [ null , Validators.required],
      otp3: [ null , Validators.required],
      otp4: [ null , Validators.required],
      otp5: [ null , Validators.required],
      otp6: [ null , Validators.required],
    });
    
    this.resetForm = this.fb.group({
      oldPassword: [null, Validators.required],
      password: [null, Validators.compose([
        Validators.required,
        Validators.minLength(8),
        CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,{ hasSpecialCharacters: true }),
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
      ])],
      confirmpassword: [null, Validators.compose([Validators.required])]
    }, 
    {
      // check whether our password and confirm password match
      validator: CustomValidators.passwordMatchValidator
    });

    this.timeout = setTimeout(() => this.sendEmailOTP(), 3500);
  }

  move(e:any, p:any, c:any, n:any){
    let length = c.value.length;
    let maxLength = c.getAttribute('maxlength');
    if(length == maxLength) {
      if(n != '') n.focus();
    }
    if(e.key == 'Backspace') {
      if(p != '') p.focus();
    }
  }

  close() {
    this.dialogRef.close();
  }

  timer() {
    this.count = 60;
    let x = setInterval(() => {
      this.count -= 1;
       if(this.count == 0) {
         clearInterval(x);
       }
     }, 1000)
   }

  sendEmailOTP(){
    this.commonService.sendEmailOTP('OTP for reset password').subscribe((res:any) => {
      this.snackBar.open(res.result.msg, 'Ok', { panelClass: ['snack-sucess'], });
      this.timer();
      this.sentOTP = true;
    }, err => {
      this.snackBar.open(err?.error.result.msg, 'Ok', { panelClass: ['snack-warn'], });
    })
  }

  verifyEmailOTP(){
    const data = {
      otp: Object.values(this.otpForm.value).join('')
    };
    
    this.commonService.verifyEmailOTP(data).subscribe((res:any) => {
      this.showDialog = 'PASSWORD';
    }, err => {
      this.snackBar.open(err?.error.result.msg, 'Ok', { panelClass: ['snack-warn'], });
    })
  }

  //
  passwordold(): void {
    this.showOld = !this.showOld;
  }
  password(): void {
    this.show = !this.show;
  }
  passwordC(): void {
    this.showC = !this.showC;
  }

  submit(form: UntypedFormGroup) {
    const newPass = form.controls.confirmpassword.value.trim();

    this.authService.signInWithEmailPassword(
      this.userData.email,
      form.controls.oldPassword.value
      )
      .then((u) => {
        u.user?.updatePassword(newPass).then(() => {
        this.getUserDatils();
        }).catch(err => {
          this.snackBar.open("Update password failed!", 'Close', { panelClass:['snack-error'] });
        });
      }).catch(err => {
        this.snackBar.open('Current password is wrong!', 'Close', { panelClass:['snack-error'] });
      });
  }

  getUserDatils() {
    this.authService.getUserDetails().subscribe(res => {
      this.authService.setUserLocal(res.result);
      this.snackBar.open("Password updated successfully!", 'Close', { panelClass:['snack-sucess'] });
      this.close();
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }
}

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

export interface Recaptcha {
  message: string;
  result: {
    message: string;
    success: boolean;
  };
}

export interface UserData {
  name: string;
  uid: string;
  email: string;
  emailVerified: boolean;
  mobNo: string;
  mobNoVerified: boolean;
  businessDetails: BusinessDetails;
  profileStatus: string;
  role: string;
  userType: string;
  created: number;
  type?:string,
  updated: number;
  profileVerified: boolean;
}

export interface BusinessDetails {
  name: string; //   Name of the company/LLP,
  regNo: string; //   Company CIN/FCRN/LLPIN/FLIPPIN
  businessType: string; //   Name of the company/LLP,
  address: {
    city: string;
    state: string;
    country: string;
    postcode: string;
    addr: string;
  }; //   Company's  Address,
  businessEstDate: number;
  bankDetails: {
    bankName: string;
    branch: string;
    country: string;
    swiftCode: string;
    accNo: string;
    currency: string;
    remarks: string;
    statement: [{ name: string; key: string }];
    created: number;
  };
  website: string; //   Company's Website,
  email: string; //   Company's Email ID
  mobNo: string; //   Company Mobile Number,
  revenue: string; //   Revenue,
  documents: [{ name: string; key: string }]; //   Revenue,
  tan: string; //   TAN,
  businessCategory: string;
  purpose: string;
  created: number;
  logo: {
    key: string;
  };
  currency: string;
  // gst: string, //   GST,
  // pan: string, //   PAN,
  // authSign: string, //   Authorized signatory.
}

// export const Stages = {
//   new: 'NEW',
//   emailVerified: 'EMAIL_VERIFIED',
//   mobileVerified: 'MOBILE_VERIFIED',
//   stage1Draft: 'STGE_1_DRAFT',
//   stage1Completed: 'STGE_1_COMPLETED',
//   stage2Draft: 'STGE_2_DRAFT',
//   stage2Completed: 'STGE_2_COMPLETED',
//   stage3Draft: 'STGE_3_DRAFT',
//   stage3Completed: 'STGE_3_COMPLETED',
//   submitted: 'SUBMITTED',
//   verfied: 'VERIFIED',
// }

// export const Stages = {
//   NEW: 0,
//   EMAIL_VERIFIED: 1,
//   MOBILE_VERIFIED: 2,
//   USER_TYPE: 2.5,
//   STGE_1_DRAFT: 3,
//   STGE_1_COMPLETED: 4,
//   STGE_2_DRAFT: 5,
//   STGE_2_COMPLETED: 6,
//   STGE_3_DRAFT: 7,
//   STGE_3_COMPLETED: 8,
//   SUBMITTED: 9,
//   VERIFIED: 10,
// };
export const Stages = {
  NEW: 0,
  EMAIL_VERIFIED: 1,
  MOBILE_VERIFIED: 2,
  USER_TYPE: 3,
  STGE_1_DRAFT: 4,
  STGE_1_COMPLETED: 5,
  STGE_2_DRAFT: 6, // mechant only
  STGE_2_COMPLETED: 7, // mechant only
  // STGE_3_DRAFT: 8,
  // STGE_3_COMPLETED: 9,
  WALLET: 8,
  SUBMITTED: 9,
  VERIFIED: 10,
};

export const USER_ROLES = {
  MERCHANT: 'MERCHANT',
  INDIVIDUAL: 'INDIVIDUAL'
}

export const allowedCountries = ['ae', 'us', 'in', 'al', 'ad', 'at', 'by', 'be', 'ba', 'bg', 'hr', 'cz','cn', 'dk',
'ee', 'fo', 'fi', 'fr', 'de', 'gi', 'gr', 'va', 'hu', 'is', 'ie', 'it', 'lv',
'li', 'lt', 'lu', 'mk', 'mt', 'md', 'mc', 'me', 'nl', 'no', 'pl', 'pt', 'ro',
'ru', 'sm', 'rs', 'sk', 'si', 'es', 'se', 'ch', 'ua', 'gb'];

export const preparedCountries = ['ae', 'us', 'in'];

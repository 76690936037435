import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Recaptcha, Stages, User, UserData } from '../../model/user';
import * as auth from 'firebase/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // fire auth
  stage: any = Stages;

  public _userSecertsData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public getSecertData$:Observable<UserData> = this._userSecertsData$.asObservable();

  // //update userData
  // updateUserData$(userData: any) {
  //   this._userDataService$.next(userData);
  // }

  constructor(
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    private router: Router,
    public http: HttpClient,
    public ngZone: NgZone, // NgZone service to remove outside scope warning

    private snackBar: MatSnackBar,
    private commonService: CommonService
  ) {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('u', 'u');
        // when refresh
        const userData = this.getUserLocal();
        if (userData) {
          this.getUserDetails().subscribe((res) => {
            if(!this.getUserLocal()) this.router.navigate(['dashboard']);
            this.setUserLocal(res.result);
          });
          this.settings().subscribe(res => {
            sessionStorage.setItem("stg", window.btoa(JSON.stringify(res.result)));
          });
        }
      } else {
        const authToken = this.commonService.getLocalStorage('userInfo');
        if(!authToken) {
          this.logout();
        }
      }
    });
  }

  // Sign up with email/password
  SignUp(email: string, password: string, name: string, referral:string) {
    this.commonService.showSpinner();
    this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        console.log('firebase signup');
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        const firebaseUser = {
          ...result.user,
          displayName: name,
          referral,
          type:'APP'
        };
        this.SendVerificationMail(result.user, firebaseUser);
      })
      .catch((error) => {
        this.snackBar.open(error.code, 'CLOSE', {
          panelClass: ['snack-error'],
        });
        this.commonService.hideSpinner();
      });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail(firebaseUser: any, updatedfirebaseUser: any) {
    this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        console.log('firebase verification email send');
        // pass toke to node backend
        this.afAuth.idToken.subscribe((res) => {
          this.signupWithNode(updatedfirebaseUser).subscribe(
            (res) => {
              //update profile
              firebaseUser?.updateProfile(updatedfirebaseUser);
              this.setUserLocal(res.result);
              this.router.navigate(['verify']);
              this.commonService.hideSpinner();
            },
            (err: any) => {
              this.snackBar.open(
                'something went wrong. Please try again!',
                'CLOSE',
                { panelClass: ['snack-error'] }
              )
              this.commonService.hideSpinner();
              });
        });
      })
      .catch((error) =>{
        this.snackBar.open(error.code, 'CLOSE', { panelClass: ['snack-error'] })
        this.commonService.hideSpinner();
      });
  }

  resendEmail() {
    this.afAuth.currentUser.then((u) => {
      u?.sendEmailVerification();
      this.snackBar.open('Email Sent!', 'CLOSE', { duration: 5000, panelClass: ['snack-sucess'] });
    }).catch(error => this.snackBar.open(error.code, 'CLOSE', { panelClass: ['snack-error'] })
    );
  }

  // email verification oobcode
  updateEmailverification(code: any) {
    return this.afAuth.applyActionCode(code);
  }

  doGoogle() {
    return this.afAuth.signInWithPopup(new auth.GoogleAuthProvider());
  }

  signInWithEmailPassword(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  // Returns true when user is looged in and email is verified
  isLoggedIn(): boolean {   
    const user = this.getUserLocal();  
    return (user !== null) ? true : false;
  }

  isEmailverified(): boolean {
    const user = this.getUserLocal(); 
    return (user !== null && user.emailVerified === true) ? true : false;
  }

  isAccSetupCompleted(): boolean {
    const user = this.getUserLocal(); 
    return (user !== null && this.stage[user.profileStatus] >= 5) ? true : false; //4
  }

  isWalletCreated(): boolean {
    const user = this.getUserLocal();
    return (user !== null && this.stage[user.profileStatus] >= 8) ? true : false; 
  }
  
  isMerchantUser(): boolean {
    const user = this.getUserLocal();
    return (user !== null && user.role === 'MERCHANT') ? false : false; 
  }

  isBusinessVerified(): boolean {
    const user = this.getUserLocal();
    return (user !== null && this.stage[user.profileStatus] >= 10) ? true : false;
  }

  isBankVerified(): boolean {
    const user = this.getUserLocal(); 
    return (user !== null && this.stage[user.profileStatus] >= 10) ? true : false;
  }

  istransferEnabled(): boolean {
    const s: any = sessionStorage.getItem('stg');
    if(!s) return true;
    const setting = JSON.parse(window.atob(s));
    return (!!setting.merchantSettings  &&  setting.merchantSettings.transferEnabled == 0) ?  false : true;
  }

  iswithdrawalEnabled(): boolean {
    const s: any = sessionStorage.getItem('stg');
    if(!s) return true;
    const setting = JSON.parse(window.atob(s));
    return (!!setting.merchantSettings &&  setting.merchantSettings.withdrawalEnabled == 0) ?  false : true;
  }

  logout() {
    this.afAuth.signOut();
    const redirectUrl = this.commonService.getLocalStorage('redirectUrl');
    if(!redirectUrl) {
      localStorage.clear();
      sessionStorage.clear();
    }
  }

  forgotPassword(email:string){
   return this.afAuth.sendPasswordResetEmail(email);
  }

  checkPasswordActionCode(code: any) {
    return this.afAuth.verifyPasswordResetCode(code);
  }

  confirmPaswordReset(code: any, newPassword:any) {
    return this.afAuth.confirmPasswordReset(code, newPassword);
  }

  setUserLocal(user:any) {
    localStorage.setItem("ud", window.btoa(JSON.stringify(user)));
  }

  getUserLocal() {
    const user: any = localStorage.getItem('ud');
    if(!user) return user;
    return JSON.parse(window.atob(user));
  }

  authState(): Observable<any> {
    return this.afAuth.authState;
  }

  currentUser(){
    return this.afAuth.currentUser;
  }

  /** kaleyra otp */

  generateOTP(): Observable<any> {
    const url = "https://api.kaleyra.io/v1/HXAP1729966316AE/verify";

    const options = { 
      headers: new HttpHeaders({
      'api-key': 'A9e409f4120704cffb675fff319b04610',
      'noAuth': ''
      })
    };

    const obj = {
      // "flow_id":"<FLOW_ID>",
       "to" : {
              "mobile" : "+919686882865"
        }
    }

    return this.http.post(url, obj, options);
  }

  validateOTP(): Observable<any> {
    const url = environment.url + 'public/signup';
    return this.http.post(url,{});
  }



  /** connecting with backend */
  signupWithNode(userData: any): Observable<any> {
    const url = environment.url + 'public/signup';
    return this.http.post(url, { userData });
  }

  validateCaptcha(recaptcha: string | null): Observable<Recaptcha> {
    const url = environment.url + 'public/validateCaptcha';
    return this.http.post<Recaptcha>(url, { recaptcha: recaptcha });
  }

  updateUserDetails(userData: any) {
    const url = environment.url + 'users/updateUserDetails';
    return this.http.put(url, { userData });
  }

  // get all data about user
  getUserDetails(): Observable<any> {
    const url = environment.url + 'users/userDetails';
    return this.http.get(url);
  }

  /************ Account Setup  */

  // complete registration - save finish later
  updateBusinessDetails(body: any): Observable<any> {
    const url = environment.url + 'users/updateBusinessDetails';
    return this.http.put(url, body);
  }

  // complete registration - next
  addBusinessDetails(body: any): Observable<any> {
    const url = environment.url + 'users/addBusinessDetails';
    return this.http.post(url, body);
  }

  addBusinessVerification(body: any): Observable<any> {
    const url = environment.url + 'users/addBusinessVerification';
    return this.http.post(url, body);
  }

  updateBankDetails(body: any): Observable<any> {
    const url = environment.url + 'users/updateBankDetails';
    return this.http.put(url, body);
  }

  submitProfile(): Observable<any> {
    const url = environment.url + 'users/submitProfile';
    return this.http.post(url, {});
  }

  createWallet(): Observable<any> {
    const url = environment.url + 'users/wallet';
    return this.http.post(url, {});
  }

  uploadFiles(body: any): Observable<any> {
    const url = environment.url + 'service/uploadFiles';
    // const options = { 
    //   headers: new HttpHeaders({
    //   'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
    //   })
    // };
    return this.http.post(url, body);
  }

  sendMobileOTP(data?:any): Observable<any> {
    const url = environment.url + 'users/sendMobileOTP';
    return this.http.post(url, {data});
  }

  verifyMobileOTP(data:any): Observable<any> {
    const url = environment.url + 'users/verifyMobileOTP';
    return this.http.post(url, {data});
  }

  settings(): Observable<any> {
    const url = environment.url + 'users/settings';
    const options = { 
      headers: new HttpHeaders({ 'noLoader': '', 'stg': ''})
    };
    return this.http.get(url, options);
  }

  updateSettings(merchantSettings:any): Observable<any> {
    const url = environment.url + 'users/settings';
    return this.http.post(url, {merchantSettings});
  }

  updateBusinessDetailssettings(body:any): Observable<any> {
    const url = environment.url + 'users/settings/updateBusinessDetails';
    return this.http.put(url, body);
  }

  updateProfile() {
    return this.afAuth.currentUser
  }

  updateUserDetailsSettings(userData:any): Observable<any> {
    const url = environment.url + 'users/settings/updateUserDetails';
    return this.http.put(url, {userData});
  }

  checkUserName(userName: any): Observable<any> {
    const url = environment.url + 'service/check';
    return this.http.post(url, {userName});
  }

  verifyHandle(data:any): Observable<any> {
    const url = environment.url + 'users/verifyHandle';
    return this.http.post(url, {data});
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { ConfirmModalComponent } from '../dialog/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public busNameTxt: string = 'BN';
  public businessName: string = 'Business Name';
  public userData: any;
  public moduleType: any = '';
  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private route: Router
  ) {
    this.userData = this.authService.getUserLocal();
  }

  ngOnInit(): void {
    if (this.userData?.businessDetails?.name) {
      this.businessName = this.userData?.businessDetails.name;
      let name = this.userData?.businessDetails.name.split(' ');
      if (name.length > 1) {
        this.busNameTxt =
          name[0].charAt(0).toUpperCase() + name[1].charAt(0).toUpperCase();
      } else {
        this.busNameTxt =
          name[0].charAt(0).toUpperCase() + name[0].charAt(1).toUpperCase();
      }
    }
  }

  getMenuDetails = (menu: any) => {
    switch (menu) {
      case 'FIAT withdrawals':
        return {
          heading: 'FIAT withdrawals',
          subheading: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at augue enim. Praesent eu luctus nulla, vitae ullamcorper elit. Morbi id ligula molestie nisi auctor ullamcorper. Sed sit amet porta eros, ac condimentum magna. Suspendisse potenti. Fusce lectus sem, dictum ultrices nibh nec, ultrices fermentum sem. Pellentesque turpis enim, consequat ut sagittis in, faucibus a ipsum. Vestibulum id maximus turpis. Duis faucibus maximus iaculis. Maecenas eget nibh et sem elementum pellentesque. Sed lorem lacus, facilisis at dapibus ut, egestas id risus. Vivamus scelerisque massa eu justo interdum blandit. Nunc fringilla in neque a lobortis.`,
        };
      case 'Payment Link':
        return {
          heading: 'Payment Link',
          subheading: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at augue enim. Praesent eu luctus nulla, vitae ullamcorper elit. Morbi id ligula molestie nisi auctor ullamcorper. Sed sit amet porta eros, ac condimentum magna. Suspendisse potenti. Fusce lectus sem, dictum ultrices nibh nec, ultrices fermentum sem. Pellentesque turpis enim, consequat ut sagittis in, faucibus a ipsum. Vestibulum id maximus turpis. Duis faucibus maximus iaculis. Maecenas eget nibh et sem elementum pellentesque. Sed lorem lacus, facilisis at dapibus ut, egestas id risus. Vivamus scelerisque massa eu justo interdum blandit. Nunc fringilla in neque a lobortis.`,
        };
      case 'Invoices':
        return {
          heading: 'Invoices',
          subheading: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at augue enim. Praesent eu luctus nulla, vitae ullamcorper elit. Morbi id ligula molestie nisi auctor ullamcorper. Sed sit amet porta eros, ac condimentum magna. Suspendisse potenti. Fusce lectus sem, dictum ultrices nibh nec, ultrices fermentum sem. Pellentesque turpis enim, consequat ut sagittis in, faucibus a ipsum. Vestibulum id maximus turpis. Duis faucibus maximus iaculis. Maecenas eget nibh et sem elementum pellentesque. Sed lorem lacus, facilisis at dapibus ut, egestas id risus. Vivamus scelerisque massa eu justo interdum blandit. Nunc fringilla in neque a lobortis."`,
        };
      default:
        return {};
    }
  };

  showDetails = (menu: any) => {
    const data = {
      ...this.getMenuDetails(menu),
      cancelBtn: '',
      okBtnLink: null,
      okBtn: 'Okay',
    };
    this.dialog
      .open(ConfirmModalComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res: any) => {});
  };

  handleAction = (link: any) => {
    this.route.navigate([link]);
  };
}

<div class="gws_form_control network_tab {{settings?.class}}">
  <div class="gws_label_optinal">
    <label>Choose Your Network</label>
    <span class="color_gray" *ngIf="settings?.isRequiredShow">Required</span>
  </div>

  <div class="network_row">
    <mat-chip-listbox aria-label="Network Selection" [multiple]="settings.multipleSelect">
      <mat-chip-option
        selected="{{ selectedNetworkList === item.value ? true : false }}"
        (click)="onSelect(item.value, selectedNetworkList, 'Network')"
        value="{{ item.value }}"
        *ngFor="let item of netWorks"
        ><img [src]="item.img" width="18" alt="{{ item.label }}">
         <ng-container >
          {{ item.label }}
         </ng-container>
        </mat-chip-option
      >
    </mat-chip-listbox>
  </div>
  <div class="gws_label_optinal">
    <label>Choose Your Currency</label>
    <span class="color_gray" *ngIf="settings?.isRequiredShow">Required</span>
  </div>

  <div class="currency_row">
    <mat-chip-listbox aria-label="Currency Selection" multiple="true">
      <mat-chip-option
        *ngFor="let item of getCurrencyList()"
        selected="{{
          selectedCurrencyList.indexOf(item.value) > -1 ? true : false
        }}"
        (click)="onSelect(item.value, selectedCurrencyList, 'Currency')"
        value="{{ item.value }}"
        ><img [src]="item.img" width="18" alt="{{ item.label }}"> {{ item.label }}</mat-chip-option
      >
    </mat-chip-listbox>
  </div>
  <div *ngIf="!settings?.noAddressField" class="gws_form_control gws_mat_select">
    <mat-select #matRef
      [(ngModel)]="networkObj.address"
      (selectionChange)="onSelectAddress($event)"
      disableOptionCentering
      placeholder="Select Address"
      disableRipple
    >
      <mat-option value="{{ item.address }}" *ngFor="let item of filterWalletAddress()">
        {{ item.walletName }} - {{ item.address }}
      </mat-option>
      <span class="gws_mat_options_divider"></span>
      <mat-option value="new_address"> + New Address</mat-option>
    </mat-select>
  </div>
</div>

<ng-container *ngIf="isLoader && isSubscriptionInvoice && (subscriptionStatus === status.CANCELLED || subscriptionStatus === status.EXPIRED || subscriptionStatus === status.COMPLETED)">
    <mat-card appearance="outlined" class="error_box">
     <div class="head">
        <mat-icon class="red_icn">cancel</mat-icon>
         <h3>Error</h3>
     </div>
     <p>Customer payment not allowed for the subscription at this stage.. Please contact the merchant for assistance.</p>
    </mat-card>
</ng-container>
<ng-container *ngIf="isLoader && !isSubscriptionInvoice || isLoader && isSubscriptionInvoice && subscriptionStatus != status.CANCELLED && subscriptionStatus != status.EXPIRED && subscriptionStatus != status.COMPLETED">
   <div class="gws_title_flex">
     <div class="ref-back-btn" *ngIf="isPayoutEnable && isWalletConnected">
      <mat-icon class="color_primary" (click)="backToWalletConnect()">arrow_back</mat-icon>
     </div>
    <h2>Payment Summary</h2>
    <mat-icon *ngIf="isPayoutEnable" class="color_primary" (click)="close(false)">close</mat-icon>
   </div> 
   
    <div class="gws_flex_container">
      <div class="gws_invoice_container" *ngIf="!isPayoutEnable">
        <div class="message_badge" *ngIf="invoiceData?.invoiceStatus === 'PAID'">
          <mat-icon>error_outline</mat-icon>
          <span
            >Payment was successful. You will receive an receipt of this payment
            shortly.</span
          >
        </div>
        <ng-container *ngIf="isSubscriptionInvoice && isSubscriptionView">
          <app-subscription-invoice-card
            [invoiceData]="invoiceData"
          ></app-subscription-invoice-card>
        </ng-container>
        <mat-card appearance="outlined" *ngIf="!isSubscriptionView">
          <div class="top_box">
            <div class="logo_container">
              <img [src]="sanitizeURL(logoURL)" *ngIf="logoURL" alt="" />
    
              <h4 *ngIf="!logoURL">{{ invoiceData?.billFrom?.name }}</h4>
            </div>
    
            <div class="invoice_title">
              <h2>INVOICE</h2>
    
              <div class="gws_form_control input_invoice_no">
                <p><b>Invoice #</b> {{ invoiceData?.invoiceID }}</p>
              </div>
            </div>
          </div>
    
          <div class="text_box">
            <p>{{ invoiceData?.description }}</p>
          </div>
    
          <div class="customer_container">
            <div class="customer">
              <div class="customerDetails">
                <h3>{{ invoiceData?.billTo?.name }}</h3>
                <p>
                  {{ invoiceData?.billTo?.email }} |
                  {{ invoiceData?.billTo?.mobNo }}
                </p>
              </div>
              <div class="billingCurrency d-none" *ngIf="invoiceData?.currencyName">
                <h6>Invoice Currency</h6>
                <p>{{ invoiceData?.currencyName }}</p>
              </div>
            </div>
    
            <div class="cus_date">
              <div class="date_label_container">
                <p>
                  <span>Date Issued:</span> {{ commonService.getDate(invoiceData?.issuedDate) }}
                </p>
              </div>
              <div class="date_label_container">
                <p><span>Due Date:</span> {{ commonService.getDate(invoiceData?.dueDate) }}</p>
              </div>
            </div>
          </div>
    
          <div class="customer_address">
            <div class="bill_addr">
              <h6>Billing Address</h6>
              <ng-container *ngIf="invoiceData?.billTo?.billingAddr">
                <p>{{ invoiceData?.billTo.billingAddr.addr }} <br> 
                    {{invoiceData?.billTo.billingAddr.city}}, 
                    {{invoiceData?.billTo.billingAddr.state}}, 
                    {{invoiceData?.billTo.billingAddr.country}}, 
                    {{invoiceData?.billTo.billingAddr.postcode}}, 
                </p>
            </ng-container>
            <ng-container *ngIf="!invoiceData?.billTo?.billingAddr">--</ng-container>
            </div>
            <div class="ship_addr">
              <h6>Shipping Address</h6>
              <ng-container *ngIf="invoiceData?.billTo?.shippingAddr">
                <p>{{ invoiceData?.billTo.shippingAddr.addr }} <br> 
                    {{invoiceData?.billTo.shippingAddr.city}}, 
                    {{invoiceData?.billTo.shippingAddr.state}}, 
                    {{invoiceData?.billTo.shippingAddr.country}}, 
                    {{invoiceData?.billTo.shippingAddr.postcode}}, 
                </p>
            </ng-container>
            <ng-container *ngIf="!invoiceData?.billTo?.shippingAddr">--</ng-container>

            </div>
          </div>
    
          <div class="table_container">
            <table>
              <thead>
                <tr class="gws_table_row">
                  <th>{{isSubscriptionInvoice ? 'Plan Code' : 'Item Code' }}</th>
                  <th>{{isSubscriptionInvoice ? 'Plan Name' : 'Item Name' }}</th>
                  <th>{{isSubscriptionInvoice ? 'Rate/Plan' : 'Rate/Item' }}</th>
                  <th>Quantity</th>
                  <th>Tax</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of invoiceData?.items; let i = index"
                  [ngClass]="invoiceData?.items?.length - 1 === i ? 'last-row' : ''"
                >
                  <td>
                    {{ item?.itemDetails?.itemCode }}
                  </td>
                  <td class="items_td">
                    {{ item?.itemDetails?.name }}
                  </td>
                  <td>
                    {{ item.unitPrice }}
                  </td>
                  <td>
                    {{ item.quantity }}
                  </td>
                  <td>
                    <ng-container *ngIf="item.tax && !isSubscriptionView && !isSubscriptionInvoice">
                        {{ item.taxType }} | {{ item.tax }}
                    </ng-container>
                    <ng-container *ngIf="!item.tax && !isSubscriptionView && !isSubscriptionInvoice">-</ng-container>
                    <ng-container *ngIf="!isSubscriptionView && isSubscriptionInvoice">
                        {{ totalTaxAmount | currency: invoiceData?.invoiceCurrency | space }}
                    </ng-container>
                  </td>
                  <td>
                    <ng-container *ngIf="!isSubscriptionView && isSubscriptionInvoice">
                      {{ item?.unitPrice | currency: invoiceData?.invoiceCurrency | space }}
                  </ng-container>
                  <ng-container *ngIf="!isSubscriptionView && !isSubscriptionInvoice">
                      {{ item.amount | currency: invoiceData?.invoiceCurrency | space }}
                  </ng-container>
                  </td>
                </tr>
                <tr class="calculation_bar">
                  <td colspan="3"></td>
                  <td colspan="2">Amount</td>
                  <td>
                    {{
                      totalWithoutTax
                        | currency : invoiceData?.invoiceCurrency
                        | space
                    }}
                  </td>
                </tr>
                <tr class="calculation_bar" *ngIf="totalDiscount > 0 && !isSubscriptionView && isSubscriptionInvoice">
                  <td colspan="3"></td>
                  <td colspan="2">Applied Discount<mat-icon *ngIf="offerId" class="info" aria-hidden="false" matTooltip="{{offerId.description}}"
                    [matTooltipPosition]="'above'">info</mat-icon></td>
                  <td>-{{ totalDiscount | currency: invoiceData?.invoiceCurrency | space }}</td>
               </tr>

                <tr class="calculation_bar">
                  <td colspan="3"></td>
                  <td colspan="2">Include Tax
                    <ng-container *ngIf="planId">
                      <ng-container *ngIf="planId?.tax?.type === '%'">
                        ({{planId?.tax?.amount}}{{planId?.tax?.type}})
                    </ng-container>
                    <ng-container *ngIf="planId?.tax?.type === '#'">
                        (Flat {{ planId?.tax?.amount | currency: invoiceData?.invoiceCurrency | space }})
                    </ng-container>
                    </ng-container>
                  </td>
                  <td>
                    +{{
                      totalTaxAmount
                        | currency : invoiceData?.invoiceCurrency
                        | space
                    }}
                  </td>
                </tr>
                
                <tr class="calculation_bar">
                  <td colspan="3"></td>
                  <td colspan="2"><b>Total amount</b></td>
                  <td>
                    <b>{{
                      totalAmount || invoiceData?.convertedAmount
                        | currency : invoiceData?.invoiceCurrency
                        | space
                    }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text_box">
            <p>{{ invoiceData?.note }}</p>
          </div>
          <p class="footer_addr">
            {{ invoiceData?.billFrom?.name }} |
            {{ invoiceData?.billFrom?.address.addr }},
            {{ invoiceData?.billFrom?.address.city }},
            {{ invoiceData?.billFrom?.address.state }},
            {{ invoiceData?.billFrom?.address.postcode }} |
            {{ invoiceData?.submittedBy?.mobNo }} |
            {{ invoiceData?.submittedBy?.email }}
          </p>
        </mat-card>
      </div>
    
      <div class="gws_customer_container" id="gws_customer_container" [ngClass]="isPayoutEnable ? 'payoutbox' : ''">
        <mat-card appearance="outlined">
          <div class="flex_row">
            <label class="muted">Payment Status</label>
            <span
              class="gws_chip"
              *ngIf="invoiceData?.invoiceStatus != 'AWAITING_PAYMENT'"
              [ngClass]="invoiceData?.invoiceStatus | lowercase"
              >{{ invoiceData?.invoiceStatus }}</span
            >
            <span
              class="gws_chip paid"
              *ngIf="invoiceData?.invoiceStatus == 'AWAITING_PAYMENT'"
              >PROCESSING</span
            >
            <!-- <span class="gws_chip paid" *ngIf="paymentSucess">Paid</span> -->
          </div>
          <div class="flex_row" [ngClass]="payoutType === 'ENCRYPTUS' && isPayoutEnable ? 'pt-25': ''">
            <div class="refreshquotation" *ngIf="isPaymentMethodEnable && payoutType === 'ENCRYPTUS' && isPayoutEnable">
              <p>You have <span id="ptTimer"></span> min left to complete this payment.</p>
            </div>
            <label class="muted">Amount</label>
            <h3>
              <ng-container *ngIf="isPayoutEnable">
                {{ totalAmount}} {{data?.quotation?.coin}}
              </ng-container>
              <ng-container *ngIf="!isPayoutEnable">
              {{ totalAmount | currency : invoiceData?.invoiceCurrency | space }}
              <ng-container *ngIf="isSubscriptionInvoice && totalTaxAmount > 0">
                <p>
                  (Including tax
                  {{
                    totalTaxAmount
                      | currency : invoiceData?.invoiceCurrency
                      | space
                  }})
                </p>
              </ng-container>
            </ng-container>
            </h3>
          </div>
          <div class="flex_row" *ngIf="invoiceData?.paymentMode">
            <label class="muted">Payment Method</label>
            <div class="networkList">
              <span>
                <img width="18" [src]="invoiceData?.paymentMode?.networkObj?.img" />
              </span>
              <span>{{ invoiceData?.paymentMode?.networkObj?.label }}</span>
            </div>
          </div>
    
          <ng-container
            *ngIf="
              isPaymentMethodEnable && ((invoiceData?.invoiceStatus === 'SUBMITTED' ||
              invoiceData?.invoiceStatus === 'OVERDUE'
              ) || isSubscriptionView && invoiceData?.invoiceStatus != 'AWAITING_PAYMENT' && invoiceData?.invoiceStatus != 'PAID')">
            <ng-container
              *ngIf="
                (isSubscriptionInvoice &&
                  (subscriptionStatus == status.ACTIVE ||
                  subscriptionStatus == status.CREATED ||
                  subscriptionStatus == status.PENDING)) ||
                !isSubscriptionInvoice"
            >
            <button class="gws_btn_primary" (click)="enableSubscriptionPayment()" *ngIf="!enabledPayment && isSubscriptionInvoice">
              Start Subscription
             </button>
             
              <app-payment-actions
                [invoiceData]="invoiceData"
                (data)="reacivedInvoiceData($event)"
                *ngIf="(!invoiceData?.transactionDetails && enabledPayment) || (invoiceData.allowPayment && isSubscriptionInvoice)"
              ></app-payment-actions>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!isPaymentMethodEnable">
            <div class="oop_message">
              <h3>Oops!</h3>
              <p>looks like you couldn’t complete the payment in time, please try again.</p>
              <div class="buttons">
                <button class="gws_btn_primary" (click)="close(false)">
                   Give it another try!
                </button>
              </div>
            </div>
          </ng-container>
        </mat-card>
      </div>
    </div>
    
    <!-- model dialog -->
    <m-web3-modal
      title="Connect to Wallet"
      description=""
      descriptionGray="Sign in with one of available wallet providers or create a new wallet"
      dismissText="Close"
    ></m-web3-modal>
    
</ng-container>

<ng-container *ngIf="!paywithMetamask && !showQR && !walletObj">
    <!-- <button class="gws_btn_primary" (click)="payMetamask()">
        Pay with MetaMask
    </button> -->
    <button class="gws_btn_primary" (click)="selectWallet()">
        Choose your wallet
    </button>
    <p>or select a cryptocurrency to pay with another wallet</p>
    <div class="coins">
        <button class="gws_btn_secondary pay_btn" *ngFor="let coin of coins" (click)="clickShowQR(coin.symbol)">
            <img [src]="coin.img" [alt]="coin.symbol">
            <span>{{coin.symbol}} </span>
        </button>
    </div>
</ng-container>

<ng-container *ngIf="paywithMetamask && !walletObj">
    <button class="gws_btn_primary" (click)="btnClick()"
        [ngClass]="connectedToWallet && chainSelected ? 'green-bg': ''">
        {{ chainSelected ? 'Connected to Matic' : connectedToWallet ? 'Switch to polygon' : 'Connect to Wallet' }}
    </button>

    <div class="message_badge warn" *ngIf="connectedToWallet && !chainSelected">
        <mat-icon>error_outline</mat-icon>
        <span>Switch to Polygon Network to make payment.</span>
    </div>
</ng-container>
<ng-container *ngIf="walletObj?.connected">
    <div class="wallect-options">
        <div class="flex-class">
          <span>Wallect Connected with </span>
          <span class="chip" *ngIf="walletObj?.accounts && walletObj?.accounts?.length > 0" aria-label="Wallect Selected">
              {{getAccounts(walletObj.accounts[0])}} 
              <mat-icon
              mat-raised-button
              matTooltip="Copy to clipboard"
              [matTooltipPosition]="'above'"
              class="color_primary copy"
              (click)="copy(walletObj?.accounts)"
              >content_copy</mat-icon
            >
          </span>
        </div>
  
      <button class="gws_btn_primary" mat-raised-button
      matTooltip="Disconnect your wallet"
      [matTooltipPosition]="'above'" (click)="walletConnectService.disconnect()">
          Disconnect
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="paywithMetamask || (walletObj && walletObj?.connected)">
    <p>select a cryptocurrency to pay</p>
      <button class="gws_btn_secondary pay_btn" *ngFor="let coin of coins"
          (click)="(chainSelected || walletObj) && coinPrice && coinPrice[coin.symbol]?.price !== 0 && processPayment(coin.symbol)">
          <img [src]="coin.img" [alt]="coin.name">
          <span>{{coin.symbol}} <span *ngIf="coinPrice && coinPrice[coin.symbol]?.price !== 0">({{ coinPrice[coin.symbol]?.price |
                  number : '1.2-2' }})</span></span>
      </button>
     
  </ng-container>
<ng-container *ngIf="showQR && !walletObj">
    <div class="forward-address">
        <h3>Send {{ coin }} on Polygon Network</h3>
        <p class="muted">Pay</p>
        <div>
            <h3 class="address">{{ invoiceData?.forwarderContract?.address }}</h3>
            <mat-icon class="gws_c_p copy_icon" (click)="copy(invoiceData?.forwarderContract?.address)">content_copy
            </mat-icon>
        </div>

        <div class="coin-symbol">
            <p class="muted">{{ coin }}</p>
            <span>
                <h3>{{ getValue() }}</h3>
                <img src="assets/images/{{coin | lowercase}}.svg" alt="">
            </span>
        </div>

        <img *ngIf="invoiceData?.forwarderContract?.address"
            src="https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl={{invoiceData?.forwarderContract?.address}}&choe=UTF-8"
            alt="">
    </div>

    <div class="message_badge">
        <mat-icon>error_outline</mat-icon>
        <span>Only send {{ coin }} to this address from Polygon Network. Don't send {{ coin }} from other networks or it
            will result in a loss of funds.</span>
    </div>
    <p style="padding: 10px auto;">Please click on the paid button once you have completed the payment</p>
    <div class="ref-back-btn">
        <P class="color_primary gws_c_p" (click)="!loadingPaid && paid()">I have Paid</P>
        <p class="color_primary text-right gws_c_p" *ngIf="paywithMetamask || showQR" (click)="back()">Back</p>
    </div>
</ng-container>
<p><b>Disclaimer:</b> please don't cancel or speed up the transaction</p>


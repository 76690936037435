import { Inject, Injectable } from '@angular/core';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5'
import {
  RequestNetwork,
  Types,
  Utils,
} from "@requestnetwork/request-client.js";
import { ethers, providers, utils } from "ethers";
import { Web3SignatureProvider } from "@requestnetwork/web3-signature";
import {
  approveErc20,
  hasErc20Approval,
  hasSufficientFunds,
  payRequest,
} from "@requestnetwork/payment-processor";
import { getPaymentNetworkExtension } from "@requestnetwork/payment-detection";
import { BehaviorSubject } from 'rxjs';
import { CommonService } from './common.service';
import { NETWORKSCONFIG } from 'src/app/config';
import { MatSnackBar } from '@angular/material/snack-bar';
import Web3 from 'web3';
import { mainnetNetworkMapper } from 'src/app/config/constants-walletconfig';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { WalletService } from './wallet.service';
import { payoutStatus } from 'src/app/model/transfer';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
enum APP_STATUS {
  AWAITING_INPUT = "awaiting input",
  SUBMITTING = "submitting",
  PERSISTING_TO_IPFS = "persisting to ipfs",
  PERSISTING_ON_CHAIN = "persisting on-chain",
  REQUEST_CONFIRMED = "request confirmed",
  APPROVING = "approving",
  APPROVED = "approved",
  PAYING = "paying",
  REQUEST_PAID = "request paid",
  ERROR_OCCURRED = "error occurred",
}
let web3: any;
@Injectable({
  providedIn: 'root'
})
export class Web3modalService {
  public modal: any
  public provider: any
  public address: any;
  public network: any;
  public isConnected: boolean = false;
  public wallectConnectStatus$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public requestStatus$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public callback: any = null;
  public timeout: any = null;
  public counter: any = 0;
  constructor(
    @Inject(DOCUMENT) private document: any,
    public router: Router, 
    private spinner: NgxSpinnerService,
    private walletService: WalletService,
    public snackBar: MatSnackBar, 
    public commonService: CommonService) {

  }

  updateRequestStatus(status: any) {
    this.requestStatus$.next(status);
  }

  updateWalletStatus(status: any) {
    this.wallectConnectStatus$.next(status);
  }

  getNetworkConfig = (paymentMode: any) => {
    let currentNetwork = mainnetNetworkMapper[paymentMode.network.toLowerCase()];
    const network: any = NETWORKSCONFIG.networks.find((network: any) => network.name === currentNetwork);
    const { currency } = paymentMode;
    let token: any
    if (currency && currency.length > 0) {
      let curr = currency[0] === 'FAU' ? 'goerli' : currency[0];
      token = network?.chains.find((chain: any) => chain.name === curr.toLowerCase());
     
    }
    let networkObj = JSON.parse(JSON.stringify(network));
    delete networkObj?.chains;
    return { network: networkObj, token };

  }

  initializeWeb3Modal = (paymentMode?: any) => {

    // 2. Set chains
    const mainnet = paymentMode ? { ...this.getNetworkConfig(paymentMode).network } : { ...NETWORKSCONFIG.networks[0] };

    // 3. Create modal
    const metadata = {
      name: 'intrXn',
      description: 'Easily manage your crypto finances with intrXn',
      url: 'https://intrxn.com/',
      icons: ['https://intrxn.com/wp-content/uploads/2022/06/INTRXN_icon.svg']
    }

    this.modal = createWeb3Modal({
      ethersConfig: defaultConfig({ metadata }),
      chains: [mainnet],
      projectId: '4a720190b7887c8b7d17fc33c552de1d'
    })
    if(!this.router.url.includes('/payment/') && !this.modal.hasSyncedConnectedAccount) {
      localStorage.removeItem('isConnected');
    }
    this.modal.subscribeProvider(this.getProviderDetails.bind(this));
  }

  getProviderDetails({ provider, providerType, address, chainId, isConnected }: {
    provider: any, providerType: any, address: any, chainId: any, isConnected: any
  }) {
    this.isConnected = isConnected;
    this.provider = provider;
    this.address = address;
    this.network = chainId;
    web3 = new Web3(provider);
    this.updateWalletStatus({provider, providerType, address, chainId, isConnected});
    this.commonService.setLocalStorage('isConnected', this.isConnected);
    if (this.callback) {
      this.callback();
      this.callback = null;
    }
  }

  getConnectChain = () => {
    return this.network;
  }

  getAddress = () => {
    return this.address;
  }

  disconnect = () => {
    if (this.getWalletStatus() && this.modal) {
      this.updateWalletStatus(null);
      this.modal.disconnect();
    }
  }

  getWalletStatus = () => {
    return this.isConnected;
  }

  openWeb3Modal = (cb?: any) => {
    if (cb) this.callback = cb;
    this.modal.open();
  }

  getProvider = (network: any) => {
    return new providers.Web3Provider(this.provider, network);
  }

  switchNetwork = (network:any, cb:any) => {
    try {
      this.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: network.idhex}],
      }).then(() => {
        if(cb) {
          this.network = network.chainId;
          cb();
        }
      });
     
    } catch (switchError:any) {
      // The network has not been added to MetaMask
      if (switchError.code === 4902) {
        this.showAleartMsg(`Please add the Polygon network to MetaMask`, 'snack-error');      
      }
      this.showAleartMsg(`Cannot switch to the network`, 'snack-error');      
    }
  }

  getSigner = (provider: any) => {
    return provider.getSigner(this.address);
  }

  generateSignature = async (provider: any, payload: any) => {
    const signer = provider.getSigner();
    const messageBytes = utils.keccak256(utils.toUtf8Bytes(payload));
    const signature = await signer?.signMessage(messageBytes);
    return signature;
  }

  approve = async (provider: any, requestData: any, cb?: any) => {
    this.commonService.showSpinner();
    this.updateRequestStatus({ requestData, status: 'starting...', loaderOn: true });
    const { requestNetwork } = requestData;
    const signer = this.getSigner(provider);
    // const requestClient = new RequestNetwork({
    //   nodeConnectionConfig: {
    //     baseURL: NETWORKSCONFIG.baseURL,
    //   },
    // });
    //const { requestId } = requestData;
    try {
      //const _request = await requestClient.fromRequestId(
      //  requestId
      //);
      //let _requestData = _request.getData();
      this.addClass();
      this.showAleartMsg(`Checking if payer has sufficient funds...`, 'snack-info');
      const _hasSufficientFunds = await hasSufficientFunds(
        requestNetwork,
        this.address as string,
        { provider: provider }
      );
      if (!_hasSufficientFunds) {
        this.addClass();
        this.showAleartMsg('Please add sufficient funds.', 'snack-error')
        this.updateRequestStatus({ requestData:requestNetwork, status: APP_STATUS.REQUEST_CONFIRMED, loaderOn: false });
        return;
      }
      if (
        getPaymentNetworkExtension(requestNetwork)?.id ===
        Types.Extension.PAYMENT_NETWORK_ID.ERC20_FEE_PROXY_CONTRACT
      ) {
        this.addClass();
        // this.showAleartMsg('ERC20 Request detected. Checking approval...', 'snack-info');
        const _hasErc20Approval = await hasErc20Approval(
          requestNetwork,
          this.address as string,
          provider
        );
        if (!_hasErc20Approval) {
          const approvalTx = await approveErc20(requestNetwork, signer);
          await approvalTx.wait(2);
        }
      }
      this.addClass();
      this.showAleartMsg('ERC20 Request approved...', 'snack-sucess')
      this.updateRequestStatus({ requestData:requestNetwork, status: APP_STATUS.APPROVED, loaderOn: true });
      if (cb) cb(true);
    } catch (err) {
      console.log(err);
      this.commonService.hideSpinner();
      this.removeClass();
      this.updateRequestStatus({ requestData:requestNetwork, loaderOn: false });
    }
  }

  payRequest = async (provider: any, requestData: any) => {
    this.commonService.showSpinner();
    const signer = this.getSigner(provider);
    // const requestClient = new RequestNetwork({
    //   nodeConnectionConfig: {
    //     baseURL: NETWORKSCONFIG.baseURL,
    //   },
    // });
    //const { requestId } = requestData;
    const { requestNetwork } = requestData;
    try {
      // const _request = await requestClient.fromRequestId(
      //   requestId
      // );
      //let _requestData = _request.getData();
      const paymentTx = await payRequest(requestNetwork, signer);
      const res = await paymentTx.wait(2);
     // const timer = 30000;
     // let currentTime = 0;
      // Poll the request balance once every second until payment is detected
      // TODO Add a timeout
      this.handleTimeout(requestData);
      // while (requestData.balance?.balance! < requestData.expectedAmount) {
      //   requestData = await requestData.refresh();
      //   // console.log(`balance = ${_requestData.balance?.balance}`);
      //   await new Promise((resolve) => {
      //     setTimeout(resolve, 1000);
      //     currentTime += 1000;
      //   });
      //   if(currentTime >= timer) {
      //     this.updateRequestStatus({ requestData: requestData, status: APP_STATUS.REQUEST_PAID, loaderOn: false });
      //     break;
      //   }
      // }
      //this.removeClass();
     // this.updateRequestStatus({ requestData: requestNetwork, status: APP_STATUS.REQUEST_PAID, loaderOn: false });
    } catch (err) {
      console.log(err);
      this.removeClass();
      this.commonService.hideSpinner();
      this.updateRequestStatus({ requestData: requestNetwork, loaderOn: false });
    }
  }

  handleTimeout = (requestData:any) => {
    this.timeout = setTimeout(this.executeAPI.bind(this, requestData), 4000);
  }
  executeAPI = (requestData:any) => {
    this.counter += 1;
    const { requestNetwork } = requestData;
    this.spinner.show();
    let tx = {
      transactionHash: null,
      nonce: null,
      network: null,
      from: null,
    };
    const url = environment.url + 'service/' + requestData.refID +'/payment';
    this.commonService.commonPost(url, { invoice: tx}, {noLoader:true}).subscribe((res:any) => {
        if((res.result && res.result.invoiceStatus == payoutStatus.PAID.toUpperCase()) || this.counter === 5) {
          clearTimeout(this.timeout);
          this.spinner.hide();
          this.removeClass();
          this.updateRequestStatus({ requestData: requestNetwork, status: APP_STATUS.REQUEST_PAID, loaderOn: false });
        } else {
          this.handleTimeout(requestData);
        }
      },
      (err) => {
        if(this.counter === 5) {
          clearTimeout(this.timeout);
          this.removeClass();
          this.spinner.hide();
          this.updateRequestStatus({ requestData: requestNetwork, status: APP_STATUS.REQUEST_PAID, loaderOn: false });
        } else {
          this.handleTimeout(requestData);
        }
      }
    );
  }

  showAleartMsg = (msg: any, className: any) => {
    this.snackBar.open(msg, 'CLOSE', {
      panelClass: [className], duration: 5000
    });
  }

  getPrice = (price:any, decimal:any, token:any) => {
    const roundDecimal = Math.round(price * 100);
    if(token.network === 'mainnet') {
      switch(token.name) {
        case 'usdt': 
        case 'usdc': 
        return web3.utils.toWei(roundDecimal.toString(), 'mwei');
        case 'eth':
        return web3.utils.toWei(roundDecimal.toString(), 'ether');
        default: 
        return web3.utils.toWei(roundDecimal.toString(), 'ether');
      }
    } 
    if(token.network === 'matic') {
      return web3.utils.toBN(String(roundDecimal) + '0'.repeat(decimal - 2));
    }
  }

  createRequest = async (provider: any, formData:any, payeeAddress:any, dialog:any) => {
    let requestData:any = null;
    const paymentMode = {
      network: formData.network,
      currency: formData.currency,
      address: formData.address
    }
    const {token} = this.getNetworkConfig(paymentMode);
    const web3SignatureProvider = new Web3SignatureProvider(this.provider);

    const requestClient = new RequestNetwork({
      nodeConnectionConfig: {
        baseURL: NETWORKSCONFIG.baseURL,
      },
      signatureProvider: web3SignatureProvider,
    });
    const requestCreateParameters: Types.ICreateRequestParameters = {
      requestInfo: {
        currency: {
          type: token!.type,
          value: token!.ensAddress,
          network: token!.network,
        },
        expectedAmount: this.getPrice(formData.amount, token!.decimals, token).toString(),
        payee: {
          type: Types.Identity.TYPE.ETHEREUM_ADDRESS,
          value: payeeAddress as string,
        },
        timestamp: Utils.getCurrentTimestampInSecond(),
      },
      paymentNetwork: {
        id: Types.Extension.PAYMENT_NETWORK_ID.ERC20_FEE_PROXY_CONTRACT,
        parameters: {
          paymentNetworkName: token!.network,
          paymentAddress: formData.address || payeeAddress,
          feeAddress: '0x0000000000000000000000000000000000000000',
          feeAmount: "0",
        },
      },
      contentData: {
        reason: formData.dueDate,
        dueDate: formData.note,
        builderId: "request-network",
        createdWith: "https://business.intrxn.com/",
      },
      signer: {
        type: Types.Identity.TYPE.ETHEREUM_ADDRESS,
        value: payeeAddress as string,
      },
    };

    // if (payerIdentity.length > 0) {
    //   requestCreateParameters.requestInfo.payer = {
    //     type: Types.Identity.TYPE.ETHEREUM_ADDRESS,
    //     value: payerIdentity,
    //   };
    // }
    try {
      const request = await requestClient.createRequest(
        requestCreateParameters
      );

      requestData = request.getData();
      const confirmedRequestData = await request.waitForConfirmation();
      requestData = confirmedRequestData;
      return requestData;
    } catch (err) {
      dialog.closeAll();
      this.showAleartMsg("Can't create on-chain invoice, please try again", 'snack-error')
      this.commonService.hideSpinner();
      return err;
    }
  }

  addClass = () => {
    if(this.router.url.includes('/transfer-to-bank-account')) {
      this.document.body.classList.add('paymentPage');
    }
  }
  
  removeClass = () => {
    if(this.router.url.includes('/transfer-to-bank-account')) {
      this.document.body.classList.add('payoutPage');
    }
  }
}

<div class="wallet-options">
      <div class="dialog_right">
            <mat-icon class="color_primary" (click)="close()">close</mat-icon>
      </div>
      <div class="header">
            <h5 class="color_primary">Choose your wallet</h5>
      </div>
      <div class="container">
            <div class="wallet-list">
                  <ul>
                        <li (click) ="onSelectWallet(option.key)" *ngFor="let option of walletOptions">
                              <div class="wallet-icon">
                                    <img [src]="option.img" [alt]="option.label" />
                              </div>
                              <span>{{option.label}}</span>
                        </li>
                  </ul>
            </div>
      </div>
</div>
import { Types } from "@requestnetwork/request-client.js"

export const testnet = {
      baseURL: 'https://goerli.gateway.request.network',
      networks: [
            {
                  chainId: 5,
                  idhex: '0x13881',
                  name: 'Goerli',
                  currency: 'FAU-goerli',
                  explorerUrl: 'https://etherscan.io',
                  rpcUrl: 'https://goerli.infura.io/v3/621d1fff1f804b878426e46ea8237b18',
                  chains: [
                        {
                              chainId: 5,
                              decimals: 18,
                              type: Types.RequestLogic.CURRENCY.ERC20,
                              ensAddress: "0xBA62BCfcAaFc6622853cca2BE6Ac7d845BC0f2Dc",
                              name: "goerli",
                              network: "goerli",
                        }
                  ]
            }
      ]
}

export const mainnet = {
      baseURL: 'https://xdai.gateway.request.network',
      networks: [
            {
                  chainId: 137,
                  name: 'matic',
                  idhex: '0x89',
                  currency: 'USDT',
                  explorerUrl: 'https://polygonscan.com/',
                  rpcUrl: 'https://polygon-mainnet.infura.io/v3/f27760eff972407dac1f24959d92f247',
                  chains: [
                        {
                              chainId: 137,
                              ensAddress: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
                              name: "usdt",
                              network: "matic",
                              decimals: 6,
                              type: Types.RequestLogic.CURRENCY.ERC20,
                        },
                        {
                              chainId: 137,
                              ensAddress: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
                              name: "usdc",
                              network: "matic",
                              decimals: 6,
                              type: Types.RequestLogic.CURRENCY.ERC20,
                        }
                  ]
            },
            {
                  chainId: 1,
                  name: 'mainnet',
                  idhex: '0x1',
                  currency: 'ETH',
                  explorerUrl: 'https://etherscan.io/',
                  rpcUrl: 'https://polygon-mainnet.infura.io/v3/https://mainnet.infura.io/v3/f27760eff972407dac1f24959d92f247',
                  chains: [
                        {
                              chainId: 1,
                              ensAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
                              name: "usdt",
                              network: "mainnet",
                              decimals: 18,
                              type: Types.RequestLogic.CURRENCY.ERC20,
                        },
                        {
                              chainId: 1,
                              ensAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
                              name: "usdc",
                              network: "mainnet",
                              decimals: 18,
                              type: Types.RequestLogic.CURRENCY.ERC20,
                        }
                  ]
            },
            {
                  chainId: 56,
                  name: 'bsc',
                  idhex: '0x38',
                  currency: 'BNB',
                  explorerUrl: 'https://bscscan.com/',
                  rpcUrl: 'https://bsc-dataseed.binance.org',
                  chains: [
                        {
                              chainId: 56,
                              ensAddress: "0x55d398326f99059fF775485246999027B3197955",
                              name: "usdt",
                              network: "bsc",
                              decimals: 18,
                              type: Types.RequestLogic.CURRENCY.ERC20,
                        },
                        {
                              chainId: 56,
                              ensAddress: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
                              name: "usdc",
                              network: "bsc",
                              decimals: 18,
                              type: Types.RequestLogic.CURRENCY.ERC20,
                        }
                  ]
            },
            {
                  chainId: 10,
                  name: 'optimism',
                  idhex: '0xa',
                  currency: 'ETH',
                  explorerUrl: 'https://optimistic.etherscan.io/',
                  rpcUrl: 'https://mainnet.optimism.io',
                  chains: [
                        {
                              chainId: 10,
                              ensAddress: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
                              name: "usdt",
                              network: "optimism",
                              decimals: 6,
                              type: Types.RequestLogic.CURRENCY.ERC20,
                        },
                        {
                              chainId: 10,
                              ensAddress: "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
                              name: "usdc",
                              network: "optimism",
                              decimals: 6,
                              type: Types.RequestLogic.CURRENCY.ERC20,
                        }
                  ]
            },
            {
                  chainId: 42161,
                  name: 'arbitrum-one',
                  idhex: '0xa4b1',
                  currency: 'ETH',
                  explorerUrl: 'https://arbiscan.io/',
                  rpcUrl: 'https://arb1.arbitrum.io/rpc',
                  chains: [
                        {
                              chainId: 42161,
                              ensAddress: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
                              name: "usdt",
                              network: "arbitrum-one",
                              decimals: 6,
                              type: Types.RequestLogic.CURRENCY.ERC20,
                        },
                        {
                              chainId: 42161,
                              ensAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
                              name: "usdc",
                              network: "arbitrum-one",
                              decimals: 6,
                              type: Types.RequestLogic.CURRENCY.ERC20,
                        }
                  ]
            }
      ]
}

export const mainnetNetworkMapper:any = {
  polygon: 'matic',
  Polygon: 'matic',
  ethereum: 'mainnet',
  Ethereum: 'mainnet',
  bsc: 'bsc',
  optimism:'optimism',
  arbitrum: 'arbitrum-one'
}


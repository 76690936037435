<div class="head">
  <h3 class="color_primary">{{ planDetails.title }}</h3>
  <div class="gws_flex align_right">
    <mat-icon
      *ngIf="!planDetails.hideCopy"
      class="color_primary copy_icon"
      (click)="copyPlan()"
      >content_copy</mat-icon
    >
    <mat-icon class="color_primary" (click)="drawer.toggle()">close</mat-icon>
  </div>
</div>
<mat-card appearance="outlined" class="offer_scroll">
  <mat-list class="offer_list">
    <mat-list-item *ngIf="planDetails?.title">
      <span class="firstchild" matListItemTitle>Plan Name</span>
      <span matListItemLine class="disaply_block">{{
        planDetails?.title
      }}</span>
    </mat-list-item>

    <mat-list-item>
      <span class="firstchild" matListItemTitle>Plan Description</span>
      <span matListItemLine class="max-width disaply_block">{{
        planDetails?.description || "--"
      }}</span>
    </mat-list-item>
    <mat-list-item>
      <span class="firstchild" matListItemTitle>Billing Amount</span>
      <!-- <span matListItemLine class="disaply_block">{{
        planDetails?.billingAmount
          | currency : planDetails?.billingCurrency
          | space
      }}</span> -->
      <span matListItemLine class="disaply_block">{{
        planDetails?.billingAmount
       }} {{planDetails?.billingCurrency}}</span>
    </mat-list-item>

    <mat-list-item>
      <span class="firstchild" matListItemTitle>Billing Frequency</span>
      <span matListItemLine class="disaply_block"
        >{{ planDetails.frequencyCount > 1 ? "Once in" : "Every" }}
        {{ planDetails.frequencyCount }}
        <span class="no-pad">{{
          planDetails.frequencyCount > 1
            ? planDetails.frequencyType.concat("s")
            : planDetails.frequencyType
        }}</span></span
      >
    </mat-list-item>

    <mat-list-item *ngIf="planDetails?.created">
      <span class="firstchild" matListItemTitle>Created At</span>
      <span matListItemLine class="disaply_block">{{
        formatePlanDate(planDetails?.created)
      }}</span>
    </mat-list-item>

    <mat-list-item>
      <span class="firstchild" matListItemTitle>Notes</span>
      <span matListItemLine class="disaply_block">
        <a class="gws_btn_secondary" (click)="toggleNotes()" *ngIf="planDetails?.notes.length > 0">Show/Hide</a>
        <ng-container *ngIf="planDetails?.notes.length === 0">
          <span class="dots">--</span></ng-container
        >
      </span>
    </mat-list-item>
    <mat-list-item class="interNotes" *ngIf="showHideNote">
      <ng-container *ngIf="planDetails?.notes.length > 0">
        <div class="internal_notes" *ngFor="let item of planDetails?.notes">
          <h6>{{ item.title }}</h6>
          <p>{{ item.description }}</p>
        </div>
      </ng-container>
    </mat-list-item>
  </mat-list>

  <div
    class="recent_subscriptions"
    *ngIf="planDetails.subscriptions && planDetails.subscriptions.length > 0"
  >
    <p><b>Recently created Subscriptions</b> to this plan</p>

    <div class="gws_table_container">
      <table mat-table [dataSource]="dataSource">
        <!-- Position Column -->
        <ng-container matColumnDef="_id">
          <th mat-header-cell *matHeaderCellDef>Plan Id</th>
          <td
            mat-cell
            *matCellDef="let element"
            (click)="viewSubscriptionDetails(element._id)"
          >
            <code class="color_primary">{{ element._id }}</code>
          </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef>Create At</th>
          <td mat-cell *matCellDef="let element">
            {{ formatePlanDate(element.created) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <span
              class="gws_chip text-capitalize {{
                element.status === true ? 'created' : element.status
              }}"
            >
              {{ element.status === true ? "created" : element.status }}
            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</mat-card>

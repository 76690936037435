import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import {RouterModule} from '@angular/router';

import {MatSidenavModule} from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatMenuModule} from '@angular/material/menu';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import {HeaderComponent} from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {DefultComponent} from '../layout/defult/defult.component';
import { SaveChangesDialogComponent } from './dialog/save-changes/save-changes-dialog.component';
import { TaxRegistrationDialogComponent } from './dialog/tax-registration-dialog/tax-registration-dialog.component';
import { SnackbarComponent } from './dialog/snackbar/snackbar.component';
import {MatCardModule} from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {NgxSpinnerModule} from 'ngx-spinner';

import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ProcessingDialogComponent} from './dialog/processing-dialog/processing-dialog.component';
import { ResetPasswordComponent } from './header/reset-password/reset-password.component';
import {MatDialogModule, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SpacePipe} from '../core/space.pipe';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ProfileBlockedComponent } from './profile-blocked/profile-blocked.component';
import { MatNativeDateModule } from '@angular/material/core';
import {MatStepperModule} from '@angular/material/stepper';
import { PlanDetailsComponent } from '../modules/subscription/plans/plan-details/plan-details.component';
import { OfferDetailSidebarComponent } from '../modules/offers/offer-detail-sidebar/offer-detail-sidebar.component';
import {MatChipsModule} from '@angular/material/chips';
import { NewWalletComponent } from './new-wallet/new-wallet.component';
import { NetworkWidgetComponent } from './network-widget/network-widget.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { ConfirmModalComponent } from './dialog/confirm-modal/confirm-modal.component';
import { SubscriptionInvoiceCardComponent } from './subscription-invoice-card/subscription-invoice-card.component';
import {MatRadioModule} from '@angular/material/radio';
import { InvoicePaymentComponent } from './payments/payment-summary/payment-summary.component';
import { PaymentActionsComponent } from './payments/payment-actions/payment-actions.component';
// import { Web3ModalModule, Web3ModalService } from '@mindsorg/web3modal-angular';
import { PaymentComponent } from './payments/payment/payment.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    DefultComponent,
    SaveChangesDialogComponent,
    TaxRegistrationDialogComponent,
    SnackbarComponent,
    PlanDetailsComponent,
    ProcessingDialogComponent,
    ResetPasswordComponent,
    SpacePipe,
    VerifyOtpComponent,
    ProfileBlockedComponent,
    OfferDetailSidebarComponent,
    NewWalletComponent,
    NetworkWidgetComponent,
    ConfirmModalComponent,
    SubscriptionInvoiceCardComponent,
    InvoicePaymentComponent,
    PaymentActionsComponent,
    PaymentComponent,
    PaymentMethodComponent,
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    DefultComponent,
    TaxRegistrationDialogComponent,
    MatSnackBarModule,
    MatMenuModule,
    MatCardModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,     
    MatTabsModule,
    MatTableModule,
    MatIconModule,
    NgxSpinnerModule,
    MatStepperModule,
    ProcessingDialogComponent,
    SpacePipe,
    MatButtonToggleModule,
    VerifyOtpComponent,
    MatTooltipModule,
    NgxMaterialTimepickerModule,
    MatSidenavModule,
    MatListModule,
    PlanDetailsComponent,
    OfferDetailSidebarComponent,
    NewWalletComponent,
    MatChipsModule,
    NetworkWidgetComponent,
    MatGridListModule,
    SubscriptionInvoiceCardComponent,
    MatRadioModule,
    InvoicePaymentComponent,
    PaymentActionsComponent,
    PaymentMethodComponent
  ],
  imports: [
    CommonModule,
    RouterModule,

    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatSnackBarModule,
    MatMenuModule,
    MatCardModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,    
    MatTabsModule,
    MatTableModule,
    NgxSpinnerModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatTooltipModule,
    NgxMaterialTimepickerModule,
    MatStepperModule,
    MatChipsModule,
    MatGridListModule,
    MatRadioModule,
  //  Web3ModalModule
  ],
  providers: [
    CurrencyPipe,
    { 
      provide: MatDialogRef,
      useValue: []
       }, 
      { 
      provide: MAT_DIALOG_DATA, 
      useValue: [] 
      },
      
    // {
    //   provide: Web3ModalService,
    //   useFactory: () => {
    //     return new Web3ModalService({
    //       disableInjectedProvider: false,
    //       network: "", // optional
    //       cacheProvider: false, // optional
    //       providerOptions: {} // required
    //     });
    //   },
    // },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class SharedModule { }

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
//import WalletConnectProvider from '@walletconnect/web3-provider';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { POLYGON_INFURA_WEB3_API } from 'src/app/config';
const Web3 = require('web3');
let web3: any;
let provider: any;

@Injectable({
  providedIn: 'root',
})
export class WalletConnectService {
  public connector: any;
  public wallectObj: any = {};
  public _wallectObj$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public wallectObj$: Observable<any> = this._wallectObj$.asObservable();

  constructor(public snackBar: MatSnackBar,) {}

  checkIsWalletConnected = () => {
    const walletconnect = localStorage.getItem('walletconnect');
    return walletconnect ? JSON.parse(walletconnect) : null;
  };

  init = () => {
    this.initWeb3Provider();
  };

  emitWalletStatus = () => {
    this.wallectObj = this.checkIsWalletConnected();
    this._wallectObj$.next(this.wallectObj);
  };

  disconnect = async (status?:any) => {
    await this.initWeb3Provider();
    await provider.disconnect();
    this.clearConnectionFields();
  };

  clearConnectionFields = () => {
    localStorage.removeItem('walletconnect');
    this.wallectObj = null;
    this.emitWalletStatus();
  }

  initWeb3Provider = async () => {
    // //  Create WalletConnect Provider
    // provider = new WalletConnectProvider({
    //   infuraId: environment.infuraID,
    // });

    // //  Enable session (triggers QR Code modal)
    // await provider.enable();
    // provider.on("accountsChanged", (accounts: string[]) => {
    //   console.log(accounts);
    // });
    // web3 = new Web3(provider);
    // setTimeout(() => {
    //   this.emitWalletStatus();
    // }, 100);
    // return web3;
  };

  getWeb3Instance = () => {
    return web3 ? web3 : this.initWeb3Provider();
  };

  switchChainID = async (selectChain:any) => {
    if(!web3) return; 
    let id = await web3.eth.net.getId();
    if (id !== selectChain.ID) {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: selectChain.IDHEX }],
      }).then((res:any) => {
        console.log('switchChainID', res)
      }).catch(async (error:any) => {
        if(this.wallectObj.peerMeta.name === 'WalletConnect Safe App' && error.message === '"wallet_switchEthereumChain" not implemented') {
          this.showError('Your safe is connected to the wrong chain.');
        }
      });
    }
  }

  showError = (error:any) => {
    this.disconnect();
    this.snackBar.open(error, 'CLOSE', {
      panelClass: ['snack-error'],
      duration: 2000,
    });
  }

  callProvideTx = async (payload: any, currenctChain:any): Promise<any> => {
    await this.switchChainID(currenctChain);
    return new Promise(async (resolve, reject) => {
      console.log('callProvideTx', payload);
      await web3.eth
        .sendTransaction(payload)
        .on('transactionHash', async (hash: any) => {
          console.log('sendTransaction', hash);
          resolve({ ...payload, hash });
          this.clearConnectionFields();
        })
        .on('error', (error: any, receipt: any) => {
          reject(error);
        });
    });
  };

  callProvideSignTx = async () => {
    if (web3) {
      let nonce = await web3.eth.getTransactionCount(
        this.wallectObj.accounts[0]
      );
      const tx = {
        from: this.wallectObj.accounts[0], // Required
        to: '0xaBb4E72A1253E4Fd66F235243Fd51aF21Ecfbf39', // Required (for non contract deployments)
        data: '0x0001', // Required
        gasPrice: '0x02540be400', // Optional
        gas: '0x9c40', // Optional
        value: '0x000A', // Optional
        nonce: nonce, // Optional
      };
      const txHash = await web3.eth.signTransaction(tx);
      console.log('signTransaction', txHash);
    } else {
      this.initWeb3Provider();
      setTimeout(() => {
        this.callProvideSignTx();
      }, 100);
    }
  };

  callProvideSignMsg = async () => {
    if (web3) {
      const signedMessage = await web3.eth.sign(
        web3.utils.sha3('This is a test message'),
        this.wallectObj.accounts[0]
      );
      console.log('signedMessage', signedMessage);
    } else {
      this.initWeb3Provider();
      setTimeout(() => {
        this.callProvideSignMsg();
      }, 100);
    }
  };

  getNetworkInfuraWeb3Provider = async () => {
    console.log(POLYGON_INFURA_WEB3_API)
    return new Web3(`${POLYGON_INFURA_WEB3_API}${environment.infuraID}`);
  }

  getNonceByInfura = async (address:any) => {
    const ws = await this.getNetworkInfuraWeb3Provider();
    return await ws.eth.getTransactionCount(address)
  }
}

import {HttpClient, HttpHeaders, HttpParams, HttpParamsOptions} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {environment} from 'src/environments/environment';
// const detectEthereumProvider = require('@metamask/detect-provider'); // it will show warning commonjs



@Injectable({
  providedIn: 'root'
})

export class WalletService {  



  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar
  ){}

  // public getProvider() { 
  //   let ethereum: any;
    
  //   return from(detectEthereumProvider({ mustBeMetaMask: true })).pipe(

  //     // Step 1: Request (limited) access to users ethereum account
  //     switchMap(async (provider:any) => {
        
  //       const walletProvider = provider.providers ? provider.providers.find((p:any) => p.isMetaMask) : provider;
        
  //       if (!walletProvider) {
  //         this.snackBar.open('Please Install Metamask extension', 'CLOSE', { panelClass: ["snack-error"] });
  //         throw new Error('Please install MetaMask');
  //       }

  //       return  walletProvider;
  //     }),
  //   )};


    postInvoicePayament(invoiceId:any, paymentDetails: any) :Observable<any> {
      const url = environment.url + 'service/' + invoiceId +'/payment';
      return this.http.post(url, { paymentDetails });
    }

    getInvoicePayment(invoiceId:any) :Observable<any> {
      const url = environment.url + 'service/rn/' + invoiceId +'/payment';
      return this.http.get(url);
    }

    getNetworkInvoicePayment(invoiceId:any) :Observable<any> {
      const url = environment.url + 'service/' + invoiceId +'/paid';
      return this.http.get(url);
    }




}
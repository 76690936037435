<div class="head">
  <h3 class="color_primary" *ngIf="!walletFromObj?.isInlineForm">{{data?.heading}}</h3>
  <mat-icon class="color_primary" *ngIf="!walletFromObj?.isInlineForm" (click)="dialogRef.close()">close</mat-icon>
</div>

<ng-container *ngIf="show === 'EMAILOTP'">
  <p>We need to verify your identity to update your mobile number. An OTP has been sent to your registered email
    {{userData?.email}}.</p>

  <form [formGroup]="otpForm" novalidate>
    <div class="gws_form_control_otp">
      <input type="text" class="gws_otp" formControlName="otp1" maxlength="1" #txt1
        (keyup)="move($event, '', txt1, txt2)" />

      <input type="text" class="gws_otp" formControlName="otp2" maxlength="1" #txt2
        (keyup)="move($event, txt1, txt2, txt3)" />

      <input type="text" class="gws_otp" formControlName="otp3" maxlength="1" #txt3
        (keyup)="move($event, txt2, txt3, txt4)" />

      <input type="text" class="gws_otp" formControlName="otp4" maxlength="1" #txt4
        (keyup)="move($event, txt3, txt4, txt5)" />

      <input type="text" class="gws_otp" formControlName="otp5" maxlength="1" #txt5
        (keyup)="move($event, txt4, txt5, txt6)" />

      <input type="text" class="gws_otp" formControlName="otp6" maxlength="1" #txt6
        (keyup)="move($event, txt5, txt6, '')" />

    </div>
  </form>
  <p>Resend OTP in {{count}}S. <span class="gws_btn_secondary" *ngIf="sentOTP && count == 0"
      (click)="sendEmailOTP()">Resend
      OTP</span></p>

  <div class="gws_flex">
    <button class="gws_btn_primary" (click)="this.otpForm.valid && verifyEmailOTP()">Confirm</button>
  </div>
</ng-container>
<ng-container *ngIf="show === 'WALLETOFORM'">
  <form [formGroup]="walletForm" (ngSubmit)="walletForm.valid && createWallet()" novalidate>
    <div class="gws_form_control">
      <div class="gws_label_optinal">
        <label>Blockchain</label>
        <span class="color_gray">Required</span>
      </div>

      <mat-select *ngIf="walletFromObj?.isInlineForm"  class="walled_network" disableOptionCentering
        formControlName="network" (selectionChange)="selectChange($event.value)" placeholder="Blockchain Selection"
        disableRipple>
        <mat-option class="network_option" value="{{ item.value }}" *ngFor="let item of netWorks"
          >{{ item.label }}
        </mat-option>
      </mat-select>

      <mat-select *ngIf="!walletFromObj?.isInlineForm" class="walled_network" disableOptionCentering
        formControlName="network" (selectionChange)="selectChange($event.value)" placeholder="Blockchain Selection"
        disableRipple>
        <mat-option class="network_option" value="{{ item.value }}" *ngFor="let item of netWorks">{{ item.label }}
        </mat-option>
      </mat-select>
      <div class="gws_input_error_hint" *ngIf="
        walletForm.controls['network'].touched &&
        walletForm.controls['network'].invalid
      ">
        <div class="gws_form_errors">
          <mat-icon aria-hidden="false" aria-label="Example home icon">error_outline</mat-icon>
          <span>Network is required!</span>
        </div>
      </div>
    </div>

    <div class="gws_form_control">
      <div class="gws_label_optinal">
        <label>Your wallet name</label>
        <span class="color_gray">Required</span>
      </div>
      <input placeholder="Enter your wallet name" type="text" formControlName="walletName" [ngClass]="
        walletForm.controls['walletName'].touched &&
        walletForm.controls['walletName'].invalid
          ? 'is-invalid'
          : ''
      " />
      <div class="gws_input_error_hint" *ngIf="
        walletForm.controls['walletName'].touched &&
        walletForm.controls['walletName'].invalid
      ">
        <div class="gws_form_errors" *ngIf="walletForm.controls['walletName'].hasError('required')">
          <mat-icon aria-hidden="false" aria-label="Example home icon">error_outline</mat-icon>
          <span>Enter your wallet name!</span>
        </div>
      </div>
    </div>

    <div class="gws_form_control">
      <div class="gws_label_optinal">
        <label>Your wallet address</label>
        <span class="color_gray">Required</span>
      </div>
      <input placeholder="Enter your wallet address" type="text" formControlName="address" [ngClass]="
        walletForm.controls['address'].touched &&
        walletForm.controls['address'].invalid
          ? 'is-invalid'
          : ''
      " />
      <div class="gws_input_error_hint" *ngIf="
        walletForm.controls['walletName'].touched &&
        walletForm.controls['walletName'].invalid
      ">
        <div class="gws_form_errors" *ngIf="walletForm.controls['walletName'].hasError('required')">
          <mat-icon aria-hidden="false" aria-label="Example home icon">error_outline</mat-icon>
          <span>Enter your walled address!</span>
        </div>
      </div>
    </div>

    <div class="gws_flex align_right">
      <button *ngIf="!walletFromObj?.isInlineForm" class="gws_btn_secondary" (click)="dialogRef.close()">
        Cancel
      </button>
      <button class="gws_btn_primary" (click)="walletForm.markAllAsTouched()">
        {{ walletFromObj?.isInlineForm ? 'Next' : data?.primaryBtn}}
      </button>
    </div>
  </form>
</ng-container>
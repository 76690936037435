<div class="gws_title_flex">
      <h2>CSV Upload</h2>

  </div>

  <div class="gws_table_container">
      <div class="gws_form_control">
            <div class="gws_label_optinal">
                <label>CSV Upload 1</label>
            </div>

            <div class="gws_drop_drop_container">
                <input type="file" (change)="docChange($event, 'csvOne')" accept=".csv">
                <div class="content-box">
                    <p class="color_primary"><span>Drop your file here </span>or <span>browse</span></p>
                    <mat-icon aria-hidden="false" aria-label="Example home icon">cloud_upload</mat-icon>
                    <span class="supported"><span>Supported File Types:</span>CSV <span>File Size:</span>
                        Maximum of 5 MB</span>
                </div>
            </div>
            <div class="gws_flex m-20">
                  <button type="button" class="gws_btn_primary" (click)="uploadCSV('csvOne')">
                       Upload Now
                    </button>   
            </div>
        </div>

        <div class="gws_form_control m-40">
            <div class="gws_label_optinal">
                <label>CSV Upload 1</label>
            </div>

            <div class="gws_drop_drop_container">
                <input type="file" (change)="docChange($event, 'csvTwo')" accept="image/jpg, image/png, image/jpeg">
                <div class="content-box">
                    <p class="color_primary"><span>Drop your file here </span>or <span>browse</span></p>
                    <mat-icon aria-hidden="false" aria-label="Example home icon">cloud_upload</mat-icon>
                    <span class="supported"><span>Supported File Types:</span>CSV <span>File Size:</span>
                        Maximum of 5 MB</span>
                </div>
            </div>
            <div class="gws_flex m-20">
                  <button type="button" class="gws_btn_primary" (click)="uploadCSV('csvTwo')">
                       Upload Now
                    </button>   
            </div>
        </div>
  </div>
  
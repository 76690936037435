import { Component } from '@angular/core';
import {ActivatedRoute, RouteConfigLoadEnd, RouteConfigLoadStart, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {AuthService} from './core/services/auth.service';
import {CommonService} from './core/services/common.service';
import { Web3modalService } from './core/services/web3modal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'intrxn';
  constructor(
    private commonService: CommonService,
    private router: Router,
    private web3modalService: Web3modalService,
    ){
      this.router.events.subscribe(event => {
        if (event instanceof RouteConfigLoadStart) {
            this.commonService.showSpinner();           
        } else if (event instanceof RouteConfigLoadEnd) {
          this.commonService.hideSpinner();
        }
      });
      this.web3modalService.initializeWeb3Modal();
    }
  isLoading: Subject<boolean> = this.commonService.isLoading;
}

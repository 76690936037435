<div class="container">

    <div class="dialog_left">
        <h5 class="color_primary">Tax Registration Number</h5>
        <h6>UAE</h6>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. </p>
        <h6>India</h6>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. </p>
    </div>
    <div class="dialog_right">
        <mat-icon class="color_primary" (click)="close()">close</mat-icon>
    </div>
</div>
<!-- registration -->
<div class="header gws_container" *ngIf="!authService.isLoggedIn() && !authService.isEmailverified() && !isPayment">
    <div class="gws_header gws_logo_container">
        <img src="../../../assets/images/intrxn-logo.svg" alt="intrXn" (click)="home()">
        <div class="menu-items registation_menu" *ngIf="isSignUp">
            <a>Already a User?</a>
            <button class="gws_btn_primary" [routerLink]="['/sign-in']">Sign In</button>
        </div>

        <div class="menu-items registation_menu" *ngIf="isSignIn">
            <a>Don’t have an account?</a>
            <button class="gws_btn_primary" [routerLink]="['/sign-up']">Sign Up</button>
        </div>

    </div>
</div>
<!-- dashboard -->
<div class="header gws_container" *ngIf="authService.isLoggedIn() && authService.isEmailverified() && !isPayment">
    <div class="gws_header">
        <div class="gws_logo_container">
            <img src="../../../assets/images/intrxn-logo.svg" alt="intrXn"
                [routerLink]="['/dashboard']">
        </div>
        <div class="menu-items"
            *ngIf="!isWidgetUser || (isWidgetUser && authService.isEmailverified() && authService.isAccSetupCompleted() && authService.isWalletCreated())">
            <!-- <button class="gws_btn_primary" style="margin-right: 10px;" [routerLink]="['/fiat-withdrawals']">
                Payouts
            </button>
            <button class="gws_btn_primary" style="margin-right: 10px;"
                [routerLink]="['/invoices/create-invoice']">
                Create Invoice
            </button> -->

            <!-- <div class="gws_profile" *ngIf="walletObj && walletObj.isConnected">
                <span class="gws_profile_badge"
                    [matMenuTriggerFor]="walletMenu">{{getAddress(walletObj.address)}}</span>

                <mat-menu #walletMenu="matMenu" class="profile_menu wallet_menu">
                    <div class="name_box">
                        <p>{{ walletObj.address }}</p>
                        <mat-icon mat-raised-button matTooltip="Copy to clipboard" [matTooltipPosition]="'above'"
                            class="color_primary copy" (click)="copy(walletObj?.address)">content_copy</mat-icon>
                    </div>
                    <div class="sign_box">
                        <button class="gws_btn_primary" (click)="disconnect()">Disconnect</button>
                    </div>
                </mat-menu>
            </div> -->

            <span
                *ngIf="!isWidgetUser || (isWidgetUser && authService.isEmailverified() && authService.isAccSetupCompleted() && authService.isWalletCreated())"
                class="gws_profile_badge" [matMenuTriggerFor]="profileMenu">
                {{userNameTxt || 'UN'}}
            </span>

            <mat-menu #profileMenu="matMenu" class="profile_menu">
                <div class="name_box">
                    <h4>{{ userData?.name }}</h4>
                    <p *ngIf="userData?.businessDetails?.handle">@{{ userData?.businessDetails?.handle }}</p>
                    <p>{{ userData?.email }}</p>
                </div>
                <div class="sign_box">
                    <p class="btn gws_btn_secondary" *ngIf="providerId" (click)="resetPassword()">Change Password</p>
                    <button class="gws_btn_primary" (click)="logout()">Sign Out</button>
                </div>
            </mat-menu>

        </div>

        <div class="menu-items hamburger" *ngIf="sidenav">
            <button class="gws_btn_secondary" (click)="sideNavToogle()" *ngIf="!sidenav.opened">
                <mat-icon aria-hidden="true" aria-label="Example home icon">menu</mat-icon>
            </button>
            <button class="gws_btn_secondary" (click)="sideNavToogle()" *ngIf="sidenav.opened">
                <mat-icon aria-hidden="true" aria-label="Example home icon">close</mat-icon>
            </button>
        </div>

    </div>

</div>

<!-- payment -->
<div class="header gws_container" *ngIf="isPayment && !isPaymentV1 && !isPaymentForm">
    <div class="gws_header ">
        <div class="gws_logo_container">
            <img src="../../../assets/images/intrxn-payment-logo.svg" alt="intrXn" (click)="home()">

        </div>
        <div class="gws_profile" *ngIf="walletObj && walletObj.isConnected">
            <span class="gws_profile_badge" [matMenuTriggerFor]="walletMenu">{{getAddress(walletObj.address)}}</span>

            <mat-menu #walletMenu="matMenu" class="profile_menu wallet_menu">
                <div class="name_box">
                    <p>{{ walletObj.address }}</p>
                    <mat-icon mat-raised-button matTooltip="Copy to clipboard" [matTooltipPosition]="'above'"
                        class="color_primary copy" (click)="copy(walletObj?.address)">content_copy</mat-icon>
                </div>
                <div class="sign_box">
                    <button class="gws_btn_primary" (click)="disconnect()">Disconnect</button>
                </div>
            </mat-menu>
        </div>
    </div>

</div>
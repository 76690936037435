import { Component, Inject, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements OnInit {

  otpSent:boolean = false;
  otpForm!: UntypedFormGroup;

  otpResend: boolean = false;
  count:number = 0;
  userData:any;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<VerifyOtpComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private authService: AuthService,
    public snackBar: MatSnackBar,
  ) { 
    this.userData = this.authService.getUserLocal();
  }

  ngOnInit(): void {

    this.otpForm = this.fb.group({
      otp1: [ null , Validators.required],
      otp2: [ null , Validators.required],
      otp3: [ null , Validators.required],
      otp4: [ null , Validators.required],
      otp5: [ null , Validators.required],
      otp6: [ null , Validators.required],
    });

    if(this.data === 'MOBILE') {
      this.sendOTP();
      this.timer();
    }
  }

  move(e:any, p:any, c:any, n:any){
    let length = c.value.length;
    let maxLength = c.getAttribute('maxlength');
    if(length == maxLength) {
      if(n != '') n.focus();
    }
    if(e.key == 'Backspace') {
      if(p != '') p.focus();
    }
  }

  sendOTP() {
    const s = JSON.parse(sessionStorage.getItem('otpSent') || 'null');
    if(s) {this.otpSent = true; return;};
    this.authService.sendMobileOTP().subscribe(res => {
      
      this.otpSent = true;
      sessionStorage.setItem('otpSentkey', 'true')
      this.otpResend = false;
    }, err => {
      this.snackBar.open(err?.error.result.msg, 'OK', { panelClass: ['snack-warn'], });
    })
  }

  verifyOtp() {
    const data = {
      otp: Object.values(this.otpForm.value).join('')
    };
    
    this.authService.verifyMobileOTP(data).subscribe(res => { 
      if(res.result.status){
        this.dialogRef.close(true);
        sessionStorage.removeItem('otpSentkey');
      }
    }, err => {
      this.snackBar.open(err?.error.result.msg, 'OK', { panelClass: ['snack-warn'], });
    });
  }

  timer() {
    this.count = 60;
   let x = setInterval(() => {
     this.count -= 1;
      if(this.count == 0) {
        clearInterval(x);
        this.otpResend = true;
      }
    }, 1000)
  }

  resend() {
    this.otpSent = false;
    sessionStorage.setItem('otpSent', 'false')
    this.sendOTP()
    this.timer();
  }

  hidemob() {
    return this.userData?.mobNo.substring(0,3)+''+
    "*".repeat(this.userData?.mobNo?.length - 6)+''+
    this.userData?.mobNo.substring(this.userData?.mobNo.length - 3)
  }

  close() {
    sessionStorage.removeItem('otpSentkey');
    this.dialogRef.close();
  }

}

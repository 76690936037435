import { getCurrencySymbol } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { AuthService } from 'src/app/core/services/auth.service';
import { discountTypeOption, paymentMethod, redemptionType } from 'src/app/model/subscriptions';
import { OfferConfirmationComponent } from '../offer-confirmation/offer-confirmation.component';

@Component({
  selector: 'app-offer-detail-sidebar',
  templateUrl: './offer-detail-sidebar.component.html',
  styleUrls: ['./offer-detail-sidebar.component.scss'],
})
export class OfferDetailSidebarComponent implements OnInit {
  @Input() drawer: any;
  @Input() offerInfo: any;
  @Output() onSuccessOffer = new EventEmitter<boolean>();
  public currency: any;
  public discountOption: any = discountTypeOption;
  public redemptionOption: any = redemptionType;
  public userData: any;
  constructor(private dialog: MatDialog, private authSerice: AuthService,) {
    this.userData = this.authSerice.getUserLocal();
  }

  ngOnInit(): void {
    // this.currency = getCurrencySymbol(
    //   this.userData?.businessDetails?.currency,
    //   'wide'
    // );
    this.currency = 'USD';
  }

  ngOnChanges() {
    if(Object.keys(this.offerInfo).length > 0) {
      this.offerInfo.redeemName = this.redemptionOption.find((redempt:any) => redempt.value === this.offerInfo.discountRedeemType).name;
      this.offerInfo.discountName = this.discountOption.find((discount:any) => discount.value === this.offerInfo.discountType).name;
    }
  }

  formateOfferDate = (date: any) => {
    if (!date) return null;
    return moment(date, 'X').format('DD/MM/YYYY hh:mm A');
  }

  changeOfferStatus = (offerInfo:any) => {
    this.dialog
      .open(OfferConfirmationComponent, {
        data: {offerInfo: offerInfo},
        autoFocus: false,
        panelClass: ['offer_confirm'],
      })
      .afterClosed()
      .subscribe((res: any) => {
        if(res) {
          this.onSuccessOffer.emit(true);
          this.drawer.toggle();
        }
      });
  }
}

import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CommonService } from 'src/app/core/services/common.service';
import { InvoiceService } from 'src/app/core/services/invoice.service';
import { WalletService } from 'src/app/core/services/wallet.service';
import { currencyJSON } from 'src/app/model/currencyJSON';
import { CurrencyPipe, DOCUMENT, getCurrencySymbol } from '@angular/common';
import { currency, devNetWorks, NetWorks } from 'src/app/model/payment';
import { status } from 'src/app/model/subscriptions';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Web3modalService } from 'src/app/core/services/web3modal.service';
import { APIENDPOINTS } from '../../../config/constants-endpoint';
import { INVOICESTATUS } from '../../../model/invoice';
import { ConfirmModalComponent } from '../../dialog/confirm-modal/confirm-modal.component';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, OnDestroy {
  @Input() invoiceInputData: any;
  public invoiceData: any;
  public planId: any = null;
  public totalAmount: number = 0;
  public offerId: any = null;
  public invoiceStatus = INVOICESTATUS;
  public invoiceCurrency: any = currencyJSON;
  public logoURL: any;
  public status: any = status;
  public netWorks: any = NetWorks;
  public currency: any = currency;
  public totalTaxAmount: any = 0;
  public totalWithoutTax: any = 0;
  public isLoader: boolean = false;
  public subscriptionStatus: any = '';
  public isSubscriptionInvoice: boolean = false;
  public isSubscriptionView: boolean = false;
  public totalDiscount: any = 0;
  public isPayoutEnable: boolean = false;
  public payoutType: any = '';
  public isWalletConnected: boolean = false;
  public isPaymentMethodEnable: boolean = true;
  public appStatus: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PaymentComponent>,
    public dialog: MatDialog,
    public invoiceService: InvoiceService,
    public router: Router,
    public authService: AuthService,
    private currencyPipe: CurrencyPipe,
    private aRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public commonService: CommonService,
    private sanitizer: DomSanitizer,
    private walletService: WalletService,
    private spinner: NgxSpinnerService,
    private web3modalService: Web3modalService,
    public route: ActivatedRoute,
    @Inject(DOCUMENT) private document: any
  ) {
    if (environment.ENV === 'DEV') {
      this.netWorks = devNetWorks;
    }
    this.isSubscriptionView = this.router.url.includes('subscription');
    if (this.router.url.includes('/payment/')) {
      this.document.body.classList.add('paymentPage');
    }
  }

  ngOnInit(): void {
    this.isWalletConnected = false;
    this.web3modalService.requestStatus$.subscribe((res) => {
      this.appStatus = res !== null ? res : null;
      if (res !== null) {
        if (this.appStatus?.loaderOn) {
          this.spinner.show();
        } else {
          this.spinner.hide();
        }

        if (res.status && res.status === 'request paid') {
          const { balance, currencyInfo } = res.requestData;
          const { events } = balance;
          let tx = {
            transactionHash: null,
            nonce: null,
            network: null,
            from: null,
          };
          if (events && events.length > 0) {
            tx = {
              transactionHash: events[0].parameters.txHash,
              nonce: events[0].parameters.gasUsed,
              network: currencyInfo.network,
              from: events[0].parameters.from,
            };
          }
          this.updateDB(tx);
        }
      }
    });

    this.invoiceService._setWallectStatus$.subscribe((res) => {
      this.isWalletConnected = res !== null ? res : false;
    });

    if (this.data && this.data.hasOwnProperty('invoiceId')) {
      this.invoiceInputData = { invoiceId: this.data.invoiceId };
    }
    this.invoiceCurrency.map((curreny: any) => {
      curreny.symbol = getCurrencySymbol(curreny.code, 'wide');
    });
    this.aRoute.params.subscribe((res) => {
      if (res && Object.keys(res).length > 0) this.getInvoiceData(res);
    });
    if (
      this.invoiceInputData &&
      Object.keys(this.invoiceInputData).length > 0
    ) {
      this.isPayoutEnable = true;
      this.getInvoiceData({ id: this.invoiceInputData.invoiceId });
    }
  }

  getInvoiceData = (res: any) => {
    this.spinner.show();
    const url = environment.url + `service/rn/${res.id}/payment`;
    this.commonService.commonGet(url).subscribe(
      (res: any) => {
        if (res.result) {
          this.invoiceData = res.result;
          this.prepareInvoiceData();
        } else {
          this.isSubscriptionInvoice = true;
          this.subscriptionStatus = this.status.EXPIRED;
        }
        this.isLoader = true;
      },
      (err) => {
        this.snackBar.open(err.message, 'CLOSE', {
          panelClass: ['snack-error'],
        });
        this.invoiceData = { subscriptionId: { status: this.status.EXPIRED } };
        this.isLoader = true;
        this.spinner.hide();
      }
    );
  };

  prepareInvoiceData = () => {
    this.invoiceData.allowPayment = false;
    if (
      this.invoiceData.paymentMode &&
      Object.keys(this.invoiceData.paymentMode).length > 0
    ) {
      this.invoiceData.paymentMode.networkObj = {};
      const networkObj = this.netWorks.find(
        (chain: any) =>
          chain.value == this.invoiceData.paymentMode.network.toLowerCase()
      );
      if (networkObj && Object.keys(networkObj).length > 0)
        this.invoiceData.paymentMode.networkObj = networkObj;
      if (this.invoiceData.paymentMode.network === 'MUMBAI') {
        this.invoiceData.paymentMode.networkObj = this.netWorks[1];
        this.invoiceData.paymentMode.networkObj.label = 'Polygon(Mumbai)';
      }
    }
    if (
      this.invoiceData.subscriptionId &&
      Object.keys(this.invoiceData.subscriptionId).length > 0
    ) {
      const { status, nextDue, offerId } = this.invoiceData.subscriptionId;
      this.offerId = offerId ? offerId : null;
      this.subscriptionStatus = status.toUpperCase();
      this.isSubscriptionInvoice = true;
      this.totalDiscount = this.invoiceService.calculateDiscount(
        this.invoiceData.items
      );

      if (nextDue) {
        const dueDate = moment(nextDue, 'X');
        this.invoiceData.allowPayment =
          this.commonService.isCurrentDateBeforeDueDate(3, dueDate);
      }
    }
    if (this.invoiceData.hasOwnProperty('invoiceCurrency')) {
      if (!this.invoiceData.invoiceCurrency)
        this.invoiceData.invoiceCurrency = this.invoiceData.billFrom?.currency;
      const currObj = this.invoiceCurrency.find(
        (curreny: any) => curreny.code === this.invoiceData.invoiceCurrency
      );
      this.invoiceData.currencyName = `${currObj?.symbol}- ${currObj?.name}`;
    }
    if (
      this.invoiceData &&
      this.invoiceData.hasOwnProperty('items') &&
      this.invoiceData.items.length > 0
    ) {
      this.invoiceData.items = this.invoiceData.items.map((item: any) => {
        if (this.isSubscriptionInvoice) {
          const { planId } = this.invoiceData.subscriptionId;
          this.planId = planId;
          item.itemDetails = { itemCode: '001', name: planId.title };
        } else {
          item.amount = this.getAmount(item);
        }
        return item;
      });
    }
    this.calculateAmt();
    this.getLogo();
    if (this.isPayoutEnable) {
      setTimeout(() => {
        this.enableTimer();
      }, 100);
    }
    if (!this.invoiceData.isFiat) {
      this.web3modalService.initializeWeb3Modal(this.invoiceData.paymentMode);
    }

    this.spinner.hide();
  };

  getAmount(item: any) {
    return this.invoiceService.calculateAmount(item);
  }

  calculateAmt() {
    this.totalAmount = this.invoiceData?.items.reduce(
      (total: any, current: any) =>
        parseFloat(total) + parseFloat(current.amount),
      0
    );
    this.totalTaxAmount = this.invoiceData?.items.reduce(
      (total: any, current: any) =>
        (
          parseFloat(
            this.invoiceService.calculationTax(
              current.taxType,
              parseFloat(current.quantity) * parseFloat(current.unitPrice),
              current.tax
            )
          ) + parseFloat(total)
        ).toFixed(2),
      0
    );
    this.totalWithoutTax = this.invoiceData?.items.reduce(
      (total: any, current: any) =>
        parseFloat(current.quantity) * parseFloat(current.unitPrice),
      0
    );
    this.totalTaxAmount = !isNaN(this.totalTaxAmount) ? this.totalTaxAmount : 0;
  }
  getLogo() {
    if (!this.invoiceData?.billFrom?.logo?.key) return;
    this.commonService
      .getFileURL(this.invoiceData.billFrom.logo.key)
      .then((url) => {
        this.logoURL = url;
      });
  }

  sanitizeURL(url: any) {
    if (!url) return;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  reacivedInvoiceData(invoice: object) {
    this.invoiceData = invoice;
    if (
      this.invoiceData.paymentMode &&
      Object.keys(this.invoiceData.paymentMode).length > 0
    ) {
      this.invoiceData.paymentMode.networkObj = {};
      const networkObj = this.netWorks.find(
        (chain: any) =>
          chain.value == this.invoiceData.paymentMode.network.toLowerCase()
      );
      if (networkObj && Object.keys(networkObj).length > 0)
        this.invoiceData.paymentMode.networkObj = networkObj;
    }
    if (this.isPayoutEnable) {
      this.payoutActionHandler(this.invoiceData);
    }
  }

  close = (status: boolean) => {
    this.dialogRef.close(status);
    this.commonService.clearTimeout();
    if (status) this.router.navigate(['/fiat-withdrawals']);
  };

  payoutActionHandler = (data?: any) => {
    setTimeout(() => {
      this.close(true);
    }, 700);
  };

  enableTimer = () => {
    let quotationTime = moment(this.data.expriyTime);
    let now = moment();
    this.startTimer(now.diff(quotationTime, 'seconds'));
  };

  disablePaymentOption = () => {
    this.isPaymentMethodEnable = false;
  };

  startTimer(diff: any) {
    var fiveMinutes = 60 * 15 - diff;
    this.commonService.callbackTimer(
      fiveMinutes,
      this.disablePaymentOption.bind(this),
      'ptTimer'
    );
  }

  backToWalletConnect = () => {
    this.invoiceService.updateWalletStatus('DISCONNECTED');
  };

  getProviderConfig = () => {
    const { token } = this.web3modalService.getNetworkConfig(
      this.invoiceData.paymentMode
    );
    const network = { ...token };
    const provider = this.web3modalService.getProvider(network);
    const { requestNetwork } = this.invoiceData;
    return { provider, requestNetwork };
  };

  handleApproveRequest = () => {
    const { provider, requestNetwork } = this.getProviderConfig();
    this.web3modalService.approve(
      provider,
      this.invoiceData,
      this.web3Callback.bind(this)
    );
  };

  web3WalletHandler(status?: any) {
    const isWalletConnected = this.web3modalService.getWalletStatus();
    const { token, network } = this.web3modalService.getNetworkConfig(
      this.invoiceData.paymentMode
    );
    const connectedChain = this.web3modalService.getConnectChain();
    if (isWalletConnected && token.chainId === connectedChain) {
      const { provider, requestNetwork } = this.getProviderConfig();
      if (status || this.appStatus?.status === 'approved') {
        this.commonService.showSpinner();
        this.web3modalService.payRequest(provider, this.invoiceData);
      }
      if (this.appStatus?.status !== 'approved') {
        this.commonService.showSpinner();
        this.handleApproveRequest();
      }
    } else if (isWalletConnected && token.chainId !== connectedChain) {
      this.web3modalService.switchNetwork(
        network,
        this.web3WalletHandler.bind(this)
      );
    } else {
      this.web3modalService.openWeb3Modal(this.web3WalletHandler.bind(this));
    }
  }

  web3Callback = (status: any) => {
    if (status) {
      this.web3WalletHandler(status);
    }
  };

  updateDB(tx: any) {
    this.commonService.showSpinner();
    this.walletService
      .postInvoicePayament(this.invoiceData.refID, tx)
      .subscribe(
        (res) => {
          this.invoiceData = res.result;
          this.commonService.hideSpinner();
          if (
            this.invoiceData &&
            this.invoiceData.invoiceStatus !== this.invoiceStatus.PAID
          ) {
            this.snackBar.open(
              'Payment is being processed. You will recieve a notification shortly.',
              'CLOSE',
              { panelClass: ['snack-sucess'], duration: 5000 }
            );
          } else if (
            this.invoiceData &&
            this.invoiceData.invoiceStatus === this.invoiceStatus.PAID
          ) {
            this.snackBar.open(
              'Payment sucessful. You will recieve a notification shortly',
              'CLOSE',
              { panelClass: ['snack-sucess'], duration: 5000 }
            );
          }
          if (this.router.url.includes('/transfer-to-bank-account')) {
            this.dialog.closeAll();
            this.commonService.clearTimeout();
            this.router.navigate(['/fiat-withdrawals']);
          } else {
            this.router.navigate(['/invoices/list/invoices-sent']);
          }
        },
        (err) => {
          this.commonService.hideSpinner();
          this.snackBar.open('Transaction failed!', 'CLOSE', {
            panelClass: ['snack-error'],
            duration: 5000,
          });
          this.router.navigate(['/invoices/list/invoices-sent']);
        }
      );
  }

  ngOnDestroy() {
    if (this.router.url.includes('/payment/')) {
      this.document.body.classList.remove('paymentPage');
    }
    if (this.router.url.includes('/transfer-to-bank-account')) {
      this.document.body.classList.add('payoutPage');
    }
  }

  getAmountWithCurrency = (element: any, amount: any) => {
    if (!element) return null;
    if (element && element.isFiat) {
      return this.currencyPipe.transform(amount, element?.currency) || '';
    } else {
      return `${amount} ${element?.paymentMode?.currency[0] ?? ''}`;
    }
  };

  markAsPaid = () => {
    if (!this.authService.isLoggedIn()) {
      const data = {
        heading: 'Sign In Required',
        subheading:
          'To proceed with confirming your payment, please sign in to your account. Once signed in, you can mark the invoice as paid.',
        cancelBtn: 'Cancel',
        okBtnLink: '/sign-in',
        okBtn: 'Sign in & Continue',
      };

      this.dialog
        .open(ConfirmModalComponent, {
          data: data,
          autoFocus: false,
          panelClass: ['warning_model'],
        })
        .afterClosed()
        .subscribe((res: any) => {});
    } else {
      this.handleMarkAsPaid();
    }
  };

  handleMarkAsPaid = () => {
    this.spinner.show();
    const url = `${environment.domain}${APIENDPOINTS.INVOICE.MARK_PAID}/${this.invoiceData.refID}`;
    this.commonService.commonGet(url).subscribe(
      (res: any) => {
        if (
          res &&
          res.result &&
          Object.keys(res.result).length > 0 &&
          !res.result.hasOwnProperty('errors')
        ) {
          const { invoiceStatus } = res.result;
          this.getInvoiceData({ id: this.invoiceData.refID });
          if (invoiceStatus === this.invoiceStatus.SENT) {
            this.snackBar.open(
              'Thank you! Your payment is being processed and is pending payees verification.',
              'CLOSE',
              { panelClass: ['snack-sucess'], duration: 5000 }
            );
          }
        } else {
          this.snackBar.open('Request failed!', 'CLOSE', {
            panelClass: ['snack-error'],
            duration: 5000,
          });
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.snackBar.open('Request failed!', 'CLOSE', {
          panelClass: ['snack-error'],
          duration: 5000,
        });
      }
    );
  };
}

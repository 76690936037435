<div class="container">

    <div class="dialog_left">
        <h5 class="color_primary">Save Changes?</h5>
        <p>Are you sure you want to leave this page? You have unsaved data in the page.</p>
    </div>
    <div class="dialog_right">
        <mat-icon class="color_primary" (click)="close()">close</mat-icon>
    </div>
</div>

<div class="gws_flex">
    <button class="gws_btn_secondary" (click)="ignore()">Ignore Changes</button>
    <button class="gws_btn_primary" (click)="save()">Save Changes</button>
</div>
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { currency, devCurrency, devNetWorks, NetWorks } from 'src/app/model/payment';
import { environment } from 'src/environments/environment';
import { NewWalletComponent } from '../new-wallet/new-wallet.component';

@Component({
  selector: 'app-network-widget',
  templateUrl: './network-widget.component.html',
  styleUrls: ['./network-widget.component.scss']
})
export class NetworkWidgetComponent implements OnInit, OnChanges {
  settings: any;
  @Input() set networkSettings(value: any) {
    this.settings = value;
  }
  public selectedNetworkList:any = '';
  public selectedCurrencyList:any = [];
  public netWorks:any = NetWorks;
  public currencyList:any = currency
  public walletAddress:any = [];
  public userData: any;
  public networkObj:any = {
    network: '',
    currency: [],
    address: ''
  }
  public isTestNetworkEnable:boolean = false;
  @ViewChild('matRef')
  matRef!: MatSelect;
  @Output() onSuccessData = new EventEmitter<{}>();
  constructor(
    private authSerice: AuthService,
    private dialog: MatDialog, 
  ) { 
  
    this.userData = this.authSerice.getUserLocal();
  }

  ngOnInit(): void {
    if(this.userData && this.userData?.hasOwnProperty('businessDetails') && this.userData?.businessDetails.hasOwnProperty('walletAddresses') && this.userData?.businessDetails.walletAddresses.length > 0) {
      this.walletAddress = this.userData?.businessDetails.walletAddresses;
    } else {
      this.walletAddress = [{network: ["Polygon"], walletName: `${this.userData?.name} Wallet`, address:this.userData?.businessDetails.walletAddress}];
    }
    this.currencyList.map((item:any) => {
      item.isSupportPolygon = true;
      if(item.value == 'MARSH')  item.isSupportPolygon = false;
    });
  }
  ngOnChanges() {
    if(this.settings && this.settings.isDevNetwork) {
      this.isTestNetworkEnable = true;
      this.netWorks = devNetWorks;
      this.currencyList = devCurrency;
    }
    if(this.settings && this.settings.hasOwnProperty('data') && Object.keys(this.settings.data).length > 0) {
      this.networkObj = {...this.settings.data};
      this.selectedNetworkList = this.networkObj.network;
      this.selectedCurrencyList = this.networkObj.currency;
    }
  }

  onSelect = (value:any, array:any, type:any) => {
    switch(type) {
      case 'Network' :
        if(this.settings.multipleSelect) {
          this.pushPullData(array, value);
        } else {
          this.selectedNetworkList = value;
        }
        this.setData();
      break;
      case 'Currency' :
        //this.pushPullData(array, value);
        this.selectedCurrencyList = [];
        this.selectedCurrencyList.push(value);
        this.setData();
      break;
    }
  }

  setData = () => {
    if(this.selectedNetworkList === 'polygon' && this.selectedCurrencyList.indexOf('MARSH') > -1) {
      this.selectedCurrencyList.splice(this.selectedCurrencyList.indexOf('MARSH'), 1);
    }
    this.networkObj = {...this.networkObj, network: this.selectedNetworkList, currency: this.selectedCurrencyList};
    this.onSuccessData.emit({...this.networkObj});
  }

  pushPullData = (array:any, value:any) => {
    if(array.indexOf(value) === -1) {
      array.push(value);
    } else {
      array.splice(array.indexOf(value), 1);
    }
  }

  getCurrencyList() {
    switch(this.selectedNetworkList) {
      case 'Ethereum' :
      return this.currencyList;
      break;
      case 'BSC' :
      return this.currencyList.filter((curr:any) => curr.value !== 'ETH');
      break;
      default :
      return this.currencyList.filter((curr:any) => curr.isSupportPolygon && curr.value !== 'ETH');
      break;
    }
  }

  onSelectAddress = (event:any) => {
    if(event.value == 'new_address') {
      this.createNewWallerAddress();
      this.networkObj.address = '';
      this.matRef.options.forEach((data: MatOption) => data.deselect());
      this.onSuccessData.emit({...this.networkObj, address: ''});
    }
    this.onSuccessData.emit({...this.networkObj});
  }

  createNewWallerAddress = () => {
    this.openCreateWalletModel();
  }

  openCreateWalletModel = () => {
    this.dialog
    .open(NewWalletComponent, {
      data: {
        heading: 'Create New Wallet',
        primaryBtn: 'Create',
        isOTPRequired: false,
        isDevNetwork:this.settings.isDevNetwork
       },
      autoFocus: false,
      panelClass: ['create_wallet'],
    })
    .afterClosed()
    .subscribe((res) => {
      this.networkObj.address = '';
      this.getUserWallet();
      this.matRef.options.forEach((data: MatOption) => data.deselect());
    });
  }

  getUserWallet = () => {
    this.authSerice.getUserDetails().subscribe(res => {
      this.authSerice.setUserLocal(res.result);
      this.userData = res.result;
      if(res.result && res.result.hasOwnProperty('businessDetails') && res.result.businessDetails.hasOwnProperty('walletAddresses') && res.result.businessDetails.walletAddresses.length > 0) {
        this.walletAddress = res.result.businessDetails.walletAddresses;
      } else {
        this.walletAddress = [{network: ["Polygon"], walletName: `${this.userData?.name} Wallet`, address:this.userData?.businessDetails.walletAddress}];
      }
    });
  }

  filterWalletAddress = () => {
    const array:any = [];
    if(this.walletAddress.length > 0) {
      this.walletAddress.forEach((element:any) => {
          if(element.network.includes(this.selectedNetworkList)) {
            array.push(element);
          }
      });
    }
    return array;
  }

}

<div class="container">
  <div class="dialog_left">
    <h5 class="color_primary">{{data['offerInfo']['status'] ? 'Disable' : 'Enable'}} Offer</h5>
    <b *ngIf="data['offerInfo']['status'] && data['offerInfo']['statistics']['activeOn']">This Offer is active on {{data['offerInfo']['statistics']['activeOn']}} subscriptions!</b>
    <p *ngIf="data['offerInfo']['status']">
      Disabling this offer will not remove it from these subscriptions and it has to be removed manually to stop the discounts.
    </p>
    <p>Are you sure you want to {{data['offerInfo']['status'] ? 'disable' : 'enable'}} this offer?</p>
  </div>
  <div class="dialog_right">
    <mat-icon class="color_primary" (click)="close()">close</mat-icon>
  </div>
</div>

<div class="gws_flex">
  <button class="gws_btn_secondary" (click)="close()">No, don't!</button>
  <button class="gws_btn_primary" (click)="changeStatus()">Yes, {{data['offerInfo']['status'] ? 'Disable' : 'Enable'}}</button>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ENDPOINTS } from 'src/app/config';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root',
})
export class OnmetaService {
  constructor(private commonService: CommonService) {}

  createUserSession =  (email: any) => {
    const payoutData = this.commonService.getLocalStorage('payout');
    if(!payoutData) {
      return new Promise((resolve, reject) => {
        const url = `${environment.payouts.onmeta}${ENDPOINTS.ONMETA.LOGIN}`;
        this.commonService.commonPost(
          url,
          {
            email: email,
          },
          {
            isNoAuth: true,
            config: { 'x-api-key': environment.payouts.onmetaAPIKey },
          }
        ).subscribe(
          (res: any) => {
            if (res && res['success']) {
              const { data } = res;
              this.commonService.setLocalStorage('payout', data);
              resolve(true);
            }
          },
          (err) => {
            reject(false);
          }
        );
      });
    } else {
      return this.getRefreshToken();
    }
  };

  
  getRefreshToken =  () => {
    return new Promise((resolve, reject) => {
    this.commonService.commonGet(
      `${environment.payouts.onmeta}${ENDPOINTS.ONMETA.GET_REFRESH_TOKEN}`,
      true,
      this.getHeaderConfig()
    ).subscribe(
       (res: any) => {
        if (res && res['success']) {
          this.handleResponse(res);
          resolve(true);
        }
      },
       (err) => {
        localStorage.removeItem('payout');
        const { email } = this.commonService.getLocalStorage('ud');
        this.createUserSession(email).then((res) => {
          resolve(true);
        }).catch((err) => {
          reject(false)
        });
      }
    );
    });
  };


  checkBankStatus =  (refNumber: any) => {
    return new Observable((observer) => {
    this.commonService.commonGet(
      `${environment.payouts.onmeta}${ENDPOINTS.ONMETA.BANK_STATUS}${refNumber}`,
      true,
      this.getHeaderConfig()
    ).subscribe(
      (res: any) => {
        if (res && res['success']) {
          this.handleResponse(res);
          const {data} = res;
          observer.next(res);
        } else {
          observer.next(false);
        }
      },
      (err) => {
        observer.error(false);
      }
    );
    });
  };

  handleResponse =  (res: any) => {
    const { data } = res;
    const payoutData = this.commonService.getLocalStorage('payout');
    const newSession = { ...payoutData, ...data };
    this.commonService.setLocalStorage('payout', newSession);
  };

  getQuotation =  (request:any):Promise<any> => {
    return this.commonService.commonPost(
      `${environment.payouts.onmeta}${ENDPOINTS.ONMETA.GET_QUOTATION}`,
      request,
      this.getHeaderConfig()
    ).toPromise();
  };

  updateTxHash = (request:any) => {
    return this.commonService.commonPost(
      `${environment.payouts.onmeta}${ENDPOINTS.ONMETA.UPDATETXHASH}`,
      request,
      this.getHeaderConfig()
    ).toPromise();
  }

  checkKYCStatus = (request:any) => {
    return this.commonService.commonPost(
      `${environment.payouts.onmeta}${ENDPOINTS.ONMETA.CHECK_KYC_STATUS}`,
      request,
      this.getHeaderConfig()
    ).toPromise();
  }

  getHeaderConfig = () => {
    const payoutData = this.commonService.getLocalStorage('payout');
    return {
      isNoAuth: true,
      config: { 'x-api-key': environment.payouts.onmetaAPIKey, Authorization: `Bearer ${payoutData.refreshToken}` },
    }
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// let url = 'https://sandboxbackend.intrxn.com/app/'; // deploy
let domain = 'https://businessbackend.intrxn.com/'; //
//let domain = 'http://localhost:3001/';
let url = domain+'app/'; //
//let url = 'http://localhost:3001/app/'; //

//
export const environment = {
  production: false,
  isprodEnv:true,
  externalAPIEndpoint: domain,
  widgetUrl:'https://payouts.intrxn.com/',
  infuraID:'f27760eff972407dac1f24959d92f247',
  recaptcha_siteKey: '6LdxG_AeAAAAAKK5N0lC3_YIs3XY1-Sap_Si86Wn',

  firebaseConfig: {
    apiKey: 'AIzaSyDMSgBktPQX02lB1UX1q6DlNmc3Sjv3W5I',
    authDomain: 'intrxn-uatsandboxauth.firebaseapp.com',
    projectId: 'intrxn-uatsandboxauth',
    storageBucket: 'intrxn-uatsandboxauth.appspot.com',
    messagingSenderId: '419745116149',
    appId: '1:419745116149:web:f5b40eba06679e90f33abc',
    measurementId: 'G-R94MWGKEB1',
  },

  domain:domain,
  url: url,
  enableFERequest:false,
  s3Config: {
    access_key_id: 'AKIA6FMJR4WDQ2ML6H7W',
    secret_access_key: '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',
    region: 'me-south-1',
    bucketName: 'intrxn-dev',
  },

  ENV: 'PROD',
  WEB3_DEFUALT: {
    NETWORK: 'POLYGON',
    CHAINID: 137,
    SYSTEM_WALLET_ADDRESS: '0xB7c979684330bf8487B9B7b2181aDe00d748bBc9'
  },
  payouts: {
    onmetaAPIKey: '94dca5a4-21c0-4c2a-99e8-cbe03bb60db6',
    onmeta: 'https://api.onmeta.in/v1/',
    encryptus: 'https://sandbox.encryptus.co/v1/',
    onmetaKYCWidgetUrl:'https://stg.platform.onmeta.in/kyc',
    encryptusCredentials: {
      grant_services: ['FORENSICS'],
      clientID: 'c12efaac-8092-4c19-98c0-9e6893507677',
      clientSecret: '997dbad0-84b1-47ad-9eca-55fcdefd5b10',
      partnerEmail: 'hemant@intrxn.com',
      partnerPassword: 'intrXnTech_@2023',
    },
  },
  externalPayment: 299,
  paymentKey: 'aa782597-6e4c-4de1-a92e-89d713121c7e' // This is pre-prod one
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

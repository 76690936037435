<!-- <mat-icon aria-hidden="false" aria-label="Example home icon">error_outline</mat-icon> -->

<!-- <button class="gws_btn_primary">Sign Up</button>
<button class="gws_btn_disabled">Disabled</button>
<a hre="">ancher</a>

<div class="gws_form_control">
  <div class="gws_label_optinal">
    <label>Email <br>Email</label>
    <span>Show</span>
  </div>
  <input type="text" placeholder="Email" />

  <div class="gws_input_error_hint">
    <div class="gws_form_errors">
      <mat-icon aria-hidden="false" aria-label="Example home icon">error_outline</mat-icon>
      <span>Email is required! zczcas casca scasc asdasda asdasd</span>
    </div>
    <span class="gws_hint">Forgot Password</span>
  </div>
</div> -->

<!-- <span class="gws_label_card">8 Characters</span> -->

<!-- <div class="gws_divider_or">
  <span>Or</span>
</div> -->


<!-- <div class="gws_social_btn google_color">
  <span>
    <img src="../assets/images/google-logo.svg" alt="Google">
  </span>
  <p>Continue with Google</p>
</div> -->



<!-- <div class="gws_form_control gws_mat_select">
  <mat-select disableOptionCentering placeholder="Status" disableRipple>
      <mat-option value="Send Remainder">
          Send Reminder
      </mat-option>
      <span class="gws_mat_options_divider"></span>
      <mat-option value="Send Remainder">
          Send Reminder
      </mat-option>
      <mat-option value="Send Remainder">
          Send Reminder
      </mat-option>
      <mat-option value="Send Remainder">
          Send Reminder
      </mat-option>
      <span class="gws_mat_options_divider"></span>
      <mat-option value="Send Remainder">
          Send Reminder
      </mat-option>
  </mat-select>
</div> -->


<!-- <div class="gws_form_control gws_date_picker">
  <div class="date_input_conatiner">
      <input formControlName="search" type="text" placeholder="End Date" class="date-picker"
          [matDatepicker]="picker" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  </div>
  <mat-datepicker #picker></mat-datepicker>
</div> -->

<!-- <div class="gws_form_control">
  <input formControlName="search" type="text" placeholder="Invoice Number" class="gws_input_v2" />
</div> -->


<!-- <div class="ges_tabs_container">
  <mat-tab-group>
      <mat-tab>
          <ng-template mat-tab-label>
              All <span class="gws_chip">00</span>
          </ng-template>
          Content 1
      </mat-tab>

      <mat-tab>
          <ng-template mat-tab-label>
              Unpaid
          </ng-template>
      </mat-tab>

      <mat-tab>
          <ng-template mat-tab-label>
              Archived
          </ng-template>
      </mat-tab>
  </mat-tab-group>
</div> -->



<router-outlet></router-outlet>


<ngx-spinner *ngIf="isLoading | async" [fullScreen]="true" size="medium" bdColor="rgba(255,255,255,0.5)" color="#0d41e1" type="ball-clip-rotate"></ngx-spinner>
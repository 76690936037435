
<div class="container">
        <h5 class="color_primary">OTP verification</h5>
    
        <mat-icon class="color_primary" (click)="close()">close</mat-icon>
</div>


<div class="gws_form_container">
    <p class="text-left"> Please enter the OTP sent to {{ hidemob() }} </p>
    <form [formGroup]="otpForm" (ngSubmit)="this.otpForm.valid && otpSent && verifyOtp()" novalidate>

        <div class="gws_form_control_otp">
            <input type="text" class="gws_otp" formControlName="otp1" maxlength="1" #txt1
                (keyup)="move($event, '', txt1, txt2)" />

            <input type="text" class="gws_otp" formControlName="otp2" maxlength="1" #txt2
                (keyup)="move($event, txt1, txt2, txt3)" />

            <input type="text" class="gws_otp" formControlName="otp3" maxlength="1" #txt3
                (keyup)="move($event, txt2, txt3, txt4)" />

            <input type="text" class="gws_otp" formControlName="otp4" maxlength="1" #txt4
                (keyup)="move($event, txt3, txt4, txt5)" />

            <input type="text" class="gws_otp" formControlName="otp5" maxlength="1" #txt5
                (keyup)="move($event, txt4, txt5, txt6)" />

            <input type="text" class="gws_otp" formControlName="otp6" maxlength="1" #txt6
                (keyup)="move($event, txt5, txt6, '')" />
        </div>
        <p class="text-left">Resend OTP in <span class="color_primary">{{count}}S</span></p>
        <div class="button_container">
            <button class="gws_btn_primary" [disabled]="!otpResend" (click)="otpResend && resend()">Resend OTP</button>
            <button type="submit" class="gws_btn_primary">Confirm</button>
        </div>
    </form>
</div>
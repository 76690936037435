import { getCurrencySymbol } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { currencyJSON } from '../../model/currencyJSON';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent {
  @Input() networkSettings: any;
  public paymentMethodForm!: UntypedFormGroup;
  @Output() onSuccessData = new EventEmitter<{}>();
  public methodOption: any = 'crypto';
  
  public invoiceCurrencyInput = new FormControl<string | any>('');
  public filteredOptions: Observable<any[]> = new Observable();

  public invoiceCurrency: any = currencyJSON;
  constructor(private fb: UntypedFormBuilder) {
    this.onSuccessData.emit({ isFiat: this.methodOption === 'fiat' });
  }

  displayFn(user: any): string {
    return user && user.name ? user.name : '';
  }

  private _filter(code: string): any[] {
    const filterValue = code.toLowerCase();
    return this.invoiceCurrency.filter((option:any) => option.code.toLowerCase().includes(filterValue));
  }

  ngOnInit(): void {
    this.filteredOptions = this.invoiceCurrencyInput.valueChanges.pipe(
      startWith(''),
      map(value => {
        const code = typeof value === 'string' ? value : value?.code;
        this.paymentMethodForm.patchValue({invoiceCurrency: value.code});
        return code ? this._filter(code as string) : this.invoiceCurrency.slice();
      }),
    );

    this.paymentMethodForm = this.fb.group({
      bankName: [null],
      swiftCode: [null],
      accountNo: [null],
      IFSC: [null],
      Iban: [null],
      invoiceCurrency: [null],
    });
  }

  onSelect = (data: any) => {
    this.onSuccessData.emit({
      ...data,
      isFiat: this.methodOption === 'fiat',
    });
  };

  currencySymbol(code:string) {
    return getCurrencySymbol(code, 'wide');
  }

  onPaymentSelect = (event: any) => {
    this.methodOption = event.value;
    this.onSelect({ isFiat: this.methodOption === 'fiat'});
    if(this.methodOption === 'crypto') {
      this.paymentMethodForm.reset();
    } else {
      this.paymentMethodForm.valueChanges.subscribe((values) => {
        this.onSelect(values);
      });
    }
    
  };
}

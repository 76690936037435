import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { status } from 'src/app/model/subscriptions';

@Component({
  selector: 'app-subscription-invoice-card',
  templateUrl: './subscription-invoice-card.component.html',
  styleUrls: ['./subscription-invoice-card.component.scss']
})
export class SubscriptionInvoiceCardComponent implements OnInit {
  @Input() invoiceData:any;
  public userData:any = {};
  public planId:any = {};
  public offerId:any = {};
  public statusObj:any = {};
  constructor(public commonService: CommonService, private authSerice: AuthService,) {
    this.userData = this.authSerice.getUserLocal();
   }

  ngOnInit(): void {
    if(this.invoiceData && this.invoiceData.hasOwnProperty('subscriptionId') && Object.keys(this.invoiceData.subscriptionId).length > 0) {
      const { planId, offerId } = this.invoiceData.subscriptionId;
      const { items } = this.invoiceData;
      this.planId = planId || null;
      this.offerId = offerId || null;
      this.offerId ? this.offerId['discount'] = items.length > 0 ? items[0].discount : 0 : "";
      const dueDate = this.invoiceData.subscriptionId.nextDue ? `Next due on ${this.commonService.getDate(this.invoiceData.subscriptionId.nextDue)}.` : '';
      switch(this.invoiceData?.subscriptionId?.status.toUpperCase()) {
        case status.ACTIVE: 
         this.statusObj = {
           msg: `Your subscription is active. ${dueDate ? dueDate : ''}`,
           class: status.COMPLETED
         }
        break;
        case status.PAUSED: 
        this.statusObj = {
          msg: `This subscription has been paused, please contact the merchant for more
          details.`,
          class: status.PAUSED
        }
       break
      }
    }
  }

}

import { AfterViewInit, Component, OnInit, Output, ViewChild } from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import {Router} from '@angular/router';
import {CommonService} from 'src/app/core/services/common.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-defult',
  templateUrl: './defult.component.html',
  styleUrls: ['./defult.component.scss']
})
export class DefultComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSidenav, {static:true})
  sidenav!: MatSidenav;
  hideSidenav: boolean = false;
  
  constructor(private observer: BreakpointObserver, private commonService: CommonService, private route: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    // hide sidenav for account setup 
    if(this.commonService.isContainRoute('account-setup') || 
    this.commonService.isContainRoute('business-verification') || 
    this.commonService.isContainRoute('registration-summary')) {
      this.sidenav.mode = 'over';
      this.sidenav.close();
      this.hideSidenav = true;
    } else {
      this.sidenav.mode = 'side';
      this.sidenav.open();
      this.hideSidenav = false;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(()=> {
      this.observer.observe(['(max-width: 900px)']).subscribe((res) => {
        if(res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();

        } else {
          if(!this.hideSidenav){
            this.sidenav.mode = 'side';
            this.sidenav.open();
          }
        }
      });
    }, 0);
  }

}

import { BusinessDetails, User } from './user';

export interface Invoice {
  refID: string;
  invoiceID: string;
  issuedDate: number;
  dueDate: number;
  invoiceType: string;
  billTo: Customer;
  billFrom: BusinessDetails;
  items: [
    {
      itemDetails: LineItems;
      quantity: number;
      unitPrice: number;
    }
  ];
  description: string;
  tax: number;
  invoiceStatus: string;
  transactionDetails: {};
  created: number;
  invoiceCurrency?: string;
  submittedBy: User;

  formStatus?: boolean; // for local form handling
}

export interface Customer {
  name: string;
  email: string
  mobNo: string
  billingAddr: {
    city: string;
    state: string;
    country: string;
    postcode: string;
    addr: string;
  };
  shippingSameAsBilling: boolean;
  shippingAddr: {
    city: string;
    state: string;
    country: string;
    postcode: string;
    addr: string;
  };
  created: number;
}

export interface LineItems {
  name: string;
  itemCode: string;
  unitPrice: number;
  description: string;
  merchant: BusinessDetails;
  created: number;
}


//


export const InvoiceStatus = {
  NEW: 0,
  DRAFT: 1,
  SUBMITTED: 2,
  SENT:3,
  AWAITING_PAYMENT: 3,
  PAID: 4,
  OVERDUE: 5,
  ARCHIVED: 6,
}

export const INVOICESTATUS = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  PAID: 'PAID',
  OVERDUE: 'OVERDUE',
  ARCHIVED: 'ARCHIVED',
  SENT: 'SENT'
}

export const INVOICE_STATUS = [
  //'NEW',
  'DRAFT',
  'SUBMITTED',
  'AWAITING_PAYMENT',
  'PAID',
  'OVERDUE',
  'ARCHIVED',
  'SENT'
];

export const filterAomunt = [
 200, 500, 1000, 5000, 10000
]

export const invoiceType = {
  EXTERNAL: 'EXTERNAL',
  INTERNAL: 'INTERNAL',
  PAYMENT_LINK: 'PAYMENT_LINK'
};
import { getCurrencySymbol } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-plan-details',
  templateUrl: './plan-details.component.html',
  styleUrls: ['./plan-details.component.scss'],
})
export class PlanDetailsComponent implements OnInit {
  @Input() drawer: any;
  @Input() planDetails: any;
  @Output() copyPlanDetails = new EventEmitter<{}>();
  public currency: any;
  public userData: any;
  public dataSource: any = [];
  public displayedColumns: string[] = ['_id', 'created', 'status'];
  public showHideNote: boolean = false;
  constructor(private authSerice: AuthService, public route: Router) {
    this.userData = this.authSerice.getUserLocal();
  }

  ngOnInit(): void {}

  ngOnChanges() {
    if (Object.keys(this.planDetails).length > 0) {
      this.currency = getCurrencySymbol(
        this.planDetails?.billingCurrency,
        'wide'
      );
      this.dataSource =
        this.planDetails.subscriptions &&
        this.planDetails.subscriptions.length > 0
          ? this.planDetails.subscriptions
          : [];
    }
  }

  formatePlanDate = (date: any) => {
    if (!date) return null;
    return moment(date, 'X').format('DD/MM/YYYY hh:mm A');
  };

  viewSubscriptionDetails = (_id: any) => {
    this.route.navigate(['/subscriptions'], {
      queryParams: {
        subscriptionId: _id,
      },
      queryParamsHandling: 'merge',
    });
  };

  toggleNotes = () => {
    this.showHideNote = !this.showHideNote;
  };

  copyPlan = () => {
    this.copyPlanDetails.emit(this.planDetails);
    this.drawer.toggle();
  };
}

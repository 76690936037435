
// const contractABI = [{"inputs":[{"internalType":"address","name":"_proxyTo","type":"address"}],"stateMutability":"nonpayable","type":"constructor"},{"anonymous":false,"inputs":[{"indexed":false,"internalType":"address","name":"_new","type":"address"},{"indexed":false,"internalType":"address","name":"_old","type":"address"}],"name":"ProxyOwnerUpdate","type":"event"},{"anonymous":false,"inputs":[{"indexed":true,"internalType":"address","name":"_new","type":"address"},{"indexed":true,"internalType":"address","name":"_old","type":"address"}],"name":"ProxyUpdated","type":"event"},{"stateMutability":"payable","type":"fallback"},{"inputs":[],"name":"implementation","outputs":[{"internalType":"address","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"proxyOwner","outputs":[{"internalType":"address","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"proxyType","outputs":[{"internalType":"uint256","name":"proxyTypeId","type":"uint256"}],"stateMutability":"pure","type":"function"},{"inputs":[{"internalType":"address","name":"newOwner","type":"address"}],"name":"transferProxyOwnership","outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[{"internalType":"address","name":"_newProxyTo","type":"address"},{"internalType":"bytes","name":"data","type":"bytes"}],"name":"updateAndCall","outputs":[],"stateMutability":"payable","type":"function"},{"inputs":[{"internalType":"address","name":"_newProxyTo","type":"address"}],"name":"updateImplementation","outputs":[],"stateMutability":"nonpayable","type":"function"},{"stateMutability":"payable","type":"receive"}];
const contractABI = [
  {
    constant: false,
    inputs: [
      {
        name: '_to',
        type: 'address',
      },
      {
        name: '_value',
        type: 'uint256',
      },
    ],
    name: 'transfer',
    outputs: [
      {
        name: '',
        type: 'bool',
      },
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  // balanceOf
  {
    constant: true,
    inputs: [{ name: '_owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: 'balance', type: 'uint256' }],
    type: 'function',
  },
  // decimals
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', type: 'uint8' }],
    type: 'function',
  },
];
  
export const NETWORKS_PROD = {
  
    POLYGON: {
  
      CONTRACTADDRESSES: {
        USDT: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        USDC: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        BUSD: '0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39',
        DAI: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      },
  
      CONTACTABI: contractABI,
  
      DECIMALS: {
        USDT : 6,
        USDC : 6,
        BUSD: 18, 
      },
  
      TXN_FEES: {
        USDT: 1,
        USDC: 1,
      },
  
      API: {
        Tx: 'https://polygonscan.com/tx/',
        Address: 'https://polygonscan.com/address/'
      }
    }
  
  }

  export const CHAINS_PROD = [
    {
      ID: 137,
      IDHEX: '0x89',
      SYMBOL: 'POLYGON',
      NAME: 'Polygon Mainnet',
      SECRET_TYPE: 'MATIC',
    },  
  ];
export const POLYGON_INFURA_WEB3_API_PROD = 'https://polygon-mainnet.infura.io/v3/'
export const POLYGON_GAS_STATION_PROD = 'https://gasstation.polygon.technology/v2';

import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit, AfterContentInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { Web3modalService } from 'src/app/core/services/web3modal.service';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isSignIn:boolean = false;
  isSignUp:boolean = false;
  isDashboard:boolean = false;
  isPayment:boolean = false;
  isPaymentV1:boolean = false;
  isPaymentForm:boolean = false;
  isInvoice:boolean = false;

  @Input() sidenav: any;

  userNameTxt:string = '';
  providerId: boolean = false;
  public moduleType:any = '';
  userData:any;
  public isWidgetUser:boolean = false;
  public walletObj:any = null;
  constructor(
    private route: Router, 
    public authService: AuthService,
    private web3modalService: Web3modalService,
    private activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    private clipboard: Clipboard,
    public snackBar: MatSnackBar,
    public matDialog: MatDialog) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.moduleType = params['type'];
      });
   }

  ngOnInit(): void {
    this.userData = this.authService.getUserLocal();
    if(this.userData){
      this.isWidgetUser = this.userData.hasOwnProperty('type') && this.userData.type === 'WIDGET';
      let name = this.userData.name.split(" ");
      this.userNameTxt = name[0].charAt(0).toUpperCase()+name[1].charAt(0).toUpperCase();
    }

    this.route.events.subscribe(rout => {
      this.conditionBasedRout();
    });
    this.conditionBasedRout();

    this.authService.authState().subscribe(res => {
      this.providerId = res?.providerData?.some((p:any) => p.providerId === 'password');
    });

    this.web3modalService.wallectConnectStatus$.subscribe((res) => {
      this.walletObj = res !== null ? res : null;
    });
  }

  getAddress = (str: any) => {
    return this.commonService.getAccounts(str);
  };

  copy(addr: string) {
    this.clipboard.copy(addr);
    this.snackBar.open('Address Copied!', 'Close', { duration: 1000 });
  }

  disconnect = () => {
    this.web3modalService.disconnect();
    this.commonService.setLocalStorage('isConnected', false);
  }

  conditionBasedRout() {
    /** sign up */
    // this.isSignUp = this.route.url === '/sign-up' ||  this.route.url === '/verify' || this.route.url === '/redirect' ? true : false;
    this.isSignUp = this.route.url.indexOf('/sign-up') > -1 
    || this.route.url.indexOf('/verify') > -1 
    || this.route.url.indexOf('/redirect') > -1  ? true : false;
    
    /** sign in */
    // this.isSignIn = this.route.url === '/sign-in' || this.route.url === '/forgot-password' ||  this.route.url === '/reset-password' ? true : false;
    this.isSignIn = this.route.url.indexOf('/sign-in') > -1 
    || this.route.url.indexOf('/forgot-password') > -1 
    || this.route.url.indexOf('/reset-password') > -1  ? true : false;
    
    /** dashboard */
    // this.isDashboard = this.isContainRoute('dashboard') ? true : false;

    /**payment */
    this.isPayment = this.isContainRoute('payment');
    this.isPaymentV1 = this.isContainRoute('v1');
    this.isPaymentForm = !this.route.url.includes('/payment-form') || !this.route.url.includes('/ext-subscription');
    /**invoice */
    this.isInvoice = this.isContainRoute('invoice');
  }


  isContainRoute(routeParam: string): boolean {
      let arrayOfRoutes = (this.route.url).split('/');
      return  arrayOfRoutes.includes(routeParam) ?  true : false;
  }


  sideNavToogle() {
    this.sidenav.toggle();
  }

  logout() {
    this.authService.logout();
    this.route.navigate(['sign-in']);
  }

  home() {
    if(this.authService.isLoggedIn()) {
      this.route.navigate(['dashboard']);
      return;
    }
    this.route.navigate(['sign-up']);
  }

  resetPassword(){
    this.matDialog.open(ResetPasswordComponent, {disableClose: true}).afterClosed().subscribe(res => {
      
    });
  }
}

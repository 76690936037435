import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { devNetWorks, NetWorks } from 'src/app/model/payment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-wallet',
  templateUrl: './new-wallet.component.html',
  styleUrls: ['./new-wallet.component.scss'],
})
export class NewWalletComponent implements OnInit {
  public walletForm!: UntypedFormGroup;
  public otpForm!: UntypedFormGroup;
  @Input() walletFromObj?: any = {};
  public sentOTP: boolean = false;
  public count: number = 0;
  public interval: any;
  public isTestNetworkEnable:boolean = false;

  @Output() isWalletCreate = new EventEmitter<boolean>(false);
  public netWorks: any = NetWorks;
  public show = 'WALLETOFORM';
  public userData: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    public authService: AuthService,
    public dialogRef: MatDialogRef<NewWalletComponent>
  ) {
    this.userData = this.authService.getUserLocal();
    if(this.data && this.data.isDevNetwork && !environment.production) {
      this.isTestNetworkEnable = true;
      this.netWorks = devNetWorks;
    }
  }

  ngOnInit(): void {
    this.otpForm = this.fb.group({
      otp1: [null, Validators.required],
      otp2: [null, Validators.required],
      otp3: [null, Validators.required],
      otp4: [null, Validators.required],
      otp5: [null, Validators.required],
      otp6: [null, Validators.required],
    });
    this.walletForm = this.formBuilder.group({
      network: new UntypedFormControl('', Validators.required),
      walletName: new UntypedFormControl('', Validators.required),
      address: new UntypedFormControl('', Validators.required),
    });
  }

  selectChange = (event: any) => {};

  createWallet = () => {
    if (this.data.isOTPRequired) {
      this.show = 'EMAILOTP';
      this.sendEmailOTP();
    } else {
      this.proccedWalletCreation();
    }
  };

  proccedWalletCreation = () => {
    const postData = {
      walletAddress: {
        ...this.walletForm.value,
      },
    };
    const url = environment.url + 'users/wallet';
    this.commonService.commonPost(url, postData).subscribe(
      (res: any) => {
        if (
          res &&
          res['status'] === 200 &&
          !res['result'].hasOwnProperty('errors')
        ) {
          if (!this.walletFromObj?.isInlineForm) {
            this.dialogRef.close(true);
          }

          if (this.walletFromObj?.isInlineForm) this.isWalletCreate.emit(true);
        } else {
          this.snackBar.open(res['result']['message'], 'CLOSE', {
            duration: 5000,
            panelClass: ['snack-error'],
          });
        }
      },
      (err) => {
        this.snackBar.open('Oops! somthing went wrong', 'CLOSE', {
          duration: 5000,
          panelClass: ['snack-error'],
        });
        if (!this.walletFromObj?.isInlineForm) this.dialogRef.close();
      }
    );
  };

  sendEmailOTP() {
    this.commonService.sendEmailOTP('OTP for change wallet address').subscribe(
      (res: any) => {
        this.snackBar.open(res.result.msg, 'Ok', {
          duration: 5000, 
          panelClass: ['snack-sucess'],
        });
        this.timer();
        this.sentOTP = true;
      },
      (err) => {
        this.snackBar.open(err?.error.result.msg, 'Ok', {
          panelClass: ['snack-warn'],
        });
      }
    );
  }

  verifyEmailOTP() {
    const data = {
      otp: Object.values(this.otpForm.value).join(''),
    };
    this.commonService.verifyEmailOTP(data).subscribe(
      (res: any) => {
        this.show = 'WALLETOFORM';
        this.proccedWalletCreation();
      },
      (err) => {
        this.snackBar.open(err?.error.result.msg, 'Ok', {
          duration: 5000,
          panelClass: ['snack-warn'],
        });
      }
    );
  }

  timer() {
    this.count = 60;
    this.interval && clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.count -= 1;
      if (this.count == 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  move(e: any, p: any, c: any, n: any) {
    let length = c.value.length;
    let maxLength = c.getAttribute('maxlength');
    if (length == maxLength) {
      if (n != '') n.focus();
    }
    if (e.key == 'Backspace') {
      if (p != '') p.focus();
    }
  }
}

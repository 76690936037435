import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortUrlPipe } from './pipes/short-url.pipe';


@NgModule({
  declarations: [ 
    ShortUrlPipe
  ],
  exports:[ 
    ShortUrlPipe
  ],
  imports: [
    CommonModule
  ]
})
export class CoreModule { }

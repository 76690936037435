<mat-card appearance="outlined">
  <div class="subscription_info">
    <p>Your Subscription at <b>{{userData?.businessDetails?.name}}</b></p>
    <p *ngIf="userData?.email">{{invoiceData?.billTo.email}}</p>
  </div>
  <h6>Subscription Plan</h6>
  <div class="flex-box" *ngIf="invoiceData.items && invoiceData.items.length > 0">
    <div class="plan_details">
      <h6>{{planId?.title}}</h6>
      <p>{{planId?.description}}</p>
      <span>{{invoiceData?.items[0]?.unitPrice}} {{invoiceData?.invoiceCurrency}} × {{invoiceData?.subscriptionId?.planQuantity || invoiceData?.items[0]?.quantity}} Unit</span>
      <!-- <span>{{invoiceData?.items[0]?.unitPrice | currency: invoiceData?.invoiceCurrency | space }} × {{invoiceData?.subscriptionId?.planQuantity || invoiceData?.items[0]?.quantity}} Unit</span> -->
    </div>
    <div class="plan_amount min-width">
      <h6>Recurring Amount</h6>
      <!-- <h6>{{invoiceData?.items[0]?.billAmount | currency: invoiceData?.invoiceCurrency | space }}</h6> -->
      <h6>{{invoiceData?.items[0]?.billAmount}} {{invoiceData?.invoiceCurrency}}</h6>
      <p>Billed {{ planId?.frequencyCount > 1 ? "Once in" : "Every" }}
        {{ planId?.frequencyCount }}
        {{
          planId?.frequencyCount > 1
            ? planId?.frequencyType.concat("s")
            : planId?.frequencyType
        }}</p>
    </div>
  </div>

    <div class="flex-box" *ngIf="offerId && offerId.discount">
    <h6>Offer Applied</h6>
    <div class="date min-width">
      <h6>{{offerId.discount }} {{invoiceData?.invoiceCurrency}}</h6>
      <p>{{offerId?.displayTitle}} <mat-icon class="info" aria-hidden="false" matTooltip="{{offerId.description}}"
        [matTooltipPosition]="'above'">info</mat-icon></p>
    </div>
    </div>
    <div class="flex-box no-border">
      <div class="date">
        <h6>Next Due On</h6>
        <p>{{invoiceData.subscriptionId.nextDue ? commonService.getDate(invoiceData.subscriptionId.nextDue) : '--'}}</p>
      </div>
      <!-- <div class="date">
      <h6>Pay Using</h6>
      <p>Credit Card Ending in 4242</p>
      <p class="font-small grey">Expires 7/2023</p>
      </div> -->
    </div>
   

  <div class="status_bar" *ngIf="statusObj && statusObj.msg">
    <mat-card appearance="outlined" class="{{statusObj.class.toLowerCase()}}" >
      <mat-card-content>{{statusObj.msg}}</mat-card-content>
    </mat-card>
  </div>

  <div class="contact_info">
    <p>For any queries, please contact <a class="link" href="mailto:support@intrxn.com">intrXn</a>.</p>
  </div>

  <div class="invoice_footer">
    <p>
      Subscription payments are powered by intrxn. To start accepting online
      payments for your business, visit
      <a class="link" target="_blank" href="https://intrxn.com/">intrxn</a>
    </p>
    <div class="footer-logo">
      <img
        src="../../../assets/images/intrxn-logo.svg"
        alt="intrxn"
        width="130"
      />
    </div>
  </div>
</mat-card>

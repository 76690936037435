import { CANCELLED } from "dns";

// Offer Module JSON
export const redemptionType = [
  { name: 'Single Use', value: 1 },
  { name: 'Limited Number of Cycles', value: 2 },
  { name: 'Forever', value: 3 },
];

export const discountTypeOption = [
  { name: '--Select Type--', value: '' },
  { name: 'Flat', value: 1 },
  { name: 'Percentage', value: 2 },
];

export const paymentMethod = [
  { name: '--Select Payment method--', value: '' },
  { name: 'Gnosis Safe', value: 'Gnosis Safe' },
  { name: 'Other wallets', value: 'Other wallets' },
];

export const paymentFailure = [
  { name: '--Select Type--', value: '' },
  { name: 'Do not allow payment to go through', value: true },
  { name: 'Allow customer to pay without availing offer', value: false },
];


// Plan JSON's
export const billingCycle = [
  // { name: 'Days(s)', value: 'day' },
  // { name: 'Week(s)', value: 'week' },
  { name: 'Month(s)', value: 'month' },
  // { name: 'Year(s)', value: 'year' },
];

export const status = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  COMPLETED:'COMPLETED',
  EXPIRED: 'EXPIRED',
  PAUSED:'PAUSED'
}

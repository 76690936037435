import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from './services/auth.service';

@Injectable()
export class BlockProfileInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let isSettingsReq: boolean = false;
    if(request.headers.has('stg')) isSettingsReq = true;

    return next.handle(request).pipe(
      tap(event => {
        if(event instanceof HttpResponse){
          const body:any = event.body;
          if(isSettingsReq && body.result?.merchantSettings?.profileBlocked){
            this.authService.logout();
            this.router.navigateByUrl('/profile-blocked');
          }
        }
      })
    );
  }
}

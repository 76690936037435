import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {WalletService} from 'src/app/core/services/wallet.service';

@Component({
  selector: 'app-connect-to-wallet',
  templateUrl: './connect-to-wallet.component.html',
  styleUrls: ['./connect-to-wallet.component.scss']
})
export class ConnectToWalletComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConnectToWalletComponent>,
    private walletService: WalletService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
  }
  
  close() {
    this.dialogRef.close();
  }

  ignore() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(true);
  }
  

  connectToMetamask() {
    
    this.dialogRef.close('METAMASK');
    // this.walletService.getProvider().subscribe(provider => {
    //   this.dialogRef.close(provider);
    // }, err => {
    //   this.snackBar.open('Something went wrong. Try again later!', 'CLOSE', {
    //     panelClass: ['snack-error'],
    //   });
    //   this.dialogRef.close();
    // });

  }





}

import {environment} from "src/environments/environment";
import {ENVIRONMENT} from "../config";

export const TransferStatus = {
    PENDING : 0,
    SUCCESS : 1,
    SETTLED: 2,
};

export const TransferStatusTxt = [
    'PENDING',
    'SUCCESS',
    'SETTLED',
];

export const filterAomunt = [
    100, 200, 500, 1000, 5000,
   ]

const NetworkOptions_dev = [
    //{name: 'Ethereum', value: 'ETH', img: 'assets/images/ethereum-eth-logo.png'},
    {name: 'Polygon Mumbai Testnet', value: 'MUMBAI', img: 'assets/images/polygon-matic-icon.png'}, //
    // {name: 'Polygon Mainnet', value: 'POLYGON', img: 'assets/images/polygon-matic-icon.png'}, //
  ];

const NetworkOptions_sandbox = [
    //{name: 'Ethereum', value: 'ETH', img: 'assets/images/ethereum-eth-logo.png'},
    {name: 'Polygon Mainnet', value: 'POLYGON', img: 'assets/images/polygon-matic-icon.png'}, //
  ];

export const NetworkOptions = environment.ENV === ENVIRONMENT.DEV ? NetworkOptions_dev : NetworkOptions_sandbox;

export const CoinOptions = [
    {name: 'USDT', value: 'USDT', symbol: 'USDT', img: 'assets/images/usdt.svg'},
    {name: 'USDC', value: 'USDC', symbol: 'USDC', img: 'assets/images/usdc.svg'},
    {name: 'DAI', value: 'DAI', symbol: 'DAI', img: 'assets/images/dai.svg'},
    // {name: 'BUSD', value: 'BUSD', symbol: 'BUSD', img: 'assets/images/busd.svg'},
  ]

export const Type = [
    {name: 'To Wallet', value: 'TRANSFER'},
    {name: 'To Bank Account', value: 'WITHDRAWAL'},
]

export const txnType = { 
  TRANSFER: 'TRANSFER',
  WITHDRAWAL: 'WITHDRAWAL'
};

export const payoutStatus = {
  PENDING: 'Pending',
  SUBMITTED: 'Submitted',
  SUCCESS: 'Success',
  FAILED: 'Failed',
  PAID: 'Paid',
};

import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'app-save-changes-dialog',
  templateUrl: './save-changes-dialog.component.html',
  styleUrls: ['./save-changes-dialog.component.scss']
})
export class SaveChangesDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SaveChangesDialogComponent>,
    public route: Router,
  ) { }

  ngOnInit(): void {

  }

  close() {
    this.dialogRef.close();
  }

  ignore() {
    this.dialogRef.close();
    this.route.navigate(['dashboard']);
  }

  save() {
    this.dialogRef.close(true);
  }

}

<div class="ws_form_control choose_method">
  <div class="gws_label_optinal">
    <label>Payment Method</label>
  </div>

  <div class="d-flex">
    <mat-radio-group
      aria-label="Select an option"
      [(ngModel)]="methodOption"
      (change)="onPaymentSelect($event)"
    >
      <mat-radio-button value="crypto">Crypto</mat-radio-button>
      <mat-radio-button value="fiat">Fiat</mat-radio-button>
    </mat-radio-group>
  </div>

  <ng-container *ngIf="methodOption === 'crypto'">
    <app-network-widget
      [networkSettings]="networkSettings"
      (onSuccessData)="onSelect($event)"
    ></app-network-widget>
  </ng-container>

  <ng-container *ngIf="methodOption === 'fiat'">
    <form
      class="payment-method-form"
      [formGroup]="paymentMethodForm"
      novalidate
    >
      <div class="d-flex">
        <div class="gws_form_control">
          <div class="gws_label_optinal">
            <label>Invoice Currency <span class="color_red">*</span></label>
          </div> 
          <input type="text" matInput [formControl]="invoiceCurrencyInput" [matAutocomplete]="auto">
          <mat-autocomplete class="walled_network select" #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let type of filteredOptions | async" [value]="type">
              {{ currencySymbol(type.code) + " - " + type.name }}
            </mat-option>
          </mat-autocomplete>

          <!-- <mat-select
            class="walled_network select"
            disableOptionCentering
            formControlName="invoiceCurrency"
            placeholder="Invoice Currency"
            disableRipple
            [ngClass]="
            paymentMethodForm.controls['invoiceCurrency'].touched &&
            paymentMethodForm.controls['invoiceCurrency'].invalid
    ? 'is-invalid'
    : ''
"
          >
            <mat-option
              class="network_option"
              value="{{ type.code }}"
              *ngFor="let type of invoiceCurrency"
              >{{ currencySymbol(type.code) + " - " + type.name }}
            </mat-option>
          </mat-select> -->
        </div>
        <div class="gws_form_control">
          <div class="gws_label_optinal">
            <label>Bank Name <span class="color_red">*</span></label>
          </div>
          <input
            type="text"
            formControlName="bankName"
            [ngClass]="
              paymentMethodForm.controls['bankName'].touched &&
              paymentMethodForm.controls['bankName'].invalid
                ? 'is-invalid'
                : ''
            "
          />
        </div>
      </div>

      <div class="d-flex">
        <div class="gws_form_control">
          <div class="gws_label_optinal">
            <label>Account Number</label>
          </div>
          <input
            type="number"
            formControlName="accountNo"
            [ngClass]="
              paymentMethodForm.controls['accountNo'].touched &&
              paymentMethodForm.controls['accountNo'].invalid
                ? 'is-invalid'
                : ''
            "
          />
        </div>
        <div class="gws_form_control">
          <div class="gws_label_optinal">
            <label>Swift Code</label>
          </div>
          <input
            type="text"
            formControlName="swiftCode"
            [ngClass]="
              paymentMethodForm.controls['swiftCode'].touched &&
              paymentMethodForm.controls['swiftCode'].invalid
                ? 'is-invalid'
                : ''
            "
          />
        </div>
        
      </div>
      <div class="d-flex">
        <div class="gws_form_control">
          <div class="gws_label_optinal">
            <label>IFSC</label>
          </div>
          <input
            type="text"
            formControlName="IFSC"
            [ngClass]="
              paymentMethodForm.controls['IFSC'].touched &&
              paymentMethodForm.controls['IFSC'].invalid
                ? 'is-invalid'
                : ''
            "
          />
        </div>
        <div class="gws_form_control">
          <div class="gws_label_optinal">
            <label>IBAN</label>
          </div>
          <input
            type="text"
            formControlName="Iban"
            [ngClass]="
              paymentMethodForm.controls['Iban'].touched &&
              paymentMethodForm.controls['Iban'].invalid
                ? 'is-invalid'
                : ''
            "
          />
        </div>
      </div>
    </form>
  </ng-container>
</div>

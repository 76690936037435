import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import {environment} from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import {HttpClient, HttpHeaders, HttpParams, HttpParamsOptions} from '@angular/common/http';
import * as moment from 'moment';
import { CountryJSON } from 'src/app/model/countrysJSON';
import { MatSnackBar } from '@angular/material/snack-bar';
const { S3Client, GetObjectCommand, PutObjectCommand } = require('@aws-sdk/client-s3');
const { getSignedUrl } = require('@aws-sdk/s3-request-presigner');
const aws = require("aws-sdk");
declare let intlTelInput: any;

const client = new S3Client({
  credentials: {
    accessKeyId: environment.s3Config.access_key_id,
    secretAccessKey: environment.s3Config.secret_access_key,
  },
  region: environment.s3Config.region,
});


@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public isLoading = new Subject<boolean>();
  public countrys: any = CountryJSON;
  public timeoutInstance:any = '';
  constructor(
    private snackBar: MatSnackBar,
    private route: Router,
    public spinnerService: NgxSpinnerService,
    public http: HttpClient
  ) {}

  showSpinner() {
    this.isLoading.next(true);
  }

  hideSpinner() {
    this.isLoading.next(false);
  }

  //  hideSideBar() {
  //     return this.isContainRoute('account-setup')
  //  }

  isContainRoute(routeParam: string): boolean {
    let arrayOfRoutes = this.route.url.split('/');
    return arrayOfRoutes.includes(routeParam) ? true : false;
  }

  /************* get S3 files/url *********/

  async getFileURL(fileKey: any) {
    const command = new GetObjectCommand({
      Bucket: environment.s3Config.bucketName,
      Key: fileKey,
    });
    const signedUrl = await getSignedUrl(client, command, {
      expiresIn: 3600,
    });
    // console.log('sign url', signedUrl);
    return signedUrl;
    // const response = await fetch(signedUrl, {mode: 'no-cors'})
    //     const blob = await response.blob()

    //     const blobUrl = URL.createObjectURL(blob);

    // return blobUrl;
  }

  async getFileData(fileKey: any) {
    let data;
    const command = new GetObjectCommand({
      Bucket: environment.s3Config.bucketName,
      Key: fileKey,
    });
    try {
      data = await client.send(command);
    } catch (err) {
      console.error('Error getting file', err);
    }

    return data.Body;
  }

  /** upload file */

  async uploadFiles(file: any, fileName: any) {
    // this.showSpinner();

    const s3 = new aws.S3({
      credentials: {
        accessKeyId: environment.s3Config.access_key_id,
        secretAccessKey: environment.s3Config.secret_access_key,
      },
      region: environment.s3Config.region,
    });

    let data;
    try {
      data = await s3
        .upload({
          Bucket: environment.s3Config.bucketName,
          Key: fileName,
          Body: file,
        })
        .promise();
    } catch (err) {
      console.error('Error uploading file', err);
      throw err;
    }

    console.info(`File uploaded: ${data.Key}`);

    return { name: fileName || data.Key, key: data.Key, url: data.Location };
  }

  sendEmailOTP(subject: string): Observable<any> {
    const url = environment.url + 'users/sendEmailOTP?subject=' + subject;
    return this.http.get(url);
  }

  verifyEmailOTP(data: any): Observable<any> {
    const url = environment.url + 'users/verifyEmailOTP';
    return this.http.post(url, { data });
  }

  getPrice(data: any): Observable<any> {
    const httpParams: HttpParamsOptions = {
      fromObject: data,
    } as HttpParamsOptions;
    const options = { params: new HttpParams(httpParams) };

    const url = environment.url + 'service/priceConversion';
    return this.http.get(url, options);
  }

  // paymentgateway

  getPaymentDetails(id: string): Observable<any> {
    const url = `${environment.domain}v1/payment/${id}`;
    return this.http.get(url);
  }

  payment(id: string, transaction: any): Observable<any> {
    const url = `${environment.domain}v1/payment/${id}`;
    return this.http.post(url, transaction);
  }

  getHeader = (noLoader?: boolean, obj?: any) => {
    const noLoaderObj: any = noLoader ? { noLoader: '', stg: '' } : {};
    const noAuth = obj && obj.isNoAuth ? { noAuth: '' } : {};
    const otherConfig = obj && obj.config ? { ...obj.config } : {};
    return {
      headers: new HttpHeaders({
        ...noLoaderObj,
        ...noAuth,
        ...otherConfig,
      }),
    };
  }

  commonPost = (url: any, postData: any, obj?: any) => {
    return this.http.post(url, postData, this.getHeader(obj?.noLoader || false, obj));
  };

  commonPut = (url: any, postData: any) => {
    return this.http.put(url, postData);
  };

  commonGet = (url: any, noLoader?: boolean, obj?: any) => {
    return this.http.get(url, this.getHeader(noLoader, obj));
  };

  commonDelete = (url: any) => {
    return this.http.delete(url);
  };

  getDate(date: any) {
    if (!date) return null;
    return moment(date, 'X').format('DD/MM/YYYY');
  }

  isCurrentDateBeforeDueDate = (number: any, dueDate: any) => {
    const todayDate: any = new Date();
    const nextDue: any = new Date(dueDate);
    const diffTime = nextDue - todayDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 0 && diffDays <= number;
  };

  setLocalStorage(key: any, data: any) {
    localStorage.setItem(key, window.btoa(JSON.stringify(data)));
  }

  getLocalStorage(key: any) {
    const data: any = localStorage.getItem(key);
    if (!data) return data;
    return JSON.parse(window.atob(data));
  }

  getPhoneNumberObj = (phoneNumber: any, countryCode: any) => {
    if (!phoneNumber) return phoneNumber;
    let regex = /^\+[0-9]+$/;
    if (regex.test(phoneNumber)) {
      const country = this.countrys.find(
        (item: any) => item.code === countryCode
      );
      if (country && Object.keys(country).length > 0) {
        return {
          countryCode: country.dial_code,
          number: phoneNumber.substring(
            country.dial_code.length,
            phoneNumber.length
          ),
        };
      } else {
        return { countryCode: '', number: phoneNumber };
      }
    } else {
      return { countryCode: '', number: phoneNumber };
    }
  };

  updateUserProfile = (payout:any) => {
    return new Promise((resolve, reject) => {
    const currentUserData = this.getLocalStorage("ud");
    const userData = { payout }
    const url = environment.url + 'users/updateUserDetails';
    this
    .commonPut(url, { userData }).subscribe((res:any) => {
      if(res && Object.keys(res.result).length > 0) {
        this.setLocalStorage("ud", res.result);
        resolve(res.result);
      }
    },
    (err) => {
      this.snackBar.open(err.error.result + '-' + err.name, 'Close', {
        panelClass: ['snack-error'],
      });
      reject(err);
    });
   });
  }

  callbackTimer(duration: any, callback:any, id:any) {
    var start = Date.now(),
      diff,
      minutes,
      seconds;
    let timer = () => {
      // get the number of seconds that have elapsed since
      // startTimer() was called
      diff = duration - (((Date.now() - start) / 1000) | 0);

      // does the same job as parseInt truncates the float
      minutes = (diff / 60) | 0;
      seconds = diff % 60 | 0;

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      const timer = minutes + ':' + seconds;
      const el:any = document.getElementById(id);
      el.innerText = timer;
      if (diff <= 0) {
        // add one second so that the count down starts at the full duration
        // example 05:00 not 04:59
        this.clearTimeout();
        callback && callback();
      }
    };
    // we don't want to wait a full second before the timer starts
    timer();
    this.timeoutInstance = setInterval(timer, 1000);
  }

  clearTimeout = () => {
    clearInterval(this.timeoutInstance);
  }

  getMobileNumberLength = (numberLength:string) => {
    let length = numberLength;
    if (numberLength.indexOf(',') > -1) { 
      length = numberLength.split(',')[1];
    }
     return length;
    }

  validateMobileNumber = (formControl:any, expectLength:any) => {
    let mobileNumRegex = /^(0|[1-9][0-9]*)$/;
    if (
      !mobileNumRegex.test(formControl.value.mobileNumber)
    ) {
      formControl.controls['mobileNumber'].setErrors({ incorrect: true });
      return;

    } else if(formControl.value.mobileNumber.toString().length !== Number(expectLength)) {
      formControl.controls['mobileNumber'].setErrors({ incorrectLength: true });
      return;
    } else {
      formControl.controls['mobileNumber'].setErrors(null);
    }
    return formControl;
  };

  getmakedNumber = (bankAccountNumber:string) =>  bankAccountNumber.replace(/\d(?=\d{4})/g, "X")

  generateFirstLastName = (fullName:string) => {
    let firstName = '';
    let lastName = '';
    if((fullName).trim().length > 0) {
      const splitName = fullName.split(' ');
      firstName = splitName[0];
      const skipFirstIndex = splitName.filter((words:string) => words !== splitName[0]);
      if(skipFirstIndex.length > 0) {
        lastName = skipFirstIndex.join(' ');
      }
    } 
    return {firstName, lastName};
  }
  
  getAccounts = (str: any) => {
    if (str.length > 35) {
      return str.substr(0, 6) + '...' + str.substr(str.length - 4, str.length);
    }
    return str;
  };

  isFloat(n:any){
    return Number(n) === n && n % 1 !== 0;
  }

  initIntlTelInput = () => {
    const input = document.querySelector("#phone");
    intlTelInput(input, {
      utilsScript: "intl-tel-input/build/js/utils.js",
    }); 
  }
  
  externalPost = (url: any, postData: any, obj?: any) => {
    const date:any = new Date();
    const header = {
      headers : new HttpHeaders({
      "X-intrXn-Timestamp": Math.floor(date / 1000).toString() as unknown as string,
      "X-intrXn-key": obj.key,
    })}
    return this.http.post(url, postData, header);
  };

}


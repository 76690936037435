import { Injectable } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {AuthService} from '../services/auth.service';
import {Observable} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private afAuth: AngularFireAuth, public route: Router, public authService: AuthService) { }
  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean|UrlTree|Observable<boolean|UrlTree>|Promise<boolean|UrlTree> {
    
    if(!this.authService.isLoggedIn()){
      this.route.navigate(['sign-in'], { queryParams: { returnUrl: state.url }});
      return false;
    }

    if(!this.authService.isEmailverified()){
        this.route.navigate(['verify']);
        return false;
    }
    return true;
  }
  
}

/**
 * 
 */

@Injectable({
  providedIn: 'root'
})
export class isLoggedInGuard  {

  constructor(private afAuth: AngularFireAuth, public route: Router, public authService: AuthService) { }
  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean|UrlTree|Observable<boolean|UrlTree>|Promise<boolean|UrlTree> {
    
    if(this.authService.isLoggedIn()){
      this.route.navigate(['dashboard']);
      return false;
    }
    return true;
  }
  
}




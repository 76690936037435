import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessControlGuardBuss} from './core/guards/access-control.guard';
import {AuthGuard} from './core/guards/auth.guard';
import { DefultComponent } from './layout/defult/defult.component';
import {FullwidthComponent} from './layout/fullwidth/fullwidth.component';
import { CsvUploadComponent } from './modules/csv-upload/csv-upload.component';
import {ProfileBlockedComponent} from './shared/profile-blocked/profile-blocked.component';


const routes: Routes = [
  { 
    path: 'dashboard', 
    loadChildren: () => import('../app/modules/dashboard/dashboard.module').then((module) => module.DashboardModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'invoiceold', 
    loadChildren: () => import('../app/modules/invoice/invoice.module').then((module) => module.InvoiceModule),
    canActivate: [AuthGuard, AccessControlGuardBuss]
  },
  { 
    path: 'invoices', 
    loadChildren: () => import('../app/modules/invoices/invoices.module').then((module) => module.InvoicesModule),
    canActivate: [AuthGuard, AccessControlGuardBuss]
  },
  
  { 
    path: 'payment-link', 
    loadChildren: () => import('../app/modules/payment-link/payment-link.module').then((module) => module.PaymentLinkModule),
    canActivate: [AuthGuard, AccessControlGuardBuss]
  },
  { 
    path: 'payment', 
    loadChildren: () => import('../app/modules/payment/payment.module').then((module) => module.PaymentModule),
  },
  { 
    path: 'wallets', 
    loadChildren: () => import('./modules/wallet/wallet.module').then((module) => module.WalletModule),
    canActivate: [AuthGuard]
  },
   { 
    path: 'fiat-withdrawals', 
    loadChildren: () => import('./modules/payouts/payouts.module').then((module) => module.PayoutsModule),
    canActivate: [AuthGuard, AccessControlGuardBuss]
  },
  { 
    path: 'deposite', 
    loadChildren: () => import('./modules/payouts/payouts.module').then((module) => module.PayoutsModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'customers', 
    loadChildren: () => import('../app/modules/customers/customers.module').then((module) => module.CustomersModule),
    canActivate: [AuthGuard, AccessControlGuardBuss]
  },
  { 
    path: 'products', 
    loadChildren: () => import('../app/modules/products/products.module').then((module) => module.ProductsModule),
    canActivate: [AuthGuard, AccessControlGuardBuss]
  },
  { 
    path: 'settings', 
    loadChildren: () => import('../app/modules/settings/settings.module').then((module) => module.SettingsModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'referral-program', 
    loadChildren: () => import('../app/modules/referral-program/referral-program.module').then((module) => module.ReferralProgramModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'api', 
    loadChildren: () => import('../app/modules/api-integration/api-integration.module').then((module) => module.ApiIntegrationModule),
    canActivate: [AuthGuard, AccessControlGuardBuss]
  },
  { 
    path: 'profile-blocked',
    component: FullwidthComponent,
    children:[{
      path:'',
      component: ProfileBlockedComponent
    }]
  },
  { 
    path: 'csv-upload',
    component: DefultComponent,
    children:[{
      path:'',
      component: CsvUploadComponent
    }],
    canActivate: [AuthGuard, AccessControlGuardBuss]
  },
  { 
    path: 'subscriptions', 
    loadChildren: () => import('../app/modules/subscription/subscription.module').then((module) => module.SubscriptionModule),
    canActivate: [AuthGuard, AccessControlGuardBuss]
  },
  { 
    path: 'offers', //offers
    loadChildren: () => import('../app/modules/offers/offers.module').then((module) => module.OffersModule),
    canActivate: [AuthGuard, AccessControlGuardBuss]
  },
  { 
    path: '',  
    loadChildren: () => import('../app/auth/auth.module').then((module) => module.AuthModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'sign-in'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

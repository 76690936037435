export const NetWorks = [
  { label: 'Ethereum', value: 'ethereum', img: 'assets/images/eth.svg' },
  { label: 'Polygon', value: 'polygon', img: 'assets/images/polygon.svg' },
  { label: 'BSC', value: 'bsc', img: 'assets/images/bsc.svg' },
  { label: 'Arbitrum', value: 'arbitrum', img: 'assets/images/arbitrum.svg' },
  { label: 'Optimism', value: 'optimism', img: 'assets/images/optimism.svg' },
];

export const currency = [
  { label: 'USDT', value: 'USDT', img: 'assets/images/usdt.svg' },
  { label: 'USDC', value: 'USDC', img: 'assets/images/usdc.svg' },
  // { label: 'DAI', value: 'DAI', img: 'assets/images/dai.svg' },
  // { label: 'MARSH', value: 'MARSH', img: 'assets/images/marsh.png' },
  // { label: 'ETH', value: 'ETH', img: 'assets/images/eth.svg' },
];

export const devNetWorks = [
  { label: 'Goerli', value: 'Goerli', img: 'assets/images/goerli.png' },
];
export const devCurrency = [
  { label: 'FAU', value: 'FAU', img: 'assets/images/goerli.png' },
];

export const paymentRules = {
  IN: { min: 500, max: 500000 },
  KE: { min: 1000, max: 999999 },
  AE: { min: 40, max: 36700 },
  UK: { min: 10, max: 10000 },
  CN: {min: 50, max: 20000 },
  PH: {min: 250, max: 500000 },

};
export const counrtySettings = [
  {
    code: 'IN',
    enable:true,
    labels: { 
      accountNumber: 'Account Number',
      swiftCode: 'SWIFT Code',
      bankCode: 'IFSC Code',
      bankSubCode: 'Branch Code',
    },
    required: {
      accountNumber: true,
      swiftCode: true,
      bankCode: true,
      bankSubCode: false,
    },
  },
  {
    code: 'CN',
    enable:true,
    labels: { 
      accountNumber: 'Account Number',
      swiftCode: 'SWIFT Code',
      bankCode: 'Bank Code',
      bankSubCode: 'Bank Subcode',
    },
    required: {
      accountNumber: true,
      swiftCode: true,
      bankCode: false,
      bankSubCode: false,
    },
  },
  {
    code: 'AE',
    enable:true,
    labels: {
      accountNumber: 'IBAN',
      swiftCode: 'SWIFT Code',
      bankCode: 'Bank Code',
      bankSubCode: 'Bank Subcode',
    },
    required: {
      accountNumber: true,
      swiftCode: true,
      bankCode: false,
      bankSubCode: false,
    },
  },
  {
    code: 'GB',
    enable:false,
    labels: {
      accountNumber: 'IBAN or bank account number',
      swiftCode: 'SWIFT Code',
      bankCode: 'sort Code',
      bankSubCode: 'Bank Subcode',
    },
    required: {
      accountNumber: true,
      swiftCode: true,
      bankCode: true,
      bankSubCode: false,
    },
  },
  {
    code: 'KE',
    enable:false,
    labels: {
      accountNumber: 'Bank account number',
      swiftCode: 'SWIFT Code',
      bankCode: 'Bank Code',
      bankSubCode: 'Bank Subcode',
    },
    required: {
      accountNumber: true,
      swiftCode: true,
      bankCode: true,
      bankSubCode: false,
    },
  },
  {
    code: 'PH',
    enable:true,
    labels: {
      accountNumber: 'Bank account number',
      swiftCode: 'SWIFT Code',
      bankCode: 'Bank Code',
      bankSubCode: 'Bank Subcode',
    },
    required: {
      accountNumber: true,
      swiftCode: true,
      bankCode: true,
      bankSubCode: false,
    },
  },
];

<app-header flexLayout="column" [sidenav]="sidenav"></app-header>

<!-- <div class="content_block" flexLayout="row" fxFlex>

    <div fxFlex="20" class="content_side">
        <app-sidebar fxFlex></app-sidebar>
    </div>

    <div fxFlex="80" class="gws_container content">
        <router-outlet></router-outlet>
    </div>

</div> -->

<!-- <div fxLayout="row" fxFlex="100" style="overflow: hidden;">
    <app-sidebar fxLayout="column" fxFlex="20"></app-sidebar>
    <div class="content gws_container" fxLayout="column" fxFlex>
        <router-outlet></router-outlet>
    </div>
</div> -->

<!-- <app-footer flexLayout="column"></app-footer> -->


<!-- <mat-drawer-container autosize>

    <mat-drawer #drawer class="sidenav" mode="side" opened>

        <app-sidebar></app-sidebar>

    </mat-drawer>


    <div class="sidenav-content gws_container">
        <button type="button" mat-button (click)="drawer.toggle()">
            Toggle sidenav
        </button>
        <router-outlet></router-outlet>
    </div>

</mat-drawer-container> -->


<!-- <mat-toolbar>
    responsive
</mat-toolbar> -->


<mat-sidenav-container fxFlex="100" style="overflow: hidden;">

    <mat-sidenav #sidenav="matSidenav" mode="side" opened fxFlex="20" class="sidenav">
        <app-sidebar></app-sidebar>
    </mat-sidenav>

    <mat-sidenav-content class="content layout_content">
        <router-outlet></router-outlet>
    </mat-sidenav-content>

</mat-sidenav-container>
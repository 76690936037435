<div class="container">
    <div class="title">
        <h5 class="color_primary">Update Password</h5>
        <mat-icon class="color_primary" (click)="close()">close</mat-icon>
    </div>
    <ng-container *ngIf="showDialog === 'OTP'">
        <p>We need to verify your identity to update your password. An OTP has been sent to your registered email
            {{userData?.email}}.</p>

        <form [formGroup]="otpForm" novalidate>
            <div class="gws_form_control_otp">
                <input type="text" class="gws_otp" formControlName="otp1" maxlength="1" #txt1
                    (keyup)="move($event, '', txt1, txt2)" />

                <input type="text" class="gws_otp" formControlName="otp2" maxlength="1" #txt2
                    (keyup)="move($event, txt1, txt2, txt3)" />

                <input type="text" class="gws_otp" formControlName="otp3" maxlength="1" #txt3
                    (keyup)="move($event, txt2, txt3, txt4)" />

                <input type="text" class="gws_otp" formControlName="otp4" maxlength="1" #txt4
                    (keyup)="move($event, txt3, txt4, txt5)" />

                <input type="text" class="gws_otp" formControlName="otp5" maxlength="1" #txt5
                    (keyup)="move($event, txt4, txt5, txt6)" />

                <input type="text" class="gws_otp" formControlName="otp6" maxlength="1" #txt6
                    (keyup)="move($event, txt5, txt6, '')" />

            </div>
        </form>
        <p>Resend OTP in {{count}}S. <span class="gws_btn_secondary" *ngIf="sentOTP && count == 0" (click)="sendEmailOTP()">Resend
                OTP</span></p>

        <div class="gws_flex">
            <button class="gws_btn_secondary" (click)="close()">Cancel</button>
            <button class="gws_btn_primary" (click)="otpForm.valid && verifyEmailOTP()">Confirm</button>
        </div>
    </ng-container>
    <ng-container *ngIf="showDialog === 'PASSWORD'">
        
        <form [formGroup]="resetForm" (ngSubmit)="resetForm.valid && submit(resetForm)" novalidate>

            <div class="gws_form_control">
                <div class="gws_label_optinal">
                    <label>Current Password</label>
                    <span (click)="passwordold()">{{ showOld ? 'Hide' : 'Show' }}</span>
                </div>
                <input [type]="showOld ? 'text' : 'password'" placeholder="Password" formControlName="oldPassword"
                    [ngClass]="
                resetForm.controls['oldPassword'].touched && 
                resetForm.controls['oldPassword'].invalid ? 'is-invalid' : ''
                " />
                <div class="gws_input_error_hint"
                    *ngIf="resetForm.controls['oldPassword'].touched || resetForm.controls['oldPassword'].dirty">
                    <div class="gws_form_errors" *ngIf="resetForm.controls['oldPassword'].hasError('required')">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">error_outline</mat-icon>
                        <span>Password is required!</span>
                    </div>
                </div>
            </div>

            <div class="gws_form_control">
                <div class="gws_label_optinal">
                    <label>Password</label>
                    <span (click)="password()">{{ show ? 'Hide' : 'Show' }}</span>
                </div>
                <input [type]="show ? 'text' : 'password'" placeholder="Password" formControlName="password"
                    [ngClass]="
                resetForm.controls['password'].touched && 
                resetForm.controls['password'].invalid ? 'is-invalid' : ''
                " />
                <div class="gws_input_error_hint" [ngStyle]="{'display': 'initial'}"
                    *ngIf="resetForm.controls['password'].touched || resetForm.controls['password'].dirty">
                    <div class="gws_form_errors" *ngIf="resetForm.controls['password'].hasError('required')">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">error_outline</mat-icon>
                        <span>Password is required!</span>
                    </div>
                    <div class="gws_form_errors" *ngIf="resetForm.controls['password'].hasError('minlength')">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">error_outline</mat-icon>
                        <span>minimum 8 Characters</span>
                    </div>
                    <div class="gws_form_errors"
                        *ngIf="resetForm.controls['password'].hasError('hasSpecialCharacters')">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">error_outline</mat-icon>
                        <span>atleast 1 Special Character</span>
                    </div>
                    <div class="gws_form_errors" *ngIf="resetForm.controls['password'].hasError('hasNumber')">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">error_outline</mat-icon>
                        <span>atleast 1 Number</span>
                    </div>
                </div>
            </div>

            <div class="gws_form_control">
                <div class="gws_label_optinal">
                    <label>confirm Password</label>
                    <span (click)="passwordC()">{{ showC ? 'Hide' : 'Show' }}</span>
                </div>
                <input [type]="showC ? 'text' : 'password'" placeholder="confirm Password"
                    formControlName="confirmpassword" [ngClass]="
                resetForm.controls['confirmpassword'].touched && 
                resetForm.controls['confirmpassword'].invalid ? 'is-invalid' : ''
                " />
                <div class="gws_input_error_hint"
                    *ngIf="resetForm.controls['confirmpassword'].touched || resetForm.controls['confirmpassword'].dirty"
                    [ngStyle]="{'display': 'initial'}">
                    <div class="gws_form_errors" *ngIf="resetForm.controls['confirmpassword'].hasError('required')">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">error_outline</mat-icon>
                        <span>confirm Password is required!</span>
                    </div>
                    <div class="gws_form_errors"
                        *ngIf="resetForm.controls['confirmpassword'].hasError('NoPassswordMatch')">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">error_outline</mat-icon>
                        <span>Password didn't match!</span>
                    </div>
                </div>
            </div>

            
        <div class="gws_flex">
            <button type="button" class="gws_btn_secondary" (click)="close()">Cancel</button>
            <button type="submit" class="gws_btn_primary" (click)="this.resetForm.markAllAsTouched()">Update Password</button>
        </div>
        </form>

    </ng-container>
</div>
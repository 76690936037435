import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-offer-confirmation',
  templateUrl: './offer-confirmation.component.html',
  styleUrls: ['./offer-confirmation.component.scss']
})
export class OfferConfirmationComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<OfferConfirmationComponent>,
    private snackBar: MatSnackBar,
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  changeStatus(){
    const postData = {
      "status": this.data['offerInfo']['status'] ? false : true,
      "offerId": this.data['offerInfo']['_id']
    }
    const url = `${environment.domain}app/core/offer/update`;
    this.commonService.commonPut(url, postData).subscribe(res => {
      this.snackBar.open(`offer ${this.data['offerInfo']['status'] ? 'disable' : 'enable'} successfully.`, '', { duration: 5000, panelClass: ['snack-success'] });
      this.dialogRef.close(true);
    });
  }


}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Stages } from 'src/app/model/user';
import { ConfirmModalComponent } from 'src/app/shared/dialog/confirm-modal/confirm-modal.component';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AccessControlGuardBuss  {
  public userData:any = {};
  public accountSetupUrl:any = '';
  public stage: any = Stages;
  constructor(
    public route: Router, 
    public authService: AuthService,
    public snackBar: MatSnackBar,
    private dialog: MatDialog,
  ){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.userData = this.authService.getUserLocal();
      if(this.stage[this.userData.profileStatus]< 3) {
        this.accountSetupUrl = 'dashboard/setup/account-setup';
      }
      if(this.stage[this.userData.profileStatus] >= 3 && this.stage[this.userData.profileStatus] < 8 ) {
        this.accountSetupUrl = 'dashboard/setup/business-verification';
      }
      if(this.stage[this.userData.profileStatus] === 8) {
        this.accountSetupUrl = 'dashboard/setup/registration-summary';
      }

      if(this.authService.isLoggedIn() && !this.authService.isAccSetupCompleted()){
        const data = {
          heading: 'Account Verification',
          subheading:
            'Seems like your account is not setup, A complete profile helps you explore all features of intrXn`s app, kindly setup your account and expore more feautres.',
          cancelBtn: 'Cancel',
          okBtnLink: this.accountSetupUrl,
          okBtn: 'Setup your account',
        };
        this.dialog
        .open(ConfirmModalComponent, {
          data: data,
          autoFocus: false,
          panelClass: ['warning_model'],
        })
        .afterClosed()
        .subscribe((res: any) => {});
        return false;
    }
    
    if(this.authService.isLoggedIn() && this.authService.isAccSetupCompleted() && (state.url.includes('payouts/') && this.authService.isMerchantUser())){
      this.route.navigate(['dashboard']);
      return false;
    }
    return true;
  }
}


@Injectable({
  providedIn: 'root'
})
export class AccessControlGuardBank  {
  constructor(
    public route: Router, 
    public authService: AuthService,
    public snackBar: MatSnackBar,
  ){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    if(!this.authService.isBankVerified()){
        this.route.navigate(['payouts/verify']);
        return false;
    }
    return true;
  }
  
}

import {environment} from "src/environments/environment";
import { APIENDPOINTS } from "./constants-endpoint";
import {CHAINS_DEV, NETWORKS_DEV, POLYGON_GAS_STATION_DEV, POLYGON_INFURA_WEB3_API_DEV} from "./constants-dev";
import {CHAINS_PROD, NETWORKS_PROD, POLYGON_GAS_STATION_PROD, POLYGON_INFURA_WEB3_API_PROD} from "./constants-prod";
import { mainnet, testnet } from "./constants-walletconfig";

// export const systemWallet = {
//   //toAddress: '0x40297601F699080ddf45d3F9f07597745828a99B', // hemant acc address
//   // toAddress: '0x7C4Bc50db79efa6bC244e2bd71aBAc985E612901', // hemant acc address
//   toAddress: '0x7816C13a8476d0d87E918AC308C01E723a08FDaA', // new
//   // toAddress: '0xa6E97eC5FDf5004E0c3F31d6676bE9AcF2a64383', // karthi
// }

/**
 * fee in Coins
 */
 export const TXN_FEES = {
  POLYGON: {
    USDT: 1,
    USDC: 1,
  }
}

/**
 * @constant {Object} NETWORKS .
 * @constant {Object} CHAINS .
 * @constant {Object} ENVIRONMENT .
 */
export const ENVIRONMENT = {
  DEV: 'DEV',
  PROD: 'PROD'
}

export const NETWORKS = environment.ENV === ENVIRONMENT.DEV ? NETWORKS_DEV : NETWORKS_PROD;

export const CHAINS = environment.ENV === ENVIRONMENT.DEV ? CHAINS_DEV : CHAINS_PROD;

export const POLYGON_INFURA_WEB3_API = environment.ENV === ENVIRONMENT.DEV ?  POLYGON_INFURA_WEB3_API_DEV : POLYGON_INFURA_WEB3_API_PROD;

export const POLYGON_GAS_STATION = environment.ENV === ENVIRONMENT.DEV ?  POLYGON_GAS_STATION_DEV : POLYGON_GAS_STATION_PROD;

export const ENDPOINTS = APIENDPOINTS;

export const NETWORKSCONFIG = environment.ENV === ENVIRONMENT.DEV ? testnet : mainnet;
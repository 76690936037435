import { Component, inject, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-processing-dialog',
  templateUrl: './processing-dialog.component.html',
  styleUrls: ['./processing-dialog.component.scss']
})
export class ProcessingDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef <ProcessingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {txt: string}
  ) { }

  ngOnInit(): void {
  }

  public close() {
    this.dialogRef.close();
  }

}

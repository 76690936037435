<div class="container">
  <div class="dialog_left">
    <h5 class="color_primary">{{data?.heading}}</h5>
    <p [innerHTML]="data?.subheading"></p>
  </div>
  <div class="dialog_right">
    <mat-icon class="color_primary" (click)="no()">close</mat-icon>
  </div>
</div>

<div class="gws_flex">
  <button class="gws_btn_secondary" *ngIf="data?.cancelBtn" (click)="no()">{{data?.cancelBtn}}</button>
  <button class="gws_btn_primary" *ngIf="data?.okBtn" (click)="yes()">{{data?.okBtn}}</button>
</div>

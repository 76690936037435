import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  @Input() modalObj:any = {};
  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public route: Router,
    ) { }

  ngOnInit(): void {
  }

  no() {
    this.dialogRef.close(false);
  }

  yes() {
    if(this.data.okBtnLink) {
      this.no();
      this.route.navigate([this.data.okBtnLink]);
    } else {
      this.dialogRef.close(true);
    }
  }
}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { finalize, switchMap, take } from 'rxjs/operators';
import { CommonService } from './services/common.service';



@Injectable()
export class AuthconfigInterceptor implements HttpInterceptor {
  constructor(
    private commonService: CommonService,
    public afauth: AngularFireAuth) {}
  setToken = (token:any, request:any) => {
    if (!request.headers.has('noAuth')) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
    }
    if (request.headers.has('Noauth')) {
      request = request.clone({
        headers: request.headers.delete('noauth', ''),
      });
    }
    return request;
  }
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.afauth.idToken.pipe(
      take(1),
      switchMap((token: any) => {
        let res = request;
        if (token) {
          res = this.setToken(token, res);
        }
        const authToken = this.commonService.getLocalStorage('userInfo');
        if (authToken) {
          res = this.setToken(authToken, res);
        }
        return next.handle(res);
      })
    );
  }
}

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  reqCount: number = 0;
  constructor(public commonService: CommonService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    //no loader
    if (!request.headers.has('noLoader')) {
      // this.reqCount++;
      this.commonService.showSpinner();
    }

    return next.handle(request).pipe(
      finalize(() => {
        // this.reqCount--;
        if (!request.headers.has('noLoader')) {
          this.commonService.hideSpinner();
        }
      })
    );
  }
}

<div class="container">

    <div class="dialog_left">
        <h5 class="color_primary">Connect to Wallet</h5>
        <p>Sign in with one of available wallet providers or create a new wallet</p>
        <div class="message_badge">
            <mat-icon>error_outline</mat-icon>
            <span>We do not own your private keys and cannot access your funds without your confirmation</span>
        </div>
        <button class="gws_btn_secondary btn" (click)="connectToMetamask()" >Connect to Metamask</button>
        <button class="gws_btn_secondary coming-soon" >Connect to Coinbase Wallet</button>
        <button class="gws_btn_secondary coming-soon" >Connect to Wallet Connect</button>
        <!-- <button class="gws_btn_primary" >Show More</button> -->
    </div>
    <div class="dialog_right">
        <mat-icon class="color_primary" (click)="close()">close</mat-icon>
    </div>
</div>


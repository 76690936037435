import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-tax-registration-dialog',
  templateUrl: './tax-registration-dialog.component.html',
  styleUrls: ['./tax-registration-dialog.component.scss']
})
export class TaxRegistrationDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TaxRegistrationDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(true);
  }

}
